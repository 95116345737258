import { round2 } from "@/assets/utils/commonMath";

export class Revenue {
  constructor(
    id,
    serviceObject,
    amount,
    unit,
    vatCode,
    price,
    revenueTypeArray,
    description = "",
    percentage = 100,
    list_order = 0
  ) {
    this.id = id;
    this.serviceObj = serviceObject;
    this.amount = parseFloat(amount);
    this.taxType = vatCode;
    this.price = parseFloat(price);
    this.unit = unit;
    this.revenueTypeArray = revenueTypeArray;
    this.percentage = percentage;
    this.description = description
      ? description
      : serviceObject
      ? serviceObject.service_name
      : null;
    this.list_order = list_order;
    this.costs = [];
    this.expanded = false;
  }

  get processedRevenue() {
    let obj = {};
    if (this.id) obj.id = this.id;
    if (this.taxType) obj.vat = this.taxType.accounting_code ?? null;
    if (this.serviceObj) {
      obj.service_code = this.serviceObj.service_code;
      obj.service_name = this.serviceObj.service_name;
    }
    obj.amount = this.amount;
    obj.unit = this.unit;
    obj.price = this.price;
    obj.revenueTypeArray = this.revenueTypeArray ?? null;
    obj.description = this.description;
    obj.percentage = this.percentage;
    obj.list_order = this.list_order;

    return obj;
  }

  get tax() {
    if (this.taxType?.tax_percentage) {
      let computeTaxPercent = this.taxType.tax_percentage ?? 0;
      return round2(
        (this.amount * this.price * (computeTaxPercent / 100) * 100) / 100
      );
    }
    return 0;
  }

  get total() {
    if (this.taxType?.tax_percentage) {
      let computeTaxPercent = this.taxType.tax_percentage ?? 0;
      return round2(this.amount * this.price * (1 + computeTaxPercent / 100));
    }
    return round2(parseFloat(this.amount ?? 0) * parseFloat(this.price ?? 0));
  }

  get isValid() {
    return true;
  }
}
