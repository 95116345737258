export const costUtil = {
  edit(cost) {
    return this.apiRequest(
      `costs/${this.companyId}/costs/${cost.type}/${cost.id}/edit/`,
      "patch",
      true,
      cost
    );
  },

  selfCost(cost) {
    return this.apiRequest(
      `costs/${this.companyId}/costs/${cost.type}/${cost.id}/edit/`,
      "patch",
      true,
      {
        self_cost: true,
      }
    );
  },

  deleteCost(cost) {
    return this.apiRequest(
      `costs/${this.companyId}/costs/${cost.type}/${cost.id}/delete/`,
      "delete",
      true
    );
  },
};
