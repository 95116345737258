<template>
  <div
    class="flex justify-start items-center relative w-auto flex-grow max-w-full"
    @click="enableWheel"
    ref="wrapper"
  >
    <div
      class="flex items-center w-full max-w-full bg-offwhite rounded h-8 shadow focus-within:ring-2 ring-primary ring-opacity-25 duration-150"
    >
      <input
        v-model="duration"
        @keydown.tab="resetVariables"
        class="h-full focus:ring-0 duration-100 shadow-none p-0 w-full max-w-full time-selector-input px-1"
      />
    </div>
    <div
      class="flex justify-start items-center relative w-auto flex-grow max-w-full"
      :data-is-time-input="key"
    >
      <transition v-if="enabled" name="fade" mode="out-in" :duration="150">
        <div
          class="time-selector-menu"
          :style="posComputed"
          v-on-clickaway="handleClickAway"
          :data-is-time-input="key"
        >
          <div class="flex">
            <button
              class="btn-danger"
              @click.stop.prevent="
                () => {
                  resetVariables();
                }
              "
            >
              x
            </button>
          </div>

          <div
            class="time-selector-element mb-4"
            v-if="selectMinute"
            key="minute"
          >
            <div class="time-selector-foreground">
              <div
                v-for="item in minuteSelector"
                :key="`minute-${item}`"
                class="outer"
                @click.stop.prevent="
                  () => {
                    setTimeFromProp(hour, item);
                    resetVariables();
                  }
                "
                :class="minute === item ? 'selected' : ''"
              >
                {{ formatTimeUnit(item) }}
              </div>
            </div>
            <div class="time-selector-background">
              <div class="center"></div>
            </div>
          </div>
          <div class="time-selector-element mb-4" v-else key="hour">
            <div class="time-selector-foreground">
              <div
                v-for="item in hourSelectorOuter"
                :key="`hour-${item}`"
                @click="
                  () => {
                    blockCloseMenu = true;
                    setTimeFromProp(item, minute);
                    selectMinute = true;
                  }
                "
                class="outer"
                :class="hour === item ? 'selected' : ''"
              >
                {{ formatTimeUnit(item) }}
              </div>
              <div
                v-for="item in hourSelectorInner"
                :key="`hour-${item}`"
                @click.stop.prevent="
                  () => {
                    blockCloseMenu = true;
                    setTimeFromProp(item, minute);
                    selectMinute = true;
                  }
                "
                class="inner"
                :class="hour === item ? 'selected' : ''"
              >
                {{ formatTimeUnit(item) }}
              </div>
            </div>
            <div class="time-selector-background">
              <div class="center"></div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { vMaska } from "maska";
import { formatTimeUnit } from "@/assets/utils/commonTransforms";
import { enable } from "core-js/internals/internal-metadata";

export default {
  props: {
    hourCap: {
      type: Number,
      default: 0,
    },
    minuteCap: {
      type: Number,
      default: 0,
    },
    initialDuration: {
      type: Object,
      default: () => {
        return { hours: 1, minutes: 0 };
      },
    },
    delimiters: {
      type: Array,
      default: () => [",", ".", ":"],
    },
    pos: {
      type: String,
      default: "br",
    },
    inline: {
      type: Boolean,
      default: false,
    },
    offset: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      duration: "00:00",
      showWheel: false,
      minuteSelector: [20, 25, 30, 35, 40, 45, 50, 55, 0, 5, 10, 15],
      hourSelectorOuter: [4, 5, 6, 7, 8, 9, 10, 11, 0, 1, 2, 3],
      hourSelectorInner: [15, 16, 17, 18, 19, 20, 21, 22, 23, 12, 13, 14],
      durationInput: "00:00",
      hour: null,
      minute: null,
      selectMinute: false,
      blockCloseMenu: false,
      enabled: false,
      key: null,
    };
  },
  directives: {
    maska: vMaska,
  },
  mounted() {
    this.key = this.generateKey();
    this.hour = this.initialDuration.hours ?? 1;
    this.minute = this.initialDuration.minutes ?? 0;
    this.duration = `${this.initialDuration.hours
      .toString()
      .padStart(2, "0")}:${this.initialDuration.minutes
      .toString()
      .padStart(2, "0")}`;
  },
  methods: {
    enable,
    formatTimeUnit,
    enableWheel() {
      setTimeout(() => {
        this.enabled = true;
      });
      this.showWheel = true;
    },
    broadcastUpdate() {
      let durationObj = {
        hours: 0,
        minutes: 0,
      };
      let delimiterUsed = this.getDelimiter();
      let splitDur = this.duration.split(delimiterUsed);
      if (delimiterUsed !== ":") {
        let minute = splitDur[1] ? parseFloat(`0.${splitDur[1]}`) * 60 : 0;

        durationObj = {
          hours: splitDur[0] ? parseInt(splitDur[0]) : 0,
          minutes: minute,
        };
      } else {
        durationObj = {
          hours: parseInt(splitDur[0] ?? 0),
          minutes: parseInt(splitDur[1]),
        };
      }
      this.$emit("updated", durationObj);
    },
    getDelimiter() {
      let delimiter = this.duration
        .split("")
        .filter((x) => this.delimiters.includes(x));
      if (delimiter.length === 0) return null;
      return delimiter[0];
    },
    checkIfHasDelimiter(input) {
      return (
        input.split("").filter((x) => this.delimiters.includes(x)).length > 0
      );
    },
    setDurationToFullDay() {
      this.duration = "08:00";
    },
    setTimeFromProp(hour, minute) {
      this.hour = hour;
      this.minute = minute;
      this.durationInput = `${formatTimeUnit(hour)}:${formatTimeUnit(minute)}`;
      setTimeout(() => (this.blockCloseMenu = false), 1000);
      this.duration = `${String(this.hour).padStart(2, "0")}:${String(
        this.minute
      ).padStart(2, "0")}`;
    },
    resetVariables() {
      this.showWheel = false;
      this.enabled = false;
      this.selectMinute = false;
    },
    handleClickAway(event) {
      if (event.target.dataset.isTimeInput !== this.key) {
        this.resetVariables();
      }
    },
    generateKey() {
      return String(Math.random() * 10000000);
    },
  },
  computed: {
    posComputed() {
      let elPos = this.$refs.wrapper.getBoundingClientRect();
      if (this.pos === "t")
        return {
          bottom:
            window.innerHeight -
            elPos.bottom +
            elPos.height +
            this.offset +
            "px",
          left: elPos.left + "px",
        };
      if (this.pos === "m")
        return {
          top: `${(window.innerHeight - 208) / 2}px`,
          left: `${(window.innerWidth - 208) / 2}px`,
        };
      if (this.pos === "r")
        return {
          top: elPos.top + "px",
          left: elPos.right + this.offset + "px",
        };
      return {
        top: elPos.bottom + this.offset + "px",
        left: elPos.left + "px",
      };
    },
  },
  watch: {
    duration: {
      handler(val, oldVal) {
        this.duration = val.replace(/[^0-9:,.]/g, "");
        const checks = [
          val.length > 5,
          val.split("").filter((x) => this.delimiters.includes(x)).length > 1,
          this.checkIfHasDelimiter(val) &&
            val.split(this.getDelimiter())[1].length > 2,
        ];

        if (checks.some((check) => check === true)) this.duration = oldVal;

        this.broadcastUpdate();
      },
      immediate: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.time-selector-menu {
  @apply fixed bg-offwhite shadow rounded flex flex-col p-2 isolate;
  z-index: 999;
  .time-selector-element {
    @apply bg-white rounded-full w-52 h-52 relative z-50 shadow border border-offwhite-dark;
    .time-selector-background {
      @apply absolute w-52 h-52 z-40;
      top: 0;
      left: 0;
      .center {
        @apply bg-offwhite-dark rounded-full w-3 h-3 absolute;
        left: calc(50% - 0.375rem);
        top: calc(50% - 0.375rem);
      }
    }
    .time-selector-foreground {
      @apply relative w-52 h-52 rounded-full z-50;
      $angle: calc(360 / 12);
      $rotation: 0;
      @for $i from 0 through 12 {
        .outer:nth-child(#{$i}) {
          @apply w-6 h-6 flex items-center justify-center cursor-pointer;
          position: absolute;
          left: 5.75rem;
          top: 5.75rem;
          transform: rotate($rotation * 1deg)
            translate(calc(11rem / 2))
            rotate($rotation * -1deg);
        }
        $rotation: $rotation + $angle;
      }
      $rotation: 0;
      @for $i from 13 through 24 {
        .inner:nth-child(#{$i}) {
          @apply w-6 h-6 flex items-center justify-center cursor-pointer;
          position: absolute;
          left: 5.75rem;
          top: 5.75rem;

          transform: rotate($rotation * 1deg)
            translate(calc(7.5rem / 2))
            rotate($rotation * -1deg);
        }
        $rotation: $rotation + $angle;
      }
      div {
        &:hover {
          @apply bg-primary text-white rounded-full;
        }
        &.selected {
          @apply bg-primary text-white rounded-full;
        }
      }
    }
  }
}
.time-selector-input {
  width: calc(100% - 1.5rem);
}
.time-selector {
  @apply shadow text-center;
  &:focus {
    @apply shadow-outline;
  }
}
.ui-select {
  @apply shadow-lg;
}
/* This is to remove the arrow of select element in IE */
select::-ms-expand {
  display: none;
}
select {
  -webkit-appearance: none;
  appearance: none;
}
</style>
