<template>
  <div class="flex flex-col">
    <div class="data-container">
      <div class="data-container-header">
        <h3>Tööajad</h3>
      </div>
      <div class="data-container-content">
        <div class="timer-item" v-for="timer in timerData" :key="timer.id">
          <div class="timer-form-row grid-cols-3">
            <div class="title"><gwd-formlabel title="Kuupäev" /></div>
            <div class="title"><gwd-formlabel title="Algusaeg" /></div>
            <div class="title"><gwd-formlabel title="Lõppaeg" /></div>
            <div class="content">
              <span>{{ moment(timer.stopper_start).format("DD.MM") }}</span>
            </div>
            <div class="content">
              <span>{{ moment(timer.stopper_start).format("HH:mm") }}</span>
            </div>
            <div class="content">
              <span>
                {{
                  timer.stopper_end
                    ? moment(timer.stopper_end).format("HH:mm")
                    : "Käigus"
                }}
              </span>
            </div>
          </div>
          <div class="timer-form-row">
            <div class="title">
              <gwd-formlabel title="Info" />
            </div>
            <div class="content">
              <input
                v-if="!timer.confirmed"
                type="text"
                v-model="timer.info"
                @change="debounceSaveTimerInfo(timer)"
              />
              <span v-else>{{ timer.info ? timer.info : "-" }}</span>
            </div>
          </div>
          <div v-if="timer.amount" class="timer-form-row grid-cols-3">
            <div class="title"><gwd-formlabel title="Tööaeg" /></div>
            <div class="title"><gwd-formlabel title="Tunnihind" /></div>
            <div class="title"><gwd-formlabel title="Kokku" /></div>
            <div class="content">
              <span>{{ decimalToHour(timer.amount) }}h</span>
            </div>
            <div class="content">
              <span>{{ timer.price }}€</span>
            </div>
            <div class="content">
              <span>{{ round2(timer.amount * timer.price) }}€</span>
            </div>
          </div>
        </div>
        <div
          v-if="timerData.length > 0 && !timerData.some((x) => !x.stopper_end)"
          class="timer-form-row grid-cols-2"
        >
          <div class="title"><gwd-formlabel title="Kogutööaeg" /></div>
          <div class="title"><gwd-formlabel title="Töö summa" /></div>
          <div class="content">
            <span> {{ parseTimerData(timerData, userId) }}h</span>
          </div>
          <div class="content">
            <span> {{ totalSum }}€</span>
          </div>
        </div>
        <div v-if="timerData.length === 0">
          <span class="py-3 px-1">Tööajad puuduvad</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
import { round2 } from "@/assets/utils/commonMath";
import { debounce } from "lodash";
import { decimalToHour, parseTimerData } from "@/assets/utils/commonTransforms";

export default {
  name: "TaskWorkerTimes",
  props: {
    taskId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      moment: moment,
    };
  },
  methods: {
    parseTimerData,
    decimalToHour,
    round2,
    debounceSaveTimerInfo: debounce(function (timer) {
      this.apiRequest(
        `tasks/${this.taskId}/stopper/${timer.id}/edit/`,
        "patch",
        true,
        { info: timer.info }
      );
    }, 250),
  },
  mounted() {
    this.$store.dispatch("modals/taskModal/retrieveTimerData", this.taskId);
  },
  computed: {
    ...mapGetters({
      timerData: "modals/taskModal/timerData",
      userId: "userData/userId",
    }),
    totalSum() {
      return this.timerData.reduce(
        (prev, curr) => round2(curr.amount * curr.price) + prev,
        0
      );
    },
  },
};
</script>

<style scoped lang="scss">
.timer-item {
  @apply flex flex-col w-full mb-2;
}

.timer-item:not(:last-child) {
  @apply border-b border-bordergrey;
}
.timer-form-row {
  @apply grid items-center mb-1;
  .title {
    @apply flex mb-1;
  }
  .content {
    @apply flex;
  }
}
</style>
