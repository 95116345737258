<template>
  <div class="modal">
    <div class="modal-body" v-on-clickaway:mousedown="closeModal">
      <div class="flex modal-content">
        <l-map
          class="rounded-xl flex flex-grow h-64"
          :options="mapOptions"
          :interactive="false"
          ref="map"
        >
          <l-tile-layer
            v-if="mapInited"
            :url="tileUrl"
            :attribution="'© OpenStreetMap contributors'"
            :position="'bottomleft'"
          ></l-tile-layer>
          <l-circle
            v-if="
              mapInited &&
              startLocation &&
              Object.keys(startLocation).length > 0
            "
            :lat-lng="[startLocation.latitude, startLocation.longitude]"
            :radius="Number(startLocation.accuracy)"
            :color="'#23c7ac'"
          >
            <l-tooltip
              :content="'Algus'"
              :options="{ permanent: true, direction: 'left' }"
            ></l-tooltip>
          </l-circle>
          <l-circle
            v-if="
              mapInited && endLocation && Object.keys(endLocation).length > 0
            "
            :lat-lng="[endLocation.latitude, endLocation.longitude]"
            :radius="Number(endLocation.accuracy)"
            :color="'#3600cd'"
          >
            <l-tooltip
              :content="'Lõpp'"
              :options="{ permanent: true, direction: 'right' }"
            ></l-tooltip>
          </l-circle>
          <l-marker
            v-if="
              mapInited && taskLocation && Object.keys(taskLocation).length > 0
            "
            :lat-lng="[taskLocation.latitude, taskLocation.longitude]"
          >
            <l-tooltip
              :content="'Tellimuse asukoht'"
              :options="{ permanent: true }"
            ></l-tooltip>

            <l-icon icon-url="/bc21/placeholder.svg"></l-icon>
          </l-marker>
        </l-map>
      </div>
      <div class="modal-bottom-bar">
        <button class="btn-danger" @click="closeModal">
          <span class="typcn typcn-times icon"></span
          ><span class="label">Sulge</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  LMap,
  LTileLayer,
  LCircle,
  LMarker,
  LIcon,
  LTooltip,
} from "vue2-leaflet";

import { latLng } from "leaflet";

export default {
  name: "LocationModal",
  components: {
    LMap,
    LCircle,
    LTileLayer,
    LMarker,
    LIcon,
    LTooltip,
  },
  props: {
    startLocation: {
      type: Object,
      default: () => {
        return {};
      },
    },
    endLocation: {
      type: Object,
      default: () => {
        return {};
      },
    },
    taskLocation: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      tileUrl: "https://{s}.tile.osm.org/{z}/{x}/{y}.png",
      mapOptions: {
        zoom: 6,
        center: latLng(58.5953, 25.0136),
        interactive: false,
      },
      mapInited: false,
    };
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    generateCenterPoint(map) {
      let latitudeArray = [];
      let longitudeArray = [];
      let boundsArray = [];
      let count = 0;
      if (this.taskLocation && Object.keys(this.taskLocation).length > 0) {
        count++;
        latitudeArray.push(parseFloat(this.taskLocation.latitude));
        longitudeArray.push(parseFloat(this.taskLocation.longitude));
        boundsArray.push([
          parseFloat(this.taskLocation.latitude),
          parseFloat(this.taskLocation.longitude),
        ]);
      }
      if (this.startLocation && Object.keys(this.startLocation).length > 0) {
        count++;
        latitudeArray.push(parseFloat(this.startLocation.latitude));
        longitudeArray.push(parseFloat(this.startLocation.longitude));
        boundsArray.push([
          parseFloat(this.startLocation.latitude),
          parseFloat(this.startLocation.longitude),
        ]);
      }
      if (this.endLocation && Object.keys(this.endLocation).length > 0) {
        count++;
        latitudeArray.push(parseFloat(this.endLocation.latitude));
        longitudeArray.push(parseFloat(this.endLocation.longitude));
        boundsArray.push([
          parseFloat(this.endLocation.latitude),
          parseFloat(this.endLocation.longitude),
        ]);
      }
      let latitudeCenter = latitudeArray.reduce((a, b) => a + b, 0) / count;
      let longitudeCenter = longitudeArray.reduce((a, b) => a + b, 0) / count;
      map.center = latLng(latitudeCenter, longitudeCenter);
      map.fitBounds(boundsArray);
    },
    initLocation() {
      const map = this.$refs.map.mapObject;
      this.generateCenterPoint(map);
      this.mapInited = true;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initLocation();
    });
  },
};
</script>

<style lang="scss">
.map-container {
  min-height: 325px;
}
@media screen and (max-width: 992px) {
  .map-container {
    min-height: auto;
  }
}
</style>
