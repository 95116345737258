<template>
  <div class="flex flex-col gap-y-2 w-full" v-if="timesInited">
    <div class="flex flex-col flex-grow gap-y-2">
      <div class="flex">
        <gwd-formlabel
          title="Kuupäev"
          :required="true"
          :valid="!$v.timeObj.startDate.$invalid"
          :error="$v.timeObj.startDate.$error"
          class="flex-1"
        />
        <date-selector
          class="flex-1"
          :class="[$v.$error && $v.timeObj.startDate.$invalid ? 'error' : '']"
          v-model="timeObj.startDate"
          @input="() => updateEndTime()"
          pos="br"
          :big-text="true"
        />
      </div>
      <div
        class="flex"
        v-if="
          timeObj.endDate &&
          !moment(timeObj.endDate).isSame(moment(timeObj.startDate), 'day')
        "
      >
        <h4 class="flex-1">Lõpp</h4>
        <span class="flex-1">{{
          moment(timeObj.endDate).format("DD.MM.YYYY")
        }}</span>
      </div>
      <div class="flex items-center gap-x-2">
        <div class="flex items-center gap-x-2">
          <gwd-formlabel title="Kordub" />
          <div
            class="flex flex-row mobile:flex-col items-center mobile:items-start"
          >
            <gwd-checkbox
              v-model="timeObj.isRepeating"
              class="mr-3 mobile:order-2"
              size="6"
            />
          </div>
          <gwd-formlabel title="Kogu päev" />
          <button class="btn-primary" @click="handleAllDaySelected">
            <span class="label"> 9-17 </span>
          </button>
        </div>
      </div>
      <div class="flex flex-col">
        <div class="flex gap-x-2">
          <div class="w-1/3">
            <gwd-formlabel
              title="Algus"
              :required="true"
              :valid="!$v.timeObj.startTime.$invalid"
              :error="$v.timeObj.startTime.$error"
            />
          </div>
          <div class="w-1/3">
            <gwd-formlabel
              title="Kestvus"
              :required="true"
              :valid="!$v.timeObj.endTime.$invalid"
              :error="$v.timeObj.endTime.$error"
            />
          </div>
          <div class="w-1/3">
            <gwd-formlabel
              title="Lõpp"
              :required="true"
              :valid="!$v.timeObj.endTime.$invalid"
              :error="$v.timeObj.endTime.$error"
            />
          </div>
        </div>
        <div class="flex w-full items-center gap-x-2">
          <div class="flex task-input-container w-1/3 pr-2">
            <time-selector
              class="justify-end"
              v-model="timeObj.startTime"
              @input="updateEndTime"
              ref="startTime"
            />
          </div>
          <div class="flex task-input-container w-1/3">
            <duration-selector
              @updated="
                (e) => {
                  this.taskDuration = e;
                  this.updateEndTime();
                }
              "
              ref="durationSelector"
              :initial-duration="initialDuration"
            />
          </div>
          <div class="flex task-input-container w-1/3">
            <span>{{ `${endTimeFormatted}` }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-col" v-if="timeObj.isRepeating">
      <h4>Sagedus</h4>
      <div class="flex items-center">
        <input
          type="number"
          v-model="timeObj.repeatAmount"
          class="w-3/12 mr-2"
        />
        <select class="mr-2" v-model="timeObj.repeatPeriod">
          <option
            v-for="period in timeObj.periods"
            :key="period.en"
            :value="period.en"
          >
            {{ period.ee }}
          </option>
        </select>
        <span>tagant</span>
      </div>
    </div>
    <transition name="fade">
      <div
        class="flex flex-col w-full mobile:w-full"
        v-if="timeObj.isRepeating"
      >
        <div class="flex flex-col w-full gap-y-2">
          <h4>Lõpp</h4>
          <div class="flex gap-x-4 items-center w-full">
            <div class="items-center flex gap-x-2">
              <input
                type="radio"
                id="endDate"
                value="1"
                v-model="timeObj.repeatType"
                class="shadow-none focus:shadow-none"
              />
              <span :class="timeObj.repeatType === '1' ? 'font-medium' : ''">
                Korduste arv
              </span>
            </div>
            <div class="flex items-center gap-x-2">
              <input
                type="radio"
                id="endCount"
                value="2"
                v-model="timeObj.repeatType"
                class="shadow-none focus:shadow-none"
              />
              <span :class="timeObj.repeatType === '2' ? 'font-medium' : ''"
                >Kuupäev</span
              >
            </div>
          </div>
          <div class="flex items-center">
            <div class="flex items-center" v-if="timeObj.repeatType === '1'">
              <input
                :class="timeObj.repeatType === '2' ? 'disabled' : ''"
                type="number"
                step="1"
                v-model="timeObj.repeatCount"
                class="flex-shrink flex mr-3 w-1/3"
                :disabled="timeObj.repeatType === '2'"
              />
              <span>korda</span>
            </div>
            <div class="flex" v-if="timeObj.repeatType === '2'">
              <date-selector
                :language="ee"
                :format="formatDate"
                :monday-first="true"
                :value="timeObj.repeatEndDate.toDate()"
                @selected="handleRepeatEndDateSelect"
                :disabled="timeObj.repeatType === '1'"
                pos="b"
                class="repeating-datepicker w-full"
              />
            </div>
            <div class="flex ml-auto items-center">
              <span class="mr-3">Lau/Pühapäev välja</span>
              <gwd-checkbox v-model="timeObj.skipWeekends" size="6" />
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
const periods = [
  {
    ee: "päeva",
    en: "DAILY",
  },
  {
    ee: "nädala",
    en: "WEEKLY",
  },
  {
    ee: "kuu",
    en: "MONTHLY",
  },
  {
    ee: "aasta",
    en: "YEARLY",
  },
];

import DateSelector from "@/components/globals/DateSelector";
import TimeSelector from "@/components/reusable/TimeSelector";
import DurationSelector from "@/components/reusable/DurationSelector";
import moment from "moment";
import "moment/locale/et";
import { ee } from "vuejs-datepicker/dist/locale";
import { required } from "vuelidate/lib/validators";
import { cloneDeep } from "lodash";
import { mapState, mapGetters } from "vuex";
import { formatTimeUnit } from "@/assets/utils/commonTransforms";
export default {
  data() {
    return {
      ee: ee,
      moment: moment,
      timesInited: false,
      taskDuration: null,
    };
  },
  components: {
    TimeSelector,
    DateSelector,
    DurationSelector,
  },
  mounted() {
    this.initTimes();
  },
  methods: {
    formatTimeUnit,
    initTimes() {
      this.taskDuration = {
        hours: 1,
        minutes: 0,
      };
      if (this.inputData?.date) {
        this.timeObj.startDate = moment(this.inputData.date);
        this.timeObj.endDate = moment(this.inputData.date);
        this.timeObj.repeatEndDate = moment(this.inputData.date);
      }
      // else {
      //   this.timeObj.startDate = moment();
      //   this.timeObj.endDate = moment();
      //   this.timeObj.repeatEndDate = moment();
      // }
      if (this.inputData?.startTime) {
        this.timeObj.startTime = {
          hour: this.inputData.startTime.hour,
          minute: this.inputData.startTime.minute,
        };
        this.timeObj.endTime = {
          hour: this.inputData.startTime.hour + 1,
          minute: this.inputData.startTime.minute,
        };
      } else {
        let startTime;
        if (!moment().isSame(this.timeObj.startDate, "day")) {
          startTime = moment().set("hour", 9).set("minute", 0);
        } else {
          startTime = moment().add(1, "h");
          if (startTime.minute <= 30) startTime.minute(30);
          else startTime.add(1, "h").startOf("hour");
        }

        let endTime = moment(startTime).add(1, "h");
        this.timeObj.startTime = {
          hour: startTime.hour(),
          minute: startTime.minute(),
        };
        this.timeObj.endTime = {
          hour: endTime.hour(),
          minute: endTime.minute(),
        };
      }
      this.timeObj.periods = periods;
      this.timesInited = true;
    },
    handleAllDaySelected() {
      this.$refs.durationSelector.setDurationToFullDay();
      this.$refs.startTime.setTimeFromProp(9, 0);
    },
    handleRepeatEndDateSelect(e) {
      console.log(e);
    },
    updateEndTime() {
      if (this.timeObj && this.timeObj.startDate && this.timeObj.startTime) {
        let newEndTime = moment(this.timeObj.startDate);
        newEndTime.set("hour", this.timeObj.startTime.hour);
        newEndTime.set("minute", this.timeObj.startTime.minute);
        newEndTime
          .add(this.taskDuration.hours ?? 1, "h")
          .add(this.taskDuration.minutes ?? 0, "m");
        this.timeObj.endDate = newEndTime;
        this.timeObj.endTime.hour = newEndTime.hour();
        this.timeObj.endTime.minute = newEndTime.minute();
      }
    },
    getTimeObj() {
      let timeObj = cloneDeep(this.timeObj);
      timeObj.invalid = this.$v.$invalid;

      return timeObj;
    },
    formatDate(date) {
      return moment(date).format("DD.MM.YYYY");
    },
  },
  watch: {
    timeObj: {
      deep: true,
      handler() {
        this.$store.dispatch("modals/addTaskModal/setTimeObj", this.timeObj);
      },
    },
  },
  computed: {
    initialDuration() {
      if (this.inputData.duration) return this.inputData.duration;
      return { hours: 1, minutes: 0 };
    },
    ...mapState({
      timeObj: (state) => state.modals.addTaskModal.timeObj,
    }),
    ...mapGetters({
      inputData: "modals/addTaskModal/inputData",
      isAdmin: "companyData/isAdmin",
    }),
    disabledDates() {
      const threshold = new Date();
      threshold.setDate(threshold.getDate() - 1);
      return { to: threshold };
    },
    endTimeFormatted() {
      return moment(this.timeObj.endTime).format("HH:mm");
    },
  },
  validations: {
    timeObj: {
      startDate: { required },
      startTime: { required },
      endTime: { required },
    },
  },
};
</script>
<style lang="scss">
.repeat-date-selector {
  .vdp-datepicker {
    div {
      input {
        @apply w-full;
      }
    }
  }
}
.disabled-repeat-date-selector {
  .vdp-datepicker {
    div {
      input {
        @apply bg-offwhite text-bordergrey w-full;
      }
    }
  }
}
.task-input-container {
  @apply flex-col;
}
</style>
