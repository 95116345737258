<template>
  <div
    class="gwd-table-row"
    :class="
      isAdmin || isManager ? 'extra-cost-table' : 'extra-cost-table-worker'
    "
  >
    <span class="mobile:hidden">{{
      extraCost.worker
        ? extraCost.worker.worker_name
        : !isManager
        ? "(Mina)"
        : "-"
    }}</span>
    <span>{{ extraCost.name }}</span>
    <div v-if="isAdmin || isManager">
      <div class="flex justify-end items-center gap-x-1" v-if="edit">
        <decimal-input v-model.number="editables.amount" class="ml-auto" />
        <select class="add-offer-input" v-model="editables.unit">
          <option v-for="unit in units" :value="unit" :key="unit">
            {{ unit }}
          </option>
        </select>
      </div>
      <span class="mobile:hidden" v-else>
        {{ extraCost.amount }} {{ extraCost.unit }}
      </span>
    </div>
    <span v-else> {{ extraCost.amount }} {{ extraCost.unit }} </span>
    <div v-if="isAdmin || isManager" class="flex">
      <span v-if="extraCost.confirmed || !edit">{{ extraCost.cost }} €</span>
      <decimal-input
        v-if="!extraCost.confirmed && edit"
        v-model="editables.cost"
      />
    </div>
    <span v-if="isAdmin || isManager">
      <span v-if="extraCost.confirmed || !edit">
        {{ extraCost.cost_coefficient }}
      </span>
      <decimal-input
        v-if="!extraCost.confirmed && edit"
        v-model="editables.cost_coefficient"
      />
    </span>
    <span v-if="isAdmin || isManager">
      {{
        round2(extraCost.amount * extraCost.cost * extraCost.cost_coefficient)
      }}
      €
    </span>
    <div v-if="isAdmin || isManager" class="flex mobile:hidden">
      <span v-if="extraCost.confirmed || !edit">{{ extraCost.price }} €</span>
      <decimal-input
        v-if="!extraCost.confirmed && edit"
        v-model="editables.price"
      />
    </div>
    <span v-else> {{ extraCost.price }} € </span>
    <div v-if="isAdmin || isManager">
      <div v-if="extraCost.confirmed || !edit">
        {{ extraCost.vatType ? `${extraCost.vatType.description}` : "-" }}
      </div>
      <select v-else class="max-w-full w-full" v-model="editables.vatType">
        <option
          v-for="taxType in taxTypes.filter((x) => x.outgoing_tax_code)"
          :key="taxType.id"
          :value="taxType"
        >
          {{ `${taxType.description}` }}
        </option>
      </select>
    </div>
    <span>{{ extraCost.info }}</span>
    <span>
      {{
        round2(
          extraCost.price * extraCost.amount +
            extraCost.price *
              extraCost.amount *
              parseFloat(
                extraCost.vatType
                  ? parseFloat(extraCost.vatType.tax_percentage) / 100
                  : "0"
              )
        )
      }}
      €
    </span>
    <div class="flex">
      <div v-if="isManager" class="flex items-center gap-x-1">
        <div v-if="!extraCost.confirmed && !edit">
          <button
            class="btn-primary"
            @click="$emit('confirmExtraCost', extraCost)"
          >
            Kinnita
          </button>
        </div>
        <button
          v-if="!edit && !extraCost.confirmed"
          class="btn-primary"
          @click="edit = true"
        >
          <span class="typcn typcn-edit" />
        </button>
        <button v-if="edit" class="btn-primary" @click="editCost">
          <span class="typcn typcn-tick" />
        </button>
        <button v-if="edit" class="btn-danger" @click="resetCost">
          <span class="typcn typcn-times" />
        </button>
        <button v-if="edit" class="btn-danger" @click="deleteCostActive = true">
          <span class="typcn typcn-trash" />
        </button>
        <span v-if="extraCost.confirmed && !edit" class="typcn typcn-tick">
          Kinnitatud
        </span>
        <button
          v-if="!edit"
          @click="showExtraInformation = !showExtraInformation"
          class="h-6 w-6 bg-primary hover:bg-primary-dark rounded shadow flex items-center justify-center"
        >
          <img src="/bc21/info.svg" class="h-4 w-4 filter-to-white" alt="?" />
        </button>
      </div>
      <div v-else>
        <span
          class="typcn"
          :class="extraCost.confirmed ? 'typcn-tick' : 'typcn-times'"
        />
      </div>
    </div>
    <div
      v-if="showExtraInformation"
      class="col-span-11 flex flex-col border-b border-t border-offwhite-dark p-2 bg-offwhite"
    >
      <div class="grid grid-cols-4 font-medium">
        <span>Lisaja</span>
        <span>Lisamise aeg</span>
        <span>Kinnitaja</span>
        <span>Kinnitamise aeg</span>
      </div>
      <div class="grid grid-cols-4">
        <span>{{
          extraCost.added_by ? extraCost.added_by.worker_name : "-"
        }}</span>
        <span>{{
          moment(extraCost.added_time).format("DD.MM.YYYY HH:mm")
        }}</span>
        <span>{{
          extraCost.confirmed
            ? extraCost.confirmed_by
              ? extraCost.confirmed_by.worker_name
              : "Kinnitaja puudub"
            : "Kinnitamata"
        }}</span>
        <span>{{
          extraCost.confirmed_time
            ? moment(extraCost.confirmed_time).format("DD.MM.YYYY HH:mm")
            : "-"
        }}</span>
      </div>
    </div>
    <div
      v-if="showExtraInformation && extraCost.editions.length > 0"
      class="col-span-11 flex flex-col border-t border-b border-offwhite-dark p-2 bg-offwhite"
    >
      <div class="grid grid-cols-4 font-medium">
        <span>Muutja</span>
        <span>Väli</span>
        <span>Vana väärtus</span>
        <span>Uus väärtus</span>
      </div>
      <div
        v-for="item in extraCost.editions"
        :key="item.id"
        class="grid grid-cols-4"
      >
        <div>{{ item.editor }}</div>
        <div>
          {{ translateFieldName(item.field_name) }}
        </div>
        <div>
          {{ item.initial_value }}
        </div>
        <div>
          {{ item.new_value }}
        </div>
      </div>
    </div>
    <delete-confirm-modal
      v-if="deleteCostActive"
      @confirmDelete="deleteCost"
      @closeModal="deleteCostActive = false"
    />
  </div>
</template>
<script>
import DecimalInput from "@/components/reusable/DecimalInput.vue";
import { round2 } from "@/assets/utils/commonMath";
import { mapGetters } from "vuex";
import { isEqual } from "lodash";
import DeleteConfirmModal from "@/components/reusable/DeleteConfirmModal.vue";
import moment from "moment";
import { translateFieldName } from "@/assets/utils/commonTransforms";

export default {
  name: "ExtraCost",
  components: { DeleteConfirmModal, DecimalInput },
  props: {
    extraCost: {
      type: Object,
      default: null,
    },
    isAdmin: { type: Boolean, default: false },
    isManager: { type: Boolean, default: false },
    taxTypes: { type: Array, default: () => [] },
  },
  data() {
    return {
      edit: false,
      editables: null,
      deleteCostActive: false,
      showExtraInformation: false,
      moment: moment,
    };
  },
  mounted() {
    this.editables = JSON.parse(JSON.stringify(this.extraCost));
  },
  methods: {
    translateFieldName,
    round2,
    editCost() {
      if (!isEqual(this.editables, this.extraCost)) {
        this.editables.vat = this.editables.vatType
          ? this.editables.vatType.id
          : null;
        this.$emit("editCost", this.editables);
      }
      this.edit = false;
    },
    deleteCost() {
      this.$emit("deleteCost", this.editables);
      this.edit = false;
    },
    resetCost() {
      this.editables = JSON.parse(JSON.stringify(this.extraCost));
      this.edit = false;
    },
  },
  computed: {
    ...mapGetters({
      units: "companyVariables/units",
    }),
  },
};
</script>
<style scoped lang="scss">
input {
  width: 95%;
}
select {
  width: 75%;
  option {
    width: 75%;
  }
}
</style>
