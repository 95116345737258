<template>
  <div class="data-container">
    <div class="data-container-header gap-x-2">
      <h3>Töötajad</h3>
      <span
        class="rounded text-white font-medium px-2 cursor-default"
        :class="
          assignments.length < taskData.worker_amount
            ? 'bg-danger'
            : 'bg-primary'
        "
      >
        {{ assignments.length }}/{{ taskData.worker_amount }}
      </span>
      <button class="btn-primary" @click="$emit('showWorkerSelector')">
        Lisa
      </button>
      <button class="btn-danger" @click="clearWorkers">Tühjenda</button>
    </div>
    <div v-if="timeObjSet" class="data-container-content gap-y-1">
      <div
        class="flex items-center w-full gap-x-2"
        v-for="assignment in assignments"
        :key="assignment.worker.id"
      >
        <div class="w-40 font-medium">{{ assignment.worker.worker_name }}</div>
        <div class="w-24 flex gap-x-1">
          <time-selector
            v-model="assignment.start_time"
            v-if="assignment.start_time"
            pos="t"
          />
          <span
            class="w-full h-full hover:bg-offwhite hover:p-1 rounded duration-200 cursor-pointer"
            v-else
            @click="() => (assignment.start_time = timeObj.startTime)"
            >{{
              `${formatTimeUnit(timeObj.startTime.hour)}:${formatTimeUnit(
                timeObj.startTime.minute
              )}`
            }}
          </span>
          <button
            @click="assignment.start_time = null"
            class="btn-danger leading-none h-8 w-5 self-center"
            v-if="assignment.start_time"
          >
            <span class="typcn typcn-times leading-none"></span>
          </button>
        </div>
        <div class="w-24 flex gap-x-1">
          <time-selector
            v-model="assignment.end_time"
            v-if="assignment.end_time"
            pos="t"
          />
          <span
            v-else
            class="w-full h-full hover:bg-offwhite hover:p-1 rounded duration-200 cursor-pointer"
            @click="
              () =>
                (assignment.end_time = {
                  hour: moment(timeObj.endTime).hour(),
                  minute: moment(timeObj.endTime).minute(),
                })
            "
          >
            {{ `${moment(timeObj.endTime).format("HH:mm")}` }}
          </span>
          <button
            @click="assignment.end_time = null"
            class="btn-danger leading-none h-8 w-5 self-center"
            v-if="assignment.end_time"
          >
            <span class="typcn typcn-times"></span>
          </button>
        </div>
        <div class="flex items-center flex-grow gap-x-2">
          <div class="w-1/3 flex gap-x-1">
            <location-inaddress
              v-if="assignment.location"
              v-model="assignment.location"
              class="flex-grow py-0"
            />
            <span
              v-else
              class="w-full truncate px-2 hover:bg-offwhite hover:py-1 duration-200 rounded h-full cursor-pointer"
              @click="
                () =>
                  (assignment.location = taskData.location
                    ? taskData.location
                    : {})
              "
            >
              {{ taskData.location ? taskData.location.long_address : "-" }}
            </span>
            <button
              class="btn-danger btn-danger leading-none h-8 w-5 self-center"
              @click="assignment.location = null"
              v-if="assignment.location"
            >
              <span class="typcn typcn-times leading-none"></span>
            </button>
          </div>
          <div class="w-1/3 flex gap-x-1">
            <location-inaddress
              v-if="assignment.end_location"
              v-model="assignment.end_location"
              class="flex-grow py-0"
            />
            <span
              class="w-full truncate px-2 hover:bg-offwhite hover:py-1 duration-200 rounded h-full cursor-pointer"
              v-else
              @click="
                () =>
                  (assignment.end_location = taskData.endLocation
                    ? taskData.endLocation
                    : {})
              "
              >{{
                taskData.endLocation ? taskData.endLocation.long_address : "-"
              }}</span
            >
            <button
              class="btn-danger leading-none h-8 w-5 self-center"
              @click="assignment.end_location = null"
              v-if="assignment.end_location"
            >
              <span class="typcn typcn-times"></span>
            </button>
          </div>
          <div class="flex w-1/3 border-box">
            <gwd-textarea
              class="w-full"
              v-model="assignment.description"
              placeholder="Kirjeldus"
            />
          </div>
        </div>
        <button class="btn-danger" @click="removeWorker(assignment.worker.id)">
          Eemalda
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import TimeSelector from "../../reusable/TimeSelector.vue";
import LocationInaddress from "../../reusable/LocationInaddress.vue";
import { mapGetters, mapState } from "vuex";
import { formatTimeUnit } from "@/assets/utils/commonTransforms";
import moment from "moment";
export default {
  components: {
    TimeSelector,
    LocationInaddress,
  },
  data() {
    return {
      moment: moment,
      timeObjSet: false,
    };
  },
  methods: {
    formatTimeUnit,
    clearWorkers() {
      this.$emit("clearWorkers");
    },
    removeWorker(id) {
      this.$emit(
        "removeWorker",
        this.assignments.findIndex((item) => item.worker.id === id)
      );
    },
  },
  mounted() {
    if (this.timeObj) this.timeObjSet = true;
  },
  watch: {
    timeObj: {
      handler() {
        this.timeObjSet = true;
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      timeObj: "modals/addTaskModal/timeObjGetter",
      taskData: "modals/addTaskModal/fields",
    }),
    ...mapState({
      assignments: (state) => state.modals.addTaskModal.assignments,
    }),
  },
};
</script>
