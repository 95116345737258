<template>
  <div class="w-full flex flex-col items-center chat-window h-full">
    <div
      class="flex justify-center items-center h-full"
      v-if="!messagesLoaded || !datesInited"
    >
      <clip-loader size="125px" />
    </div>

    <div
      class="flex flex-col overflow-y-scroll w-full mb-auto mobile:py-3 px-3"
      :class="messages.length === 0 ? 'my-auto' : ''"
      id="chat-window"
      v-if="datesInited"
      @scroll="onScroll"
    >
      <project-comment
        v-for="(message, index) in messages"
        :key="message.uuid"
        :show-header="
          index > 0
            ? messages[index - 1].author.id !== message.author.id ||
              Date.parse(message.created_at) -
                Date.parse(messages[index - 1].created_at) >
                300000
            : true
        "
        :show-date-header="dateHeaderPoints.includes(message.uuid)"
        :time-diff="
          index > 0
            ? Date.parse(message.created_at) -
              Date.parse(messages[index - 1].created_at)
            : 0
        "
        :comment="message"
        :company="companyId"
        :id="`comment-${message.uuid}`"
      />
      <div
        v-if="messages.length === 0"
        class="p-5 font-medium shadow bg-offwhite rounded-gwdfull w-4/12 mobile:w-10/12 m-auto flex justify-center"
      >
        Selles vestluses pole sõnumeid saadetud!
      </div>
    </div>
    <div
      class="flex justify-center items-center relative w-full bg-offwhite py-3 rounded-br"
    >
      <div class="flex w-full relative px-5 mobile:px-2 flex-col">
        <div class="flex relative">
          <textarea
            v-model="content"
            @keydown.enter="saveComment"
            data-cy="commentField"
            class="w-full h-full relative focus:shadow-outline bg-white shadow p-1 rounded"
            rows="2"
            :class="
              $v.$error && $v.content.$invalid
                ? 'shadow-dangeroutline focus:shadow-dangeroutline'
                : ''
            "
          />
          <span
            class="admin-comments-symbol-count absolute"
            :class="symbolsRemaining < 0 ? 'text-danger' : ''"
            >{{ symbolsRemaining }}</span
          >
        </div>
      </div>
      <div
        class="flex flex-row justify-between"
        v-if="fileUpload"
        :class="
          fileList.length > 0
            ? 'justify-between items-end mt-2'
            : 'justify-end items-center'
        "
      >
        <div class="flex flex-grow items-center" v-if="uploadInProgress">
          <ClipLoader />
          <span class="ml-4">Faile laetakse üles...</span>
        </div>

        <div
          class="flex justify-end mobile:justify-between px-1 mt-2 ml-auto mobile:ml-0 mobile:w-full"
          v-if="!uploadInProgress"
        >
          <button
            @click="displayFileUpload = !displayFileUpload"
            :class="displayFileUpload ? 'btn-danger' : 'btn-primary'"
          >
            <span
              class="typcn icon"
              :class="displayFileUpload ? 'typcn-times' : 'typcn-document-add'"
            ></span>
            <span class="label">{{
              displayFileUpload ? "Tühista" : "Lisa faile"
            }}</span>
          </button>
          <button @click="saveComment" class="btn-primary ml-3 mobile:ml-0">
            <span class="typcn typcn-chevron-right icon"></span>
            <span class="label">Saada</span>
          </button>
        </div>
      </div>
    </div>
    <div class="flex mt-2 items-center justify-center" v-if="displayFileUpload">
      <file-selector
        @filesUpdated="fileList = $event"
        class="bg-white rounded shadow"
        ref="fileSelector"
      />
    </div>
  </div>
</template>

<script>
import ProjectComment from "@/components/project/comments/ProjectComment.vue";
import moment from "moment/moment";
import { maxLength } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import FileSelector from "@/components/reusable/FileSelector.vue";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import EventBus from "@/assets/mixins/EventBus";

export default {
  name: "ChatOverlord",
  components: { FileSelector, ProjectComment, ClipLoader },
  props: {
    urlSuffix: {
      type: String,
      default: null,
    },
    fileUpload: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      content: "",
      fileList: [],
      uploadInProgress: false,
      dateHeaderPoints: [],
      displayFileUpload: false,
      datesInited: false,
      messages: [],
      messagesLoaded: false,
      socket: null,
      chatParty: null,
      next: null,
    };
  },
  mounted() {
    this.loadMessages();
  },
  beforeDestroy() {
    this.destroySocket();
  },
  methods: {
    onScroll(e) {
      const { scrollTop } = e.target;
      if (scrollTop === 0 && this.next) {
        this.messagesLoaded = false;
        this.apiRequest(this.next, "get", true).then((res) => {
          this.messages = [...res.data.results.reverse(), ...this.messages];
          this.messagesLoaded = true;
          this.next = res.data.next;
          this.messagesLoaded = true;
        });
      }
    },
    async loadMessages() {
      let url;
      if (!isNaN(this.urlSuffix) && !isNaN(parseFloat(this.urlSuffix))) {
        url = "chat/parties/";
      } else {
        url = `chat/${this.companyId}/`;
      }
      this.apiRequest(`${url}${this.urlSuffix}/`, "get", true).then((res) => {
        this.messages = res.data.results.reverse();
        this.messagesLoaded = true;
        this.next = res.data.next;
        this.initHeaders();
        this.initChatPos();
        this.initChatWebsocket();
      });
    },
    initChatWebsocket() {
      let url;
      if (!isNaN(this.urlSuffix) && !isNaN(parseFloat(this.urlSuffix))) {
        url = "chat/";
      } else {
        url = `chat/${this.companyId}/`;
      }
      this.apiRequest(`${url}${this.urlSuffix}/token/`, "get", true)
        .then((res) => {
          const socket = new WebSocket(
            `${process.env.VUE_APP_WS_URL}chat/${res.data.token}/`
          );
          this.socket = socket;
          this.chatParty = res.data.party;
          this.$store.dispatch("chat/togglePartyActive", {
            party_id: this.chatParty,
          });
          this.$store.dispatch("chat/markChatPartyAsRead", this.chatParty);
          this.$store.dispatch(
            "chat/markPartyNotificationsAsRead",
            this.chatParty
          );
          EventBus.$emit("updateTasks");
          this.$emit(
            "notificationTarget",
            res.data.notification_target ?? false
          );
          socket.addEventListener("message", (e) => {
            const data = JSON.parse(e.data);
            this.messages.push(data.message);
            this.initChatPos();
          });
        })
        .catch((err) => {
          if (err.response.status === 503) {
            this.$store.dispatch("messageHandler/throwMessage", {
              err: err,
              text: "Live sync sõnumite saatmine on maas. Sõnumid luuakse aga need ei uuenda ennast automaatselt.",
              type: "error",
              ttl: 15,
            });
          }
        });
    },
    selectFile() {
      let fileInputElement = this.$refs.file;
      fileInputElement.click();
    },
    updateFiles() {
      this.fileList = [...this.$refs.file.files];
    },
    saveComment() {
      this.$v.$touch();
      if (this.$v.$invalid) return console.error("Validation error");

      const data = new FormData();
      data.append("content", this.content);

      this.uploadInProgress = true;
      // for (let file of this.fileList) {
      //   const fileToAppend = new File([file], file.name, {
      //     type: file.type,
      //   });
      //   data.append("files", fileToAppend, file.name);
      // }
      data.append("party", this.chatParty);
      this.apiRequest(`chat/send/`, "post", true, data)
        .then(() => {
          this.resetVariables();
          this.initChatPos();
        })
        .catch((err) => {
          if (err.response.status === 503) {
            this.$store.dispatch("messageHandler/throwMessage", {
              err: err,
              text: "Live sync sõnumite saatmine on maas. Sõnumid luuakse aga need ei uuenda ennast automaatselt.",
              type: "error",
              ttl: 15,
            });
            this.resetVariables();
          } else {
            this.$store.dispatch("messageHandler/throwMessage", {
              err: err,
              text: "Sõnumi saatmise ebaõnnestus!",
              type: "error",
              ttl: 15,
            });
          }
        });
    },
    resetVariables() {
      this.content = "";
      this.uploadInProgress = false;
      this.fileList = [];
      if (this.displayFileUpload) {
        this.$refs.fileSelector.reset();
      }
      this.displayFileUpload = false;
      let self = this;
      setTimeout(function () {
        self.initHeaders();
      }, 150);
    },
    initChatPos() {
      if (this.messages.length > 0) {
        this.$nextTick(() => {
          const lastComment = this.messages[this.messages.length - 1];
          document
            .getElementById(`comment-${lastComment.uuid}`)
            .scrollIntoView({
              behavior: "smooth",
              block: "end",
            });
        });
      }
    },
    parseFileTypeIcon(file) {
      return this.parseUploadedFileType(file);
    },
    initHeaders() {
      this.datesInited = false;
      const datesProcessed = [];
      this.dateHeaderPoints = [];
      let tempMessages = this.messages;
      while (tempMessages.length > 0) {
        this.dateHeaderPoints.push(tempMessages[0].uuid);
        datesProcessed.push(
          moment(tempMessages[0].created_at).format("DD-MM-YYYY")
        );
        tempMessages = tempMessages.filter(
          (x) =>
            !datesProcessed.includes(moment(x.created_at).format("DD-MM-YYYY"))
        );
      }
      this.datesInited = true;
    },
    removeFile() {
      this.fileList.splice(this.fileList.findIndex());
    },
    destroySocket() {
      this.$store.dispatch("chat/togglePartyActive", {
        party_id: this.chatParty,
      });
      this.socket.close();
    },
  },
  computed: {
    ...mapGetters({
      companyId: "companyData/activeCompanyUuid",
    }),
    symbolsRemaining() {
      return 1024 - this.content.length;
    },
  },
  watch: {
    channelType() {
      this.initHeaders();
    },
  },
  validations: {
    content: {
      maxLength: maxLength(1024),
    },
  },
};
</script>

<style lang="scss">
.chat-window {
  @apply overflow-y-scroll h-full;
}
.admin-comments-symbol-count {
  right: 1%;
  bottom: 0%;
  @apply text-grey-light;
}
@media screen and (max-width: 992px) {
  .chat-window {
    min-height: 80vh;
    max-height: 100vh;
  }
}
</style>
