<template>
  <div class="data-container" style="margin-top: auto">
    <div class="data-container-header nopad mobile:flex-col" v-if="!hideHeader">
      <div class="flex w-1/4 mobile:w-full pl-2 mobile:pl-0">
        <h4>Töötajad</h4>
        <button
          class="btn-danger ml-3 text-xs"
          v-if="taskId"
          @click="cancelEdit"
        >
          Tühista muutmine
        </button>
      </div>
      <div class="flex w-3/4 mobile:w-full h-full gap-x-2">
        <div class="w-1/2 mobile:hidden"></div>
        <div
          class="w-1/2 mobile:w-full flex duration-100 h-full divide-x border-offwhite-dark"
        >
          <div
            class="flex px-3 py-1 cursor-pointer h-full items-center"
            :class="view === 1 ? 'bg-primary text-white' : null"
            @click="view = 1"
          >
            <span>Nimekiri</span>
          </div>
          <div
            class="flex px-3 py-1 cursor-pointer h-full items-center"
            :class="view === 5 ? 'bg-primary text-white' : null"
            @click="view = 5"
          >
            <span>Kõik töötajad</span>
          </div>
          <div
            class="flex px-3 py-1 duration-100 cursor-pointer h-full items-center"
            @click="view = 2"
            :class="view === 2 ? 'bg-primary text-white cursor-default' : null"
          >
            <span> Generaator </span>
          </div>
          <div
            class="flex px-3 py-1 cursor-pointer h-full items-center"
            :class="view === 3 ? 'bg-primary text-white' : null"
            @click="view = 3"
          >
            <span>Brigaadid</span>
          </div>
          <div
            class="flex px-3 py-1 cursor-pointer h-full items-center"
            :class="view === 4 ? 'bg-primary text-white' : null"
            @click="view = 4"
          >
            <span>Tehnika</span>
          </div>
        </div>
      </div>
    </div>
    <div class="data-container-content flex-grow mb-auto">
      <div class="flex" v-if="!initing">
        <div
          class="flex flex-col w-1/4 gap-y-2 border-r border-offwhite-dark pr-2"
        >
          <div class="flex">
            <div class="flex-2">
              <h4>Töötajate arv</h4>
            </div>
            <div class="flex-1">
              <input type="number" step="1" v-model.number="workerAmount" />
            </div>
          </div>
          <div class="grid gap-y-1 gap-x-2" v-if="workerAttributes">
            <h4>Vajalikud omadused</h4>
            <select-search
              :dataset="
                workerAttributes.filter(
                  (x) => !selectedAttributes.map((y) => y.id).includes(x.id)
                )
              "
              :filter-func="
                (attr, query) =>
                  attr.name.toLowerCase().includes(query.toLowerCase())
              "
              :label="(attr) => attr.name"
              :show-on-focus="true"
              @input="
                (e) => {
                  selectedAttributes.push({ ...e, count: 1 });
                }
              "
              placeholder="Otsi vajalikku omadust"
            />
            <div
              v-for="(attribute, index) in selectedAttributes"
              :key="attribute.id"
              class="flex items-center bg-offwhite rounded gap-x-2 flex-1 box-border"
            >
              <span class="flex-1 p-1">{{ attribute.name }}</span>
              <button
                class="btn-danger"
                @click="selectedAttributes.splice(index, 1)"
              >
                <img
                  src="/bc21/trash.svg"
                  alt="delete"
                  class="h-4 w-4 filter-to-white"
                />
              </button>
              <span class="flex items-center">
                {{
                  attributesInSelected.filter((x) => x === attribute.id).length
                }}/{{ attribute.count }}
              </span>
              <div class="flex h-full">
                <button
                  @click="() => (attribute.count += 1)"
                  class="w-6 h-full bg-primary text-white text-xl font-medium"
                >
                  <span class="typcn typcn-plus"></span>
                </button>
                <button
                  @click="
                    () => {
                      if (attribute.count - 1 >= 0)
                        attribute.count = attribute.count - 1;
                    }
                  "
                  class="w-6 h-full font-medium text-xl leading-none flex items-center justify-center rounded-r duration-100"
                  :class="
                    attribute.count === 0
                      ? 'bg-disabled text-offwhite-dark'
                      : 'bg-danger text-white'
                  "
                >
                  <span class="typcn typcn-minus"></span>
                </button>
              </div>
            </div>
          </div>
          <button
            class="confirm-button relative"
            :class="startTime && endTime ? 'btn-primary' : 'btn-disabled'"
            @click="startTime && endTime ? generateWorkers() : ''"
          >
            <span v-if="!generatingResults"> Genereeri meeskonnad </span>
            <ClipLoader size="16px" v-else />
            <ul class="confirm-checklist" v-if="!startTime && !endTime">
              <li>Vali kuupäev</li>
            </ul>
          </button>
          <button
            @click="confirmSelection"
            :disabled="!hasOneWorker"
            :class="hasOneWorker ? 'btn-primary' : 'btn-disabled'"
          >
            Loo tööülesanded
          </button>
        </div>
        <div class="flex w-3/4 px-2 gap-x-2 overflow-y-hidden">
          <div
            class="flex flex-col gap-y-2 w-1/2 border-r border-offwhite-dark pr-2"
          >
            <div
              class="p-1 rounded shadow duration-100"
              :class="
                workerSlotSelected === index
                  ? 'bg-offwhite-dark'
                  : 'bg-offwhite hover:bg-offwhite-dark'
              "
              v-for="(worker, index) in internalSelectedWorkers"
              :key="`${worker !== undefined ? worker.id : `${index}null`}`"
            >
              <div v-if="!worker" class="flex w-full">
                <span class="flex-1">Töötajat pole</span>
              </div>
              <div v-else class="flex items-center gap-x-2">
                <span
                  :class="worker.attributes.length === 0 ? 'flex-1' : null"
                  >{{ worker.worker_name }}</span
                >
                <div class="flex flex-1" v-if="worker.attributes.length > 0">
                  <div
                    v-for="attribute in worker.attributes"
                    :key="`${worker.id}-${attribute.id}`"
                    class="p-1 text-white rounded leading-none"
                    :class="
                      attributesArr.includes(attribute.id)
                        ? 'bg-green'
                        : 'bg-primary'
                    "
                  >
                    {{ attribute.name }}
                  </div>
                </div>
                <button
                  @click="
                    () => {
                      removeWorker(index);
                    }
                  "
                  class="btn-danger"
                >
                  Eemalda
                </button>
              </div>
            </div>
            <div
              class="flex flex-col mt-auto gap-y-1"
              v-if="internalSelectedEquipment"
            >
              <div
                class="p-1 rounded shadow duration-100 bg-offwhite-dark w-full"
                v-for="equipment in internalSelectedEquipment"
                :key="equipment.id"
              >
                <div class="flex items-center gap-x-2">
                  <span>{{ equipment.name }}</span>
                  <button
                    @click="
                      () => {
                        removeEquipment(equipment);
                      }
                    "
                    class="btn-danger ml-auto"
                  >
                    Eemalda
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-col gap-y-1 w-1/2" v-if="workers && view === 1">
            <div class="flex items-center">
              <search-input v-model="filterText" />
              <span
                @click="handleManagerChange(!managers)"
                class="flex ml-auto justify-center self-center cursor-pointer typcn typcn-coffee h-4 w-4"
              />
            </div>

            <div
              class="flex bg-offwhite p-1 rounded shadow gap-x-2 items-center"
              v-for="worker in workers.results.filter(
                (x) => !vacationWorkers.includes(x.id)
              )"
              :key="`worker-${worker.id}`"
            >
              <div :class="worker.attributes.length === 0 ? 'flex-1' : null">
                {{ worker.worker_name }}
              </div>
              <div class="flex-1" v-if="worker.attributes.length > 0">
                <span
                  v-for="attribute in worker.attributes"
                  :key="`w${worker.id}attr${attribute.id}`"
                  class="p-1 text-white rounded duration-100"
                  :class="
                    attributesArr.includes(attribute.id)
                      ? 'bg-green'
                      : 'bg-primary'
                  "
                >
                  {{ attribute.name }}
                </span>
              </div>
              <div
                v-if="worker.equipment && worker.equipment.length > 0"
                class="bg-primary rounded px-2 py-1 text-white leading-none flex gap-x-1 items-center relative group"
              >
                <img
                  src="/icons/tehnika.svg"
                  class="h-5 w-5 filter-to-white"
                  alt="Equipment icon"
                />
                {{ worker.equipment.length }}
                <div
                  class="absolute top-full hidden group-hover:flex duration-100 bg-white rounded text-black p-3 right-0 flex-col w-64 gap-y-1"
                >
                  <span
                    v-for="equipment in worker.equipment"
                    :key="equipment.id"
                  >
                    {{ equipment.name }}
                  </span>
                </div>
              </div>
              <button
                :class="
                  activeWorkers && activeWorkers.includes(worker.id)
                    ? 'btn-disabled'
                    : conflictingWorkers.includes(worker.id)
                    ? 'btn-attention'
                    : 'btn-primary'
                "
                @click="workerToggeled(worker)"
                :disabled="activeWorkers.includes(worker.id)"
              >
                Vali
              </button>
            </div>
            <page-changer
              :page="page"
              :count="workers.count"
              v-if="workers.count > 1"
              @pageChange="handlePageChange"
            />
            <div v-if="workers.results.length === 0">
              Valitud ajal töötajad puuduvad
            </div>
          </div>
          <div
            v-if="view === 5"
            class="flex flex-col gap-y-1 w-1/2 overflow-y-scroll"
          >
            <div class="flex items-ceview === 5nter">
              <search-input v-model="filterText2" />
              <span
                @click="managers2 = !managers2"
                class="flex ml-auto justify-center self-center cursor-pointer typcn typcn-coffee h-4 w-4"
              />
            </div>
            <div
              class="flex bg-offwhite p-1 rounded shadow gap-x-2 items-center"
              v-for="worker in computedCompanyWorkers"
              :key="worker.id"
            >
              <div :class="worker.attributes.length === 0 ? 'flex-1' : null">
                {{ worker.worker_name }}
              </div>
              <div class="flex-1" v-if="worker.attributes.length > 0">
                <span
                  v-for="attribute in worker.attributes"
                  :key="`w${worker.id}attr${attribute.id}`"
                  class="p-1 text-white rounded duration-100"
                  :class="
                    attributesArr.includes(attribute.id)
                      ? 'bg-green'
                      : 'bg-primary'
                  "
                >
                  {{ attribute.name }}
                </span>
              </div>
              <div
                v-if="worker.equipment && worker.equipment.length > 0"
                class="bg-primary rounded px-2 py-1 text-white leading-none flex gap-x-1 items-center relative group"
              >
                <img
                  src="/icons/tehnika.svg"
                  class="h-5 w-5 filter-to-white"
                  alt="Equipment icon"
                />
                {{ worker.equipment.length }}
                <div
                  class="absolute top-full hidden group-hover:flex duration-100 bg-white rounded text-black p-3 right-0 flex-col w-64 gap-y-1"
                >
                  <span
                    v-for="equipment in worker.equipment"
                    :key="equipment.id"
                  >
                    {{ equipment.name }}
                  </span>
                </div>
              </div>
              <button
                :class="
                  activeWorkers && activeWorkers.includes(worker.id)
                    ? 'btn-disabled'
                    : conflictingWorkers.includes(worker.id)
                    ? 'btn-attention'
                    : 'btn-primary'
                "
                @click="workerToggeled(worker)"
                :disabled="activeWorkers.includes(worker.id)"
              >
                Vali
              </button>
            </div>
          </div>
          <div
            class="flex flex-col gap-y-1 w-1/2 overflow-y-auto"
            v-if="view === 2"
          >
            <div v-if="!generatorResults && !generatingResults">
              Genereeri tulemused
              <button
                :class="startTime && endTime ? 'btn-primary' : 'btn-disabled'"
                @click="startTime && endTime ? generateWorkers() : ''"
              >
                Genereeri sobivad meeskonnad
              </button>
            </div>
            <div v-if="generatingResults">
              <ClipLoader size="48px" />
            </div>
            <div v-if="generatorResults" class="flex flex-col gap-y-1">
              <div class="flex gap-x-2">
                <h4>Tulemused</h4>
                <button
                  :class="startTime && endTime ? 'btn-primary' : 'btn-disabled'"
                  @click="startTime && endTime ? generateWorkers() : ''"
                >
                  Genereeri
                </button>
              </div>

              <div
                v-for="(result, index) in generatorResults"
                :key="index"
                class="flex w-full bg-offwhite rounded shadow p-1"
              >
                <div class="flex flex-wrap flex-1 gap-x-2">
                  <span
                    v-for="worker in result[0].worker_set"
                    :key="`res${index}w${worker.id}`"
                  >
                    {{ worker.worker_name }}
                  </span>
                </div>
                <div class="flex items-center">
                  <img
                    v-if="result[0].prev_together === 1"
                    src="/bc21/double-chevron-left.svg"
                    alt="<<"
                    class="h-3 w-3 mr-1"
                  />
                  <img
                    v-else-if="result[0].prev_together >= 0.65"
                    src="/bc21/chevron-left.svg"
                    alt="<"
                    class="h-3 w-3 mr-1"
                  />
                  <img
                    v-if="result[0].is_brigade"
                    src="/bc21/töötajad.svg"
                    alt="B"
                    class="h-3 w-3 mr-1"
                  />
                  <img
                    v-if="result[0].next_together === 1"
                    src="/bc21/double-chevron.svg"
                    alt=">>"
                    class="h-3 w-3 mr-1"
                  />
                  <img
                    v-else-if="result[0].next_together >= 0.65"
                    src="/bc21/chevron.svg"
                    alt=">"
                    class="h-3 w-3 mr-1"
                  />
                  <button
                    class="btn-primary"
                    @click="selectGeneratorResult(result)"
                  >
                    Vali
                  </button>
                </div>
              </div>
              <span v-if="Object.keys(generatorResults).length === 0">
                Tulemusi pole
              </span>
            </div>
          </div>
          <worker-brigades
            class="flex flex-col gap-y-1 w-1/2"
            v-if="view === 3"
            :free-amount="
              workerAmount - internalSelectedWorkers.filter((x) => !!x).length
            "
            @selectBrigade="selectBrigade"
          />
          <task-equipment-selector
            class="flex flex-col gap-y-1 w-1/2"
            v-if="view === 4"
            :selected-equipment="internalSelectedEquipment"
            @selectEquipment="addEquipment"
          />
        </div>
      </div>
      <SkeletonContent v-else />
    </div>
  </div>
</template>

<script>
import { RequestHandler } from "@/assets/mixins/GewodoMixins";
import { debounce } from "lodash";
import { mapGetters } from "vuex";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import SelectSearch from "../reusable/SelectSearch.vue";
import SkeletonContent from "../reusable/SkeletonContent.vue";
import PageChanger from "@/components/reusable/PageChanger";
import { generateParamString } from "@/assets/utils/commonTransforms";
import SearchInput from "../reusable/SearchInput.vue";
import WorkerBrigades from "@/components/company/workers/WorkerBrigades.vue";
import TaskEquipmentSelector from "@/components/company/equipment/TaskEquipmentSelector.vue";
export default {
  props: {
    taskId: {
      type: Number,
      default: null,
    },
    startTime: {
      type: String,
      default: "",
    },
    endTime: {
      type: String,
      default: "",
    },
    selectedWorkers: {
      type: Array,
      default: () => [],
    },
    selectedEquipment: {
      type: Array,
      default: () => [],
    },
    inputData: {
      type: Object,
      default: () => {},
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    startLocation: {
      type: Object,
      default: null,
    },
    endLocation: {
      type: Object,
      default: null,
    },
    workerAmountInput: {
      type: Number,
      default: 0,
    },
    attributesInput: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      selectedAttributes: [],
      internalSelectedWorkers: [],
      internalSelectedEquipment: [],
      addingWorkers: false,
      filterText: "",
      filterText2: "",
      workerFilter2TextWithTimer: "",
      conflictingWorkers: [],
      page: 1,
      workerAmount: 1,
      generatorResults: null,
      generatingResults: false,
      selectedBrigadeIndex: null,
      attributes: [],
      workers: null,
      workerSlotSelected: null,
      initing: false,
      view: 5,
      firstWorkerAmount: 1,
      managers: false,
      managers2: false,
      vacationWorkers: [],
    };
  },
  components: {
    TaskEquipmentSelector,
    WorkerBrigades,
    ClipLoader,
    SelectSearch,
    SkeletonContent,
    PageChanger,
    SearchInput,
  },
  mixins: [RequestHandler],
  async mounted() {
    let initQueue = [];
    this.initing = true;
    this.firstWorkerAmount = this.workerAmountInput;
    if (!this.companyWorkers)
      await this.$store.dispatch("companyVariables/retrieveWorkers");
    initQueue.push(this.retrieveWorkers(true));
    initQueue.push(
      this.$store.dispatch("companyVariables/retrieveWorkerAttributes")
    );

    Promise.all(initQueue).then(() => {
      if (this.workerAmountInput) this.workerAmount = this.workerAmountInput;
      this.setWorkerArr(true);
      if (this.selectedWorkers?.length > 0)
        this.selectedWorkers.forEach((worker) => {
          this.selectWorker(
            this.companyWorkers.find((x) => x.id === worker.id)
          );
        });

      if (this.attributesInput) {
        let attrInputArr = this.attributesInput.split(",");

        attrInputArr.forEach((attr) => {
          if (
            !this.selectedAttributes.map((x) => x.id).includes(parseInt(attr))
          ) {
            this.selectedAttributes.push({
              ...this.workerAttributes.find((y) => y.id === parseInt(attr)),
              count: 1,
            });
          } else {
            this.selectedAttributes.find(
              (y) => y.id === parseInt(attr)
            ).count += 1;
          }
        });
      }
      if (this.selectedEquipment)
        this.selectedEquipment.forEach((x) => this.addEquipment(x.equipment));
      this.initing = false;
    });
  },
  methods: {
    debounceSearch: debounce(function () {
      this.retrieveWorkers();
    }, 500),
    debounceFilterText2: debounce(function () {
      this.workerFilter2TextWithTimer = this.filterText2;
    }, 500),
    cancelEdit() {
      this.internalSelectedWorkers = [];
      this.internalSelectedEquipment = [];
      this.workerAmount = this.firstWorkerAmount;
      this.$emit("workerAmountChanged", this.firstWorkerAmount);
      this.$emit("cancelEdit");
    },
    pageChange(e) {
      this.page = e;
      this.retrieveWorkers();
    },
    handlePageChange(event) {
      this.page = event;
      this.retrieveWorkers();
    },
    handleManagerChange(e) {
      this.managers = e;
      this.retrieveWorkers();
    },
    retrieveWorkers(init = false) {
      this.workers = null;
      let params = {
        task: true,
        page: this.page,
      };
      if (!this.managers) params.managers = true;
      if (this.filterText) params.s = this.filterText;
      return this.apiRequest(
        `company/${this.companyId}/workers/${generateParamString(params)}`,
        "get",
        true
      ).then((res) => {
        this.workers = res.data;
        if (
          init &&
          this.inputData?.workers &&
          this.inputData.workers.length > 0
        ) {
          this.inputData.workers.map((x) => this.toggleWorker(x));
        }
        this.checkIfWorkersFree();
      });
    },
    toggleWorker(e) {
      this.selectedBrigadeIndex = null;
      if (this.selectedWorkers.find((item) => item.id === e.id) !== undefined)
        this.$emit(
          "removeWorker",
          this.selectedWorkers.findIndex((item) => item.id === e.id)
        );
      else
        this.$emit("setWorkers", [
          ...this.selectedWorkers,
          JSON.parse(JSON.stringify(e)),
        ]);
    },
    addEquipment(equipment) {
      if (
        !this.internalSelectedEquipment.map((x) => x.id).includes(equipment.id)
      )
        this.internalSelectedEquipment.push(equipment);
    },
    removeEquipment(equipment) {
      this.internalSelectedEquipment.splice(
        this.internalSelectedEquipment.findIndex((x) => x === equipment),
        1
      );
    },
    generateWorkers() {
      this.generatorResults = null;
      let data = {
        worker_amount: parseInt(this.workerAmount),
        attributes: this.attributesArr,
        start_time: this.startTime,
        end_time: this.endTime,
        start_location: this.parseLocation(this.startLocation),
        end_location: this.parseLocation(this.endLocation),
      };
      this.view = 2;
      this.generatingResults = true;
      this.apiRequest(
        `calendar/${this.companyId}/suitable/`,
        "post",
        true,
        data
      )
        .then((res) => {
          this.generatorResults = res.data;
          this.generatingResults = false;
        })
        .catch((err) => {
          this.generatingResults = false;
          if (err.response.status === 409) {
            this.$store.dispatch("messageHandler/throwMessage", {
              type: "error",
              text: "Pole piisavalt palju töötajaid ettevõttes või puuduvad vabad töötaja.",
              err: err,
              ttl: 15,
            });
          }
        });
    },
    confirmSelection() {
      this.$emit(
        "setWorkers",
        this.internalSelectedWorkers.filter((x) => !!x)
      );
    },
    workerToggeled(worker) {
      if (this.activeWorkers.length === this.workerAmount) {
        this.workerAmount++;
        this.$nextTick(() => {
          this.selectWorker(worker);
        });
      } else {
        this.selectWorker(worker);
      }
    },
    selectWorker(worker) {
      if (worker.equipment) {
        worker.equipment.forEach((x) => this.addEquipment(x));
      }
      if (this.workerSlotSelected === null) {
        let firstEmpty = this.internalSelectedWorkers.findIndex(
          (x) => x === undefined
        );
        if (firstEmpty >= 0)
          this.$set(this.internalSelectedWorkers, firstEmpty, worker);
      } else {
        this.$set(
          this.internalSelectedWorkers,
          this.workerSlotSelected,
          worker
        );
      }

      this.workerSlotSelected = null;
    },
    selectGeneratorResult(result) {
      for (let index of this.internalSelectedWorkers.keys()) {
        const worker = result[0].worker_set[index];
        const cmpWorker = this.companyWorkers.find((x) => x.id === worker.id);

        this.$set(this.internalSelectedWorkers, index, undefined);
        this.$set(this.internalSelectedWorkers, index, worker);
        if (cmpWorker && cmpWorker.equipment) {
          cmpWorker.equipment.forEach((x) => this.addEquipment(x));
        }
      }
    },
    selectBrigade(brigade, append = false) {
      if (!append) {
        this.workerAmount = brigade.workers.length;
        this.setWorkerArr();
        for (let index of this.internalSelectedWorkers.keys()) {
          const worker = brigade.workers[index];
          this.$set(this.internalSelectedWorkers, index, undefined);
          this.$set(this.internalSelectedWorkers, index, worker);
          if (worker.equipment) {
            worker.equipment.forEach((x) => this.addEquipment(x));
          }
        }
      } else {
        for (let worker of brigade.workers) {
          let firstEmpty = this.internalSelectedWorkers.findIndex(
            (x) => x === undefined
          );
          if (firstEmpty >= 0)
            this.$set(this.internalSelectedWorkers, firstEmpty, worker);
        }
      }
    },
    removeWorker(index) {
      const worker = this.internalSelectedWorkers[index];
      if (worker.equipment) {
        for (let eq of worker.equipment) {
          if (this.internalSelectedEquipment.includes(eq)) {
            this.internalSelectedEquipment.splice(
              this.internalSelectedEquipment.indexOf(eq),
              1
            );
          }
        }
      }
      this.$set(this.internalSelectedWorkers, index, undefined);
    },
    setWorkerArr(init = false) {
      if (init) {
        if (this.selectedWorkers && this.selectedWorkers?.length > 0) {
          this.workerAmount = this.selectedWorkers.length;
        }
      }
      if (this.workerAmount > this.internalSelectedWorkers.length)
        for (
          let i = this.internalSelectedWorkers.length;
          i < this.workerAmount;
          i++
        ) {
          this.internalSelectedWorkers.push(undefined);
        }
      else if (
        this.workerAmount < this.internalSelectedWorkers &&
        !!this.internalSelectedWorkers[this.internalSelectedWorkers.length - 1]
      ) {
        //@TODO make delete confirm logic
        this.internalSelectedWorkers = this.internalSelectedWorkers.slice(
          0,
          this.workerAmount
        );
      } else {
        this.internalSelectedWorkers = this.internalSelectedWorkers.slice(
          0,
          this.workerAmount
        );
      }
    },
    getActiveWorkerIds() {
      return this.internalSelectedWorkers.map((x) => {
        return x;
      });
    },
    parseLocation(location) {
      if (!location) return null;
      return {
        latitude: location.latitude,
        longitude: location.longitude,
      };
    },
    checkIfWorkersFree() {
      this.conflictingWorkers = [];
      if (
        [1, 5].includes(this.view) &&
        this.workers?.results &&
        this.workers.results.length > 0
      ) {
        this.apiRequest(`calendar/${this.companyId}/free/`, "put", true, {
          start_time: this.startTime,
          end_time: this.endTime,
          workers:
            this.view === 1
              ? this.workers.results.map((x) => x.id)
              : this.companyWorkers.map((x) => x.id),
          task: this.taskId,
          only_workers: !this.managers,
        }).then((res) => {
          if (res.status === 200) {
            this.conflictingWorkers = res.data.occupations;
            this.vacationWorkers = res.data.vacations;
          }
        });
      }
    },
  },
  computed: {
    ...mapGetters({
      workerAttributes: "companyVariables/workerAttributes",
      companyId: "companyData/activeCompanyUuid",
      companyWorkers: "companyVariables/companyWorkers",
    }),
    computedCompanyWorkers() {
      if (!this.companyWorkers) return [];
      let workers = JSON.parse(JSON.stringify(this.companyWorkers));
      workers = workers.filter((x) => !this.vacationWorkers.includes(x.id));
      if (this.workerFilter2TextWithTimer) {
        workers = this.companyWorkers.filter((x) =>
          x.worker_name
            .toLowerCase()
            .includes(this.workerFilter2TextWithTimer.toLowerCase())
        );
      }
      if (this.managers2) {
        return workers;
      } else {
        return workers.filter((x) => ["R4", "R5"].includes(x.permissions));
      }
    },
    attributesArr() {
      let attrArr = [];
      this.selectedAttributes.forEach((attribute) => {
        for (let i = 0; i < attribute.count; i++) attrArr.push(attribute.id);
      });
      return attrArr;
    },
    attributeString() {
      let attrArr = [];
      this.selectedAttributes.forEach((attribute) => {
        for (let i = 0; i < attribute.count; i++) attrArr.push(attribute.id);
      });
      return attrArr.join(",");
    },
    attributesInSelected() {
      if (!this.internalSelectedWorkers) return [];
      let attrArr = [];
      for (let worker of this.internalSelectedWorkers.filter(
        (x) => x !== undefined
      )) {
        for (let attribute of worker.attributes) {
          attrArr.push(attribute.id);
        }
      }
      return attrArr;
    },
    activeWorkers() {
      return this.internalSelectedWorkers
        .filter((x) => x !== undefined)
        .map((x) => x.id);
    },
    hasOneWorker() {
      return this.internalSelectedWorkers.some((x) => !!x);
    },
  },
  watch: {
    internalSelectedWorkers: {
      handler() {
        this.$store.dispatch(
          "modals/taskModal/updateSelectedWorkers",
          this.internalSelectedWorkers
        );
      },
      deep: true,
    },
    internalSelectedEquipment: {
      handler() {
        if (this.taskId) {
          this.$store.dispatch(
            "modals/taskModal/updateSelectedEquipment",
            this.internalSelectedEquipment
          );
        } else {
          this.$store.dispatch(
            "modals/addTaskModal/updateSelectedEquipment",
            this.internalSelectedEquipment
          );
        }
      },
      deep: true,
    },
    filterText() {
      this.page = 1;
      this.debounceSearch();
    },
    filterText2() {
      this.debounceFilterText2();
    },
    startTime() {
      if (this.startTime) this.checkIfWorkersFree();
    },
    endTime() {
      if (this.endTime) this.checkIfWorkersFree();
    },
    workerAmount() {
      if (!!this.workerAmount || this.workerAmount === 0) this.setWorkerArr();
      this.checkIfWorkersFree();
      this.$emit("workerAmountChanged", this.workerAmount);
    },
    attributeString() {
      this.$emit("attributeStringChanged", this.attributeString);
    },
  },
};
</script>
