var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal"},[_c('div',{directives:[{name:"on-clickaway",rawName:"v-on-clickaway:mousedown",value:(_vm.closeModal),expression:"closeModal",arg:"mousedown"}],staticClass:"modal-body modal-full"},[_vm._m(0),_c('div',{ref:"modalContent",staticClass:"modal-content flex-col gap-y-4"},[_c('add-task-form',{ref:"addTaskForm",on:{"taskSaved":_vm.handleSaveTask}}),_c('worker-selector',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedWorkers.length === 0 || _vm.showWorkerSelector),expression:"selectedWorkers.length === 0 || showWorkerSelector"}],ref:"workerSelector",staticClass:"text-sm h-full",attrs:{"start-time":_vm.startTime,"end-time":_vm.endTime,"start-location":_vm.fields.location,"end-location":_vm.fields.endLocation,"selected-workers":_vm.selectedWorkers.map(function (x) { return x.worker; }),"full-width":true},on:{"setWorkers":_vm.handleSetWorkers,"removeWorker":_vm.handleRemoveWorker,"workerAmountChanged":_vm.handleWorkerCountChanged,"attributeStringChanged":_vm.handleAttributeStringChanged}}),_c('assignment-manager',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedWorkers.length > 0 && !_vm.showWorkerSelector),expression:"selectedWorkers.length > 0 && !showWorkerSelector"}],ref:"assignmentManager",on:{"removeWorker":_vm.handleRemoveWorker,"clearWorkers":function () { return _vm.handleSetWorkers([]); },"showWorkerSelector":function($event){_vm.showWorkerSelector = true}}})],1),_c('div',{staticClass:"modal-bottom-bar"},[_c('div',{staticClass:"flex gap-x-3"},[_c('button',{staticClass:"confirm-button relative",class:(_vm.fields.title || _vm.fields.description) && !_vm.saving && _vm.startTime
              ? 'btn-primary'
              : 'btn-disabled',attrs:{"disabled":(!_vm.fields.title && !_vm.fields.description) || _vm.saving || !_vm.startTime},on:{"click":_vm.saveTask}},[(!_vm.saving)?_c('span',[_vm._v("Salvesta")]):_c('ClipLoader',{attrs:{"size":"16px"}}),((!_vm.fields.title && !_vm.fields.description) || !_vm.startTime)?_c('ul',{staticClass:"confirm-checklist"},[(!_vm.fields.title && !_vm.fields.description)?_c('li',[_vm._v(" Kas pealkiri või kirjeldus peab olema täidetud ")]):_vm._e(),(!_vm.startTime)?_c('li',[_vm._v("Tööl peab olema kuupäev märgitud")]):_vm._e()]):_vm._e()],1),(_vm.workerOverride)?_c('button',{class:_vm.fields.title && !_vm.saving ? 'btn-primary' : 'btn-disabled',attrs:{"disabled":!_vm.fields.title || _vm.saving},on:{"click":function($event){return _vm.saveTask()}}},[(!_vm.saving)?_c('span',[_vm._v("Salvesta (Töötajatel on aeg mis kordub)")]):_c('ClipLoader',{attrs:{"size":"16px"}})],1):_vm._e(),_c('button',{staticClass:"btn-danger",on:{"click":function($event){return _vm.closeModal(false)}}},[_vm._v("Sulge")])])]),(_vm.showProjectSelector)?_c('project-selector',{staticClass:"z-999",attrs:{"convert":false},on:{"closeModal":function($event){return _vm.$store.commit('modals/addTaskModal/setShowProjectSelector', false)},"projectAdded":_vm.setProject}}):_vm._e(),(_vm.showNewProjectModal)?_c('new-project-modal',{staticClass:"z-999",attrs:{"task-data":_vm.fields},on:{"closeModal":function($event){return _vm.$store.commit('modals/addTaskModal/setShowNewProjectModal', false)},"projectAdded":_vm.setProject}}):_vm._e(),(
        _vm.$store.state.modals.addClientModal.show &&
        _vm.$store.state.modals.addClientModal.taskModalOpen
      )?_c('add-client-modal',{on:{"close":function($event){return _vm.$store.commit('modals/addClientModal/close')}}}):_vm._e(),(_vm.closeConfirmation)?_c('confirm-close-modal',{on:{"confirm":function($event){return _vm.$store.dispatch('modals/addTaskModal/close')},"closeModal":function($event){_vm.closeConfirmation = false}}}):_vm._e(),(_vm.showPayerSelector)?_c('client-selector-modal',{attrs:{"select-client":false},on:{"closeModal":function($event){return _vm.$store.dispatch('modals/addTaskModal/showPayerSelector', false)},"clientSelected":function (e) {
          _vm.fields.payer = e;
          _vm.$store.dispatch('modals/addTaskModal/showPayerSelector', false);
        }}}):_vm._e()],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal-header"},[_c('div',{staticClass:"modal-title"},[_c('h3',{staticClass:"self-center"},[_vm._v("Lisa töö")])])])}]

export { render, staticRenderFns }