<template>
  <div class="flex w-full">
    <div
      class="data-container w-full"
      v-if="!showEditMenu && taskWorkers && taskWorkers.length > 0"
    >
      <div class="data-container-header">
        <h4>Töötajad</h4>
        <span
          class="rounded text-white px-2 font-medium cursor-default text-sm"
          :class="
            taskData.sub_tasks.length < taskData.worker_amount
              ? 'bg-danger'
              : 'bg-primary'
          "
        >
          {{ taskData.sub_tasks.length }}/{{ taskData.worker_amount }}
        </span>
        <button
          :class="showEditMenu ? 'btn-danger' : 'btn-primary'"
          @click="showEditMenu = !showEditMenu"
          class="text-sm"
        >
          Muuda
        </button>
      </div>
      <list-view />
      <span v-if="taskWorkers.length === 0" class="p-2"> Töötajaid pole </span>
    </div>

    <worker-selector
      class="w-full"
      v-if="showEditMenu || (taskWorkers && taskWorkers.length === 0)"
      :task-id="taskData.id"
      :start-time="taskData.start_time"
      :end-time="taskData.end_time"
      :selected-workers="taskWorkers"
      :selected-equipment="taskData.assigned_equipment_list"
      @cancelEdit="showEditMenu = false"
      @setWorkers="handleSetWorkers"
      @removeWorker="handleRemoveWorker"
      @workerAmountChanged="(amt) => (taskData.worker_amount = amt)"
      @attributeStringChanged="(str) => (taskData.attributes = str)"
      :worker-amount-input="taskData.worker_amount"
      :attributes-input="taskData.attributes"
      ref="workerSelector"
    />
  </div>
</template>
<script>
import WorkerSelector from "@/components/tasks/WorkerSelector";
import moment from "moment";
import { mapGetters, mapState } from "vuex";
import { formatTimeUnit } from "@/assets/utils/commonTransforms";
import ListView from "./workers/ListView";
import EventBus from "@/assets/mixins/EventBus";
export default {
  name: "TaskWorkers",
  components: {
    WorkerSelector,
    ListView,
  },
  data() {
    return {
      showEditMenu: false,
      moment: moment,
    };
  },
  mounted() {
    EventBus.$on("closeEditMenu", () => (this.showEditMenu = false));
  },
  destroyed() {
    EventBus.$off("closeEditMenu");
  },
  methods: {
    formatTimeUnit,
    handleSetWorkers(e) {
      let removePromises = [];
      let assignmentsToRemove = this.taskData.sub_tasks.filter(
        (x) => !e.map((y) => y.id).includes(x.worker.id)
      );
      assignmentsToRemove.forEach((x) =>
        removePromises.push(
          this.$store.dispatch("modals/taskModal/removeAssignment", x)
        )
      );
      Promise.all(removePromises).then(() => {
        this.$store.commit("modals/taskModal/setAssignments", e);
        if (this.showEditMenu) this.showEditMenu = false;
      });
    },
    removeAssignment(e) {
      this.$store.dispatch("modals/taskModal/removeAssignment", e);
    },
    handleRemoveWorker(e) {
      this.$store.commit("modals/taskModal/removeWorker", e);
    },
    workerHasActiveTimer(workerUUID) {
      return this.timerData
        .filter((x) => x.worker === workerUUID)
        .some((x) => !x.stopper_end);
    },
    getWorkerTimeTotal(workerUUID) {
      return moment
        .utc(
          this.timerData
            .filter((x) => x.worker === workerUUID)
            .map((x) =>
              moment
                .duration(
                  moment(x.stopper_end ? moment(x.stopper_end) : moment()).diff(
                    moment(x.stopper_start)
                  )
                )
                .as("milliseconds")
            )
            .reduce((len, sum) => len + sum, 0)
        )
        .format("HH:mm:ss");
    },
    markAsDone() {
      this.apiRequest(
        `tasks/${this.taskData.id}/mark-completed/`,
        "post",
        true
      ).then(() => {
        this.$store.dispatch("modals/taskModal/retrieveTask", this.taskData.id);
      });
    },
  },
  computed: {
    ...mapState({
      taskData: (state) => state.modals.taskModal.taskData,
    }),
    ...mapGetters({
      timerData: "modals/taskModal/timerData",
      taskWorkers: "modals/taskModal/workers",
      workerPermission: "companyData/userPermission",
    }),
  },
};
</script>

<style lang="scss" scoped>
.address-field {
  width: calc(100% - 1.75rem);
}
</style>
