<template>
  <div class="data-container-content">
    <div class="flex flex-row items-center mb-2">
      <span class="font-medium mr-3">Kliendi makse</span>
      <div
        class="flex flex-grow items-center cursor-pointer duration-100 px-2 py-1 justify-center border-offwhite-dark"
        :class="
          paymentInfo.payment_type === 1
            ? 'bg-primary text-white'
            : 'text-grey-dark'
        "
        @click="paymentInfo.payment_type = 1"
      >
        Sularaha
      </div>
      <div
        class="flex flex-grow items-center cursor-pointer duration-100 px-2 py-1 justify-center border-l border-offwhite-dark"
        :class="
          paymentInfo.payment_type === 2
            ? 'bg-primary text-white'
            : 'text-grey-dark'
        "
        @click="paymentInfo.payment_type = 2"
      >
        Kaardimakse
      </div>
    </div>
    <div
      v-if="paymentInfo.payment_type"
      class="flex flex-row items-center mb-2"
    >
      <decimal-input
        v-model.number="paymentInfo.payment_amount"
        :decimal-points="2"
      />
      <div class="flex mx-3">
        <button
          :class="
            paymentInfo.payment_type && paymentInfo.payment_amount
              ? 'btn-primary'
              : 'btn-disabled'
          "
          @click="
            paymentInfo.payment_type && paymentInfo.payment_amount
              ? setPaymentInformation()
              : ''
          "
        >
          Kinnita
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import DecimalInput from "@/components/reusable/DecimalInput.vue";

export default {
  name: "TaskTimer",
  components: { DecimalInput },
  data() {
    return {
      paymentInfo: {
        payment_type: null,
        payment_amount: 0,
      },
    };
  },
  methods: {
    setPaymentInformation() {
      this.$emit("setPaymentInformation", this.paymentInfo);
    },
  },
};
</script>
