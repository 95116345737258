import moment from "moment";
import StoreRequestHandler from "../../../assets/mixins/StoreRequestHandler";
import { EventBus } from "../../../assets/mixins/GewodoMixins";
import { normalizeLocationObject } from "@/assets/utils/commonTransforms";
import { generateModalKey } from "@/assets/utils/commonMath";
const getInitialState = () => {
  return {
    fields: {
      description: "",
      manager_description: "",
      title: "",
      is_public: false,
      visible_to_client: false,
      publish: true,
      location: null,
      endLocation: null,
      worker_amount: 1,
      attributes: "",
      extra_locations: [],
      equipment_data: [],
      tags: [],
      payer: null,
    },
    timeObj: {
      startTime: null,
      endTime: null,
      isAllDay: false,
      isRepeating: false,
      startDate: null,
      endDate: null,
      repeatAmount: 1,
      repeatEnd: 1,
      repeatType: "1",
      repeatCount: 1,
      repeatEndDate: null,
      repeatPeriod: "DAILY",
      skipWeekends: false,
    },
    saving: false,
    project: null,
    show: false,
    inputData: null,
    assignments: [],
    step: 1,
    showProjectSelector: false,
    showNewProjectModal: false,
    showPayerSelector: false,
    extraLocations: [],
    equipmentList: [],
    workerOverride: false,
  };
};

const processTaskData = (
  fields,
  timeObj,
  assignments,
  project = null,
  extraLocations = [],
  equipmentList = [],
  workerOverride = false
) => {
  let endLocation = null;
  if (fields.endLocation) {
    endLocation = fields.endLocation;
  } else if (extraLocations.length > 0) {
    endLocation = extraLocations.pop().location;
  }
  let data = {
    title: fields.title,
    description: fields.description,
    manager_description: fields.manager_description,
    all_day: timeObj.isAllDay,
    start_time: moment(timeObj.startDate)
      .hour(timeObj.startTime.hour)
      .minute(timeObj.startTime.minute)
      .format("YYYY-MM-DD[T]HH:mm:ssZ"),
    end_time: moment(timeObj.endDate)
      .hour(timeObj.endTime.hour)
      .minute(timeObj.endTime.minute)
      .format("YYYY-MM-DD[T]HH:mm:ssZ"),
    sub_tasks: assignments.map((x) => {
      let obj = { ...x };
      obj.worker = obj.worker.id;
      if (x.location) obj.location = normalizeLocationObject(x.location);
      if (x.end_location)
        obj.end_location = normalizeLocationObject(x.end_location);
      if (x.start_time)
        obj.start_time = moment(timeObj.startDate)
          .hour(x.start_time.hour)
          .minute(x.start_time.minute)
          .format("YYYY-MM-DD[T]HH:mm:ssZ");
      if (x.end_time)
        obj.end_time = moment(timeObj.startDate)
          .hour(x.end_time.hour)
          .minute(x.end_time.minute)
          .format("YYYY-MM-DD[T]HH:mm:ssZ");
      return obj;
    }),
    publish: fields.publish,
    is_public: fields.is_public,
    location: fields.location,
    end_location: endLocation,
    payer: fields.payer ? fields.payer.id : null,
    worker_amount: fields.worker_amount,
    attributes: fields.attributes,
    extra_locations: extraLocations.map((obj) => {
      return obj.location;
    }),
    equipment_data: equipmentList.map((x) => x.id),
    tags: fields.tags && fields.tags.length > 0 ? fields.tags.join(";") : null,
  };
  if (timeObj.isRepeating) {
    data.task_type = 3;
    data.interval = timeObj.repeatAmount;
    data.repeat_frequency = timeObj.repeatPeriod;
    data.skip_weekends = timeObj.skipWeekends;
    if (timeObj.repeatType === "2")
      data.until_date = timeObj.repeatEndDate.format("YYYY-MM-DD[T]HH:mm:ssZ");
    else data.count = timeObj.repeatCount;
  } else data.task_type = 1;

  if (project) {
    data.project = project.uuid;
    data.visible_to_client = false;
  }

  if (workerOverride) data.override_workers = true;

  return data;
};

export const addTaskModal = {
  namespaced: true,
  state: getInitialState(),
  mutations: {
    setSaving(state, data) {
      state.saving = data;
    },
    setTimeObj(state, data) {
      state.timeObj = data;
    },
    openModal(state, inputData) {
      let value = inputData["workers"];
      delete inputData["workers"];
      state.inputData = inputData ?? {};
      if (value) {
        let workerArr = [];
        for (let worker of value) {
          let assignmentObj = {};
          assignmentObj.worker = worker;
          assignmentObj.start_time = null;
          assignmentObj.end_time = null;
          assignmentObj.duration = null;
          assignmentObj.location = null;
          assignmentObj.end_location = null;
          assignmentObj.description = null;
          assignmentObj.attributes = [];
          workerArr.push(assignmentObj);
        }
        state.assignments = workerArr;
        state.fields.worker_amount = workerArr.length;
      }
      state.show = true;
    },
    closeModal(state) {
      Object.assign(state, getInitialState());
    },
    setWorkers(state, data) {
      let workerArr = [];
      for (let worker of data) {
        let assignmentObj = {};
        assignmentObj.worker = worker;
        assignmentObj.start_time = null;
        assignmentObj.end_time = null;
        assignmentObj.location = null;
        assignmentObj.end_location = null;
        assignmentObj.description = null;
        assignmentObj.attributes = [];

        workerArr.push(assignmentObj);
      }
      state.assignments = workerArr;
    },
    removeWorker(state, index) {
      state.assignments.splice(index, 1);
    },
    setStep(state, step) {
      state.step = step;
    },
    setProject(state, data) {
      state.project = data;
    },
    setShowProjectSelector(state, data) {
      state.showProjectSelector = data;
    },
    setShowNewProjectModal(state, data) {
      state.showNewProjectModal = data;
    },
    setWorkerCount(state, data) {
      state.fields.worker_amount = data;
    },
    setAttributeString(state, data) {
      state.fields.attributes = data;
    },
    setExtraLocations(state, data) {
      for (let extraLocation of data) {
        state.extraLocations.push({
          key: generateModalKey(),
          location: extraLocation.location,
        });
      }
    },
    addExtraLocation(state) {
      state.extraLocations.push({ key: generateModalKey() });
    },
    updateExtraLocation(state, data) {
      state.extraLocations.find((x) => x.key === data.key).location =
        data.newLocation;
    },
    deleteExtraLocation(state, index) {
      state.extraLocations.splice(index, 1);
    },
    updateSelectedEquipment(state, data) {
      state.equipmentList = data;
    },
    showPayerSelector(state, data) {
      state.showPayerSelector = data;
    },
  },
  actions: {
    setExtraLocations({ commit }, data) {
      commit("setExtraLocations", data);
    },
    setTimeObj({ commit }, data) {
      commit("setTimeObj", data);
    },
    open({ commit }, inputData) {
      commit("openModal", inputData);
    },
    close({ commit }) {
      commit("closeModal");
    },
    addExtraLocation({ commit }) {
      commit("addExtraLocation");
    },
    updateExtraLocation({ commit }, data) {
      commit("updateExtraLocation", data);
    },
    deleteExtraLocation({ commit }, index) {
      commit("deleteExtraLocation", index);
    },
    setProject({ commit }, data) {
      commit("setProject", data);
    },
    updateSelectedEquipment({ commit }, data) {
      commit("updateSelectedEquipment", data);
    },
    showPayerSelector({ commit }, data) {
      commit("showPayerSelector", data);
    },
    saveTask({ rootState, state, dispatch, commit }) {
      commit("setSaving", true);
      let data = processTaskData(
        state.fields,
        state.timeObj,
        state.assignments,
        state.project ?? null,
        state.extraLocations,
        state.equipmentList,
        state.workerOverride
      );
      return StoreRequestHandler.apiRequest(
        `tasks/${rootState.companyData.activeCompany.uuid}/new/`,
        "post",
        true,
        data
      )
        .then((res) => {
          if (res) {
            EventBus.$emit("updateTasks");
            if (data.tags)
              dispatch("companyVariables/retrieveTaskTags", { root: true });
            commit("setSaving", false);
            dispatch("close");
            dispatch(
              "modals/taskModal/open",
              {
                task: res.data.task_id,
              },
              { root: true }
            );
          } else {
            commit("setSaving", false);
          }
        })
        .catch((err) => {
          commit("setSaving", false);
          if (err.response.status === 400) {
            if (err.response.data.code === 10) {
              dispatch(
                "messageHandler/throwMessage",
                {
                  type: "error",
                  text: "Salvestamine ebaõnnestus, vahepeal on töötajate vabad ajad muutunud!",
                  ttl: 15,
                },
                { root: true }
              );
              state.workerOverride = true;
            } else {
              dispatch(
                "messageHandler/throwMessage",
                {
                  type: "error",
                  text: "Salvestamine ebaõnnestus",
                  ttl: 10,
                },
                { root: true }
              );
            }
          } else {
            dispatch(
              "messageHandler/throwMessage",
              {
                type: "error",
                text: "Salvestamine ebaõnnestus",
                ttl: 10,
              },
              { root: true }
            );
          }
        });
    },
  },
  getters: {
    project: (state) => state.project,
    step: (state) => state.step,
    timeObjGetter: (state) => state.timeObj,
    inputData: (state) => state.inputData,
    startTimeDb: (state) => {
      if (
        !state.timeObj?.startDate ||
        !state.timeObj?.startTime ||
        [undefined, null].includes(state.timeObj.startTime.hour) ||
        [undefined, null].includes(state.timeObj.startTime.minute)
      )
        return null;
      return moment(state.timeObj.startDate)
        .hour(state.timeObj.startTime.hour)
        .minute(state.timeObj.startTime.minute)
        .format("YYYY-MM-DD[T]HH:mm:ssZ");
    },
    endTimeDb: (state) => {
      if (
        !state.timeObj?.endDate ||
        !state.timeObj?.endTime ||
        [undefined, null].includes(state.timeObj.endTime.hour) ||
        [undefined, null].includes(state.timeObj.endTime.minute)
      )
        return null;
      return moment(state.timeObj.endDate)
        .hour(state.timeObj.endTime.hour)
        .minute(state.timeObj.endTime.minute)
        .format("YYYY-MM-DD[T]HH:mm:ssZ");
    },
    selectedWorkers: (state) => state.assignments,
    showProjectSelector: (state) => state.showProjectSelector,
    showNewProjectModal: (state) => state.showNewProjectModal,
    showPayerSelector: (state) => state.showPayerSelector,
    fields: (state) => state.fields,
    saving: (state) => state.saving,
    equipmentList: (state) => state.equipmentList,
    workerCount: (state) => state.workerCount,
    extraLocationsGetter: (state) => state.extraLocations,
    workerOverride: (state) => state.workerOverride,
  },
};
