<template>
  <div class="modal">
    <div class="modal-body modal-medium" v-on-clickaway:mousedown="closeModal">
      <div class="modal-content flex-col">
        <div class="flex justify-between items-center mobile:flex-col mb-4">
          <gwd-formlabel title="Vali tehnika" :heading-level="3" />
        </div>
        <equipment-selector
          v-if="!selectedEquipment"
          :task="task"
          @equipmentSelected="equipmentSelected"
        />
        <div v-else>
          <div class="flex flex-row mb-4 items-center w-full">
            <div class="flex flex-col mr-2 w-1/3">
              <gwd-formlabel title="Tehnika nimetus" class="mb-2" />
              <span> {{ selectedEquipment.name }} </span>
            </div>
            <div class="flex flex-col mx-2 w-1/3">
              <gwd-formlabel title="Määratud töötaja" class="mb-2" />
              <span>
                {{
                  selectedEquipment.worker
                    ? selectedEquipment.worker.worker_name
                    : "-"
                }}</span
              >
            </div>
            <div class="flex flex-row ml-2 w-1/3 justify-end">
              <span class="items-center flex leading-none rounded-xl">
                <button class="btn-danger" @click="clearSelected">
                  <span class="label">Eemalda valikust</span>
                </button>
              </span>
            </div>
          </div>
          <div class="flex gap-x-2 mobile:gap-y-4 flex-col gap-y-1 flex-1 mb-4">
            <div class="grid gap-x-3 items-center double-field">
              <gwd-formlabel title="Hinna tüüp" />
              <gwd-formlabel title="Kogus" />
            </div>
            <div class="grid gap-x-3 items-center double-field">
              <select v-model="equipmentAssignment.type">
                <option value="1">Tükihind</option>
                <option value="2">Tunnihind</option>
                <option value="3">Kilomeetrihind</option>
              </select>
              <decimal-input v-model.number="equipmentAssignment.amount" />
            </div>
          </div>
          <div class="flex gap-x-2 mobile:gap-y-4 flex-col gap-y-1 flex-1 mb-4">
            <div class="grid gap-x-3 items-center triple-field">
              <gwd-formlabel title="Kulu" />
              <gwd-formlabel title="Koefitsent" />
              <gwd-formlabel title="Kulu kokku" />
            </div>
            <div class="grid gap-x-3 items-center triple-field">
              <decimal-input v-model.number="equipmentAssignment.cost" />

              <decimal-input
                v-model.number="equipmentAssignment.cost_coefficient"
              />

              <span> {{ costWithCoef(equipmentAssignment) }} € </span>
            </div>
          </div>
          <div class="flex gap-x-2 mobile:gap-y-4 flex-col gap-y-1 flex-1 mb-4">
            <div class="grid gap-x-3 items-center triple-field">
              <gwd-formlabel title="Müügihind" />
              <gwd-formlabel title="Käibemaks" />
              <gwd-formlabel title="Koguhind" />
            </div>
            <div class="grid gap-x-3 items-center triple-field">
              <decimal-input v-model.number="equipmentAssignment.price" />
              <select
                class="max-w-full w-full"
                v-model="equipmentAssignment.vatType"
                @change="updateKey++"
              >
                <option
                  v-for="taxType in taxTypes.filter((x) => x.outgoing_tax_code)"
                  :key="taxType.id"
                  :value="taxType"
                >
                  {{ `${taxType.description}` }}
                </option>
              </select>
              <div :key="updateKey">
                <span>{{ priceWithVat(equipmentAssignment) }} € </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-bottom-bar">
        <button class="btn-primary mr-3" @click="saveEquipmentAssignment">
          Salvesta
        </button>
        <button class="btn-danger" @click="closeModal">Sulge</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EquipmentSelector from "@/components/company/equipment/EquipmentSelector";
import { required } from "vuelidate/lib/validators";
import DecimalInput from "../../reusable/DecimalInput.vue";
import { costWithCoef, priceWithVat } from "@/assets/utils/commonMath";
export default {
  name: "AddEquipmentAssignmentModal",
  components: { EquipmentSelector, DecimalInput },
  props: {
    task: {
      type: Number,
      default: null,
    },
    taxTypes: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      equipmentLoaded: false,
      equipment: [],
      selectedEquipment: null,
      equipmentAssignment: {
        type: 1,
        price: 0,
        cost: 0,
        amount: 1,
        cost_coefficient: 0,
        vat: null,
      },
      updateKey: 1,
    };
  },
  methods: {
    costWithCoef,
    priceWithVat,
    closeModal() {
      this.$emit("closeModal");
    },
    equipmentSelected(e) {
      this.selectedEquipment = e;
      this.equipmentAssignment.type = e.preferred_payment;
      this.equipmentAssignment.cost_coefficient = e.cost_coefficient;
      this.equipmentAssignment.vatType = e.vat
        ? this.taxTypes.find((x) => x.id === e.vat)
        : null;
      switch (e.preferred_payment) {
        case 1:
          this.equipmentAssignment.cost = e.price;
          this.equipmentAssignment.price = e.price * e.cost_coefficient;
          break;
        case 2:
          this.equipmentAssignment.cost = e.price_per_hour;
          this.equipmentAssignment.price =
            e.price_per_hour * e.cost_coefficient;
          break;
        case 3:
          this.equipmentAssignment.cost = e.price_per_km;
          this.equipmentAssignment.price = e.price_per_km * e.cost_coefficient;
          break;
        default:
          this.equipmentAssignment.cost = 0;
          this.equipmentAssignment.price = 0;
      }
    },
    clearSelected() {
      this.selectedEquipment = null;
      this.equipmentAssignment = {
        type: 1,
        price: 0,
        cost: 0,
        amount: 0,
      };
    },
    saveEquipmentAssignment() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let data = this.equipmentAssignment;
        data.task = this.task;
        data.vat = this.equipmentAssignment.vatType
          ? this.equipmentAssignment.vatType.id
          : null;
        this.apiRequest(
          `template/${this.companyId}/equipment/${this.selectedEquipment.id}/assign/`,
          "post",
          true,
          data
        ).then((res) => {
          if (res.status === 201) {
            this.$store.dispatch("modals/taskModal/retrieveTask", this.task);
            this.$emit("equipmentAssigned");
            this.closeModal();
          }
        });
      }
    },
  },
  mounted() {
    document.body.classList.add("modal-open");
  },
  beforeDestroy() {
    document.body.classList.remove("modal-open");
  },
  validations: {
    equipmentAssignment: {
      type: {
        required,
      },
      price: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters({
      companyId: "companyData/activeCompanyUuid",
    }),
  },
};
</script>
<style lang="scss" scoped>
.double-field {
  grid-template-columns: 0.5fr 0.5fr;
}

.triple-field {
  @apply grid;
  grid-template-columns: 0.35fr 0.35fr 0.3fr;
}
</style>
