<template>
  <div class="flex w-full">
    <div class="data-container w-full">
      <div class="data-container-header">
        <h4>Tehnika</h4>
      </div>
      <div class="data-container-content data-container-table">
        <div class="gwd-table">
          <div class="equipment-table gwd-table-header">
            <div class="flex">
              <h4>Nimi</h4>
            </div>
            <div class="flex">
              <h4>Asukoht</h4>
            </div>
            <div class="flex">
              <h4>Vastutav töötaja</h4>
            </div>
          </div>
          <div
            class="equipment-table items-center w-full p-2"
            v-for="equipmentAssignment in taskData.assigned_equipment_list"
            :key="equipmentAssignment.id"
          >
            <div class="flex font-medium cursor-pointer">
              {{ equipmentAssignment.equipment.name }}
            </div>
            <span>
              {{
                equipmentAssignment.equipment.location
                  ? equipmentAssignment.equipment.location.long_address
                  : ""
              }}
            </span>
            <span>
              {{
                equipmentAssignment.equipment.worker
                  ? equipmentAssignment.equipment.worker.worker_name
                  : ""
              }}
            </span>
            <div class="flex justify-center">
              <button
                class="btn-danger"
                @click="deleteObject = equipmentAssignment"
              >
                <img
                  src="/bc21/trash.svg"
                  class="h-4 w-4 filter-to-white"
                  alt="Delete eqa"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <confirm-modal
      key="equipment-assignment-delete-modal"
      v-if="deleteObject"
      text="Oled kindel, et soovid tehnika töölt kustutada?"
      @confirm="deleteEquipmentAssignment"
      @closeModal="
        () => {
          this.deleteObject = null;
        }
      "
    />
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import ConfirmModal from "@/components/reusable/ConfirmModal.vue";

export default {
  name: "TaskEquipment",
  components: { ConfirmModal },
  data() {
    return {
      showEditMenu: false,
      deleteObject: null,
    };
  },
  methods: {
    deleteEquipmentAssignment() {
      this.apiRequest(
        `template/${this.companyId}/equipment/${this.deleteObject.equipment.id}/assignment/${this.deleteObject.id}/delete/`,
        "delete",
        true
      ).then((res) => {
        if (res.status === 200) {
          this.$store.dispatch(
            "modals/taskModal/retrieveTask",
            this.taskData.id
          );
        }
      });
    },
  },
  computed: {
    ...mapState({
      taskData: (state) => state.modals.taskModal.taskData,
    }),
    ...mapGetters({
      companyId: "companyData/activeCompanyUuid",
    }),
  },
};
</script>

<style lang="scss">
.equipment-table {
  @apply grid gap-x-2;
  grid-template-columns: 0.7fr 1.9fr 2.2fr 0.2fr;
}
</style>
