<template>
  <div class="gwd-table nested h-full">
    <div class="gwd-table-header supplier-cost">
      <span>Hankija</span>
      <span>Kirjeldus</span>
      <span>Kogus</span>
      <span>Kulu</span>
      <span>Hind</span>
      <span>Käibemaks</span>
      <span class="flex" v-if="isAdmin || isManager">Kulu/Hinna summa</span>
      <span />
    </div>
    <div class="gwd-table-row-wrapper">
      <supplier-cost-planning
        v-for="planningCost in supplierCostPlannings"
        :key="planningCost.id"
        :planning-cost="planningCost"
        @deletePlanningCost="(planning) => (planningToDelete = planning)"
        @updatePlanningCost="updatePlanningCost"
        @convertToCost="convertToCost"
      />
      <div
        class="gwd-table-header nopad"
        v-if="supplierCostPlannings.length > 0"
      />
      <compact-cost
        class="supplier-cost"
        v-for="supplierCost in taskSupplierCosts"
        :key="supplierCost.id"
        :cost="supplierCost"
        :tax-types="taxTypes"
        @costsUpdated="loadTaskSupplierCosts"
      >
        {{ supplierCost.supplier.name }}
      </compact-cost>
      <span
        v-if="
          taskSupplierCosts.length === 0 && supplierCostPlannings.length === 0
        "
        class="p-2"
      >
        Hankijakulusid pole.
      </span>
    </div>

    <div class="gwd-table-bottom-bar">
      <button
        class="btn-primary mr-3"
        v-if="isAdmin || isManager"
        @click="
          $store.commit('modals/taskModal/openAddSupplierCostModal', {
            isPlanning: true,
          })
        "
      >
        Planeeri hankija kulu
      </button>
      <button
        v-if="
          isAdmin ||
          isManager ||
          (['R4', 'R5'].includes(userPermissions) &&
            moment(taskTime.end).diff(moment(), 'hours') >= -12 &&
            !taskConfirmed)
        "
        class="btn-primary"
        @click="
          $store.commit('modals/taskModal/openAddSupplierCostModal', {
            isPlanning: false,
          })
        "
      >
        <span class="label">Lisa</span>
      </button>
    </div>
    <delete-confirm-modal
      v-if="planningToDelete"
      @confirmDelete="deletePlannedCost"
      @closeModal="planningToDelete = null"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { EventBus } from "../../../assets/mixins/GewodoMixins";
import CompactCost from "@/components/tasks/costs/CompactCost.vue";
import { round2 } from "@/assets/utils/commonMath";
import set from "lodash/set";
import DeleteConfirmModal from "@/components/reusable/DeleteConfirmModal.vue";
import SupplierCostPlanning from "@/components/tasks/costs/SupplierCostPlanning.vue";
import moment from "moment";

export default {
  name: "TaskSupplierAssignments",
  components: { SupplierCostPlanning, DeleteConfirmModal, CompactCost },
  props: {
    taskId: {
      type: String,
      default: null,
    },
    taskConfirmed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      taskSupplierCostsLoaded: false,
      taskSupplierCosts: [],
      addSupplierCost: false,
      supplierCostPlannings: [],
      addSupplierCostPlanning: false,
      planningToDelete: null,
      moment: moment,
    };
  },
  methods: {
    round2,
    loadTaskSupplierCosts() {
      this.apiRequest(
        `template/${this.companyId}/plannings/supplier/?task=${this.taskId}`,
        "get",
        true
      ).then((res) => {
        this.supplierCostPlannings = res.data;
        for (let supplierCostPlanning of this.supplierCostPlannings) {
          if (supplierCostPlanning.vat) {
            set(
              supplierCostPlanning,
              "vatType",
              this.taxTypes.find((x) => x.id === supplierCostPlanning.vat) ??
                null
            );
          }
        }
      });
      this.apiRequest(
        `costs/${this.companyId}/supplier/costs/?task=${this.taskId}`,
        "get",
        true
      ).then((res) => {
        this.taskSupplierCosts = res.data;
        for (let supplierCost of this.taskSupplierCosts) {
          if (supplierCost.vat) {
            set(
              supplierCost,
              "vatType",
              this.taxTypes.find((x) => x.id === supplierCost.vat) ?? null
            );
          }
        }
        this.taskSupplierCostsLoaded = true;
      });
    },
    convertToCost(planningCost) {
      this.$store.commit("modals/taskModal/openAddSupplierCostModal", {
        isPlanning: false,
        planningCost: planningCost,
      });
    },
    updatePlanningCost(e) {
      if (e.vatType) e.vat = e.vatType.id;
      this.apiRequest(
        `template/${this.companyId}/supplier/task/${this.taskId}/${e.id}/edit/`,
        "patch",
        true,
        e
      ).then((res) => {
        if (res.status === 200) this.loadTaskSupplierCosts();
      });
    },
    deletePlannedCost() {
      if (!this.planningToDelete) return;
      this.apiRequest(
        `template/${this.companyId}/supplier/task/${this.taskId}/${this.planningToDelete.id}/delete/`,
        "delete",
        true
      ).then((res) => {
        if (res.status === 200) this.loadTaskSupplierCosts();
      });
    },
  },
  async mounted() {
    if (!this.taxTypes)
      await this.$store.dispatch("companyVariables/retrieveTaxTypes");
    this.loadTaskSupplierCosts();
    EventBus.$on("supplierCostAdded", () => this.loadTaskSupplierCosts());
  },
  beforeDestroy() {
    EventBus.$off("supplierCostAdded");
  },
  computed: {
    ...mapGetters({
      companyId: "companyData/activeCompanyUuid",
      isAdmin: "companyData/isAdmin",
      isManager: "companyData/isManager",
      taxTypes: "companyVariables/taxTypes",
      userPermissions: "companyData/userPermission",
      taskTime: "modals/taskModal/taskTime",
    }),
  },
};
</script>
<style lang="scss">
.supplier-cost {
  grid-template-columns: 0.4fr 0.6fr 0.1fr 0.1fr 0.1fr 0.2fr 0.2fr 0.1fr;
  @media screen and (max-width: 601px) {
    @apply flex-wrap m-1 border-b border-b-offwhite-dark w-full gap-y-1;
    grid-template-columns: 0.34fr 0.33fr 0.33fr;
  }
}
</style>
