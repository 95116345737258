<template>
  <div class="modal">
    <div
      class="modal-body modal-short add-task-modal relative mobile:modal-full"
      v-on-clickaway:mousedown="closeModal"
    >
      <div class="modal-header p-2">
        <gwd-formlabel title="Lisa tehnika kulu" :heading-level="3" />
      </div>
      <div class="modal-content flex-col gwd-form">
        <equipment-selector
          v-if="!selectedEquipment"
          @equipmentSelected="toggleEquipment"
        />

        <div class="gwd-form-group">
          <gwd-formlabel title="Nimetus" />
          <input type="text" v-model="equipmentCost.name" />
        </div>
        <div class="flex gap-x-2 mobile:gap-y-4 flex-col gap-y-1 flex-1 mb-4">
          <div class="grid gap-x-3 items-center double-field">
            <gwd-formlabel title="Kogus" />
            <gwd-formlabel title="Ühik" />
          </div>
          <div class="grid gap-x-3 items-center double-field">
            <decimal-input v-model.number="equipmentCost.amount" />
            <select class="add-offer-input" v-model="equipmentCost.unit">
              <option v-for="unit in units" :value="unit" :key="unit">
                {{ unit }}
              </option>
            </select>
          </div>
        </div>
        <div class="flex gap-x-2 mobile:gap-y-4 flex-col gap-y-1 flex-1 mb-4">
          <div class="grid gap-x-3 items-center triple-field">
            <gwd-formlabel title="Kulu" />
            <gwd-formlabel title="Koefitsent" />
            <gwd-formlabel title="Kulu kokku" />
          </div>
          <div class="grid gap-x-3 items-center triple-field">
            <decimal-input v-model.number="equipmentCost.cost" />
            <decimal-input v-model.number="equipmentCost.cost_coefficient" />
            <span> {{ costWithCoef(equipmentCost) }} € </span>
          </div>
        </div>
        <div class="flex gap-x-2 mobile:gap-y-4 flex-col gap-y-1 flex-1 mb-4">
          <div class="grid gap-x-3 items-center triple-field">
            <gwd-formlabel title="Müügihind" />
            <gwd-formlabel title="Käibemaks" />
            <gwd-formlabel title="Koguhind" />
          </div>
          <div class="grid gap-x-3 items-center triple-field">
            <decimal-input v-model.number="equipmentCost.price" />
            <select
              class="max-w-full w-full"
              v-model="equipmentCost.vatType"
              @change="updateKey++"
            >
              <option
                v-for="taxType in taxTypes.filter((x) => x.outgoing_tax_code)"
                :key="taxType.id"
                :value="taxType"
              >
                {{ `${taxType.description}` }}
              </option>
            </select>
            <div :key="updateKey">
              <span>{{ priceWithVat(equipmentCost) }} € </span>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-bottom-bar">
        <button @click="saveEquipmentCost" class="btn-primary mr-3">
          <span class="icon typcn typcn-tick" />
          <span class="label">Salvesta</span>
        </button>
        <button class="btn-danger" @click="closeModal(false)">
          <span class="typcn typcn-times icon" />
          <span class="label">Sulge</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EquipmentSelector from "@/components/company/equipment/EquipmentSelector.vue";
import { costWithCoef, priceWithVat, round2 } from "@/assets/utils/commonMath";
import DecimalInput from "../../reusable/DecimalInput.vue";
export default {
  name: "AddProjectEquipmentCostModal",
  components: { EquipmentSelector, DecimalInput },
  props: {
    equipmentAssignment: {
      type: Object,
      default: () => {},
    },
    task: {
      type: Number,
      default: null,
    },
    taxTypes: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      selectedAssignment: null,
      selectedEquipment: null,
      equipmentCost: {
        equipment: null,
        task: null,
        project: null,
        name: "",
        amount: 0,
        unit: "",
        price: 0,
        cost: 0,
        cost_coefficient: 0,
        vat: null,
      },
      updateKey: 1,
    };
  },
  methods: {
    priceWithVat,
    costWithCoef,
    round2,
    priceType(e) {
      let data = {
        1: "Tükihind",
        2: "Tunnihind",
        3: "Kilomeetrihind",
      };
      return data[e];
    },
    closeModal() {
      this.$emit("closeModal");
    },
    handleSaveResource() {
      this.$emit("costAdded");
      this.closeModal();
    },
    toggleEquipment(e) {
      this.selectedEquipment = e;
      let localEquipment;
      if (this.equipmentAssignment) {
        localEquipment = this.equipmentAssignment.equipment;
        this.equipmentCost.amount = e.amount ? parseFloat(e.amount) : 0;
      } else {
        localEquipment = e;
      }
      if (e.vat)
        this.equipmentCost.vatType = this.taxTypes.find((x) => x.id === e.vat);
      this.equipmentCost.cost_coefficient = e.cost_coefficient;
      this.equipmentCost.equipment = localEquipment.id;
      this.equipmentCost.name = localEquipment.name;
      this.equipmentCost.cost = e.cost ? parseFloat(e.cost) : 0;
      this.equipmentCost.price = e.price ? parseFloat(e.price) : 0;
      let type;
      if (e.preferred_payment) {
        type = e.preferred_payment;
      } else {
        type = e.type;
      }
      switch (type) {
        case 1:
          this.equipmentCost.unit = "tk";
          break;
        case 2:
          this.equipmentCost.unit = "h";
          break;
        case 3:
          this.equipmentCost.unit = "km";
          break;
        default:
          this.equipmentCost.unit = "tk";
          break;
      }

      this.equipmentCost.task = e.task ? e.task.id : null;
      if (this.task) {
        this.equipmentCost.task = this.task;
      }
    },
    saveEquipmentCost() {
      if (this.equipmentCost.equipment) {
        if (this.equipmentCost.vatType)
          this.equipmentCost.vat = this.equipmentCost.vatType.id;
        if (this.equipmentCost.cost_coefficient === "")
          this.equipmentCost.cost_coefficient = null;
        this.apiRequest(
          `costs/${this.companyId}/equipment/${this.equipmentCost.equipment}/costs/add/`,
          "post",
          true,
          this.equipmentCost
        ).then((res) => {
          if (res.status === 201) {
            this.handleSaveResource();
          }
        });
      }
    },
  },
  mounted() {
    document.body.classList.add("modal-open");
    this.equipmentCost.project = this.$route.params.projectId;
    this.equipmentCost.task = this.task;
    if (this.equipmentAssignment) {
      this.toggleEquipment(this.equipmentAssignment);
    }
  },
  beforeDestroy() {
    document.body.classList.remove("modal-open");
  },
  computed: {
    ...mapGetters({
      companyId: "companyData/activeCompanyUuid",
      currency: "userData/currency",
      units: "companyVariables/units",
    }),
  },
};
</script>
<style lang="scss" scoped>
.double-field {
  grid-template-columns: 0.5fr 0.5fr;
}

.triple-field {
  @apply grid;
  grid-template-columns: 0.35fr 0.35fr 0.3fr;
}
</style>
