<template>
  <div class="flex flex-col">
    <div
      class="grid p-2 mobile:hidden worker-pay-cost-table"
      :class="showEdits ? 'pb-2' : ''"
    >
      <div class="flex cursor-pointer" @click="navigateToWorker">
        {{ workerCost.worker_name }}
      </div>
      <div class="flex items-center" v-if="startDate">
        <gwd-dateselector
          v-if="editMode"
          :value="startDate ? moment(startDate) : null"
          @dateChanged="handleDateChange"
        />
        <span v-else>{{ parseStartDate(startDate) }}</span>
      </div>
      <div class="flex items-center" :class="editMode ? '' : 'justify-start'">
        <span v-if="!editMode">
          {{ parseTime(workerCost.real_times.start_time) }}</span
        >
        <span
          v-if="
            !editMode &&
            workerCost.editions.filter((x) => x.field_name === 'stopper_start')
              .length > 0
          "
          class="typcn typcn-info text-danger cursor-pointer ml-2"
          @click="showEdits = !showEdits"
        />
        <time-selector
          v-if="editMode"
          class="flex flex-row w-full justify-start"
          :value="startTime"
          :full-minutes="true"
          @input="handleStartTimeChange"
          :is-white="false"
          :is-moment="true"
          :class="startTime > endTime ? 'wrong-time' : ''"
        />
      </div>
      <div class="flex items-center" :class="editMode ? '' : 'justify-start'">
        <span v-if="workerCost.real_times.end_time === null">Töötab</span>
        <span v-if="!editMode && workerCost.real_times.end_time !== null">
          {{ parseTime(workerCost.real_times.end_time) }}</span
        >
        <span
          v-if="
            !editMode &&
            workerCost.editions.filter((x) => x.field_name === 'stopper_end')
              .length > 0
          "
          class="typcn typcn-info text-danger ml-2 cursor-pointer"
          @click="showEdits = !showEdits"
        />
        <time-selector
          v-if="editMode && workerCost.real_times.end_time !== null"
          :full-minutes="true"
          class="flex flex-row w-full justify-start"
          :value="endTime"
          @input="handleEndTimeChange"
          :is-white="false"
          :is-moment="true"
          :class="startTime > endTime ? 'wrong-time' : ''"
        />
      </div>
      <div class="flex items-center">
        <span>{{ round2(workerCost.real_times.amount) }} h</span>
      </div>
      <div v-if="isAdmin || isManager" class="flex items-center">
        <decimal-input v-if="editMode" v-model.number="hourlyCost" />
        <span v-else>{{ workerCost.cost }}€</span>
        <span
          v-if="
            !editMode &&
            workerCost.editions.filter((x) => x.field_name === 'cost').length >
              0
          "
          class="typcn typcn-info text-danger ml-2 cursor-pointer"
          @click="showEdits = !showEdits"
        />
      </div>
      <div class="flex items-center">
        <span>
          {{ round2(workerCost.cost * workerCost.real_times.amount) }} €
        </span>
      </div>
      <div v-if="isAdmin || isManager" class="flex items-center">
        <decimal-input v-if="editMode" v-model="costCoefficient" />
        <span v-else>{{ workerCost.cost_coefficient }}</span>
        <span
          v-if="
            !editMode &&
            workerCost.editions.filter(
              (x) => x.field_name === 'cost_coefficient'
            ).length > 0
          "
          class="typcn typcn-info text-danger ml-2 cursor-pointer"
          @click="showEdits = !showEdits"
        />
      </div>
      <div class="flex items-center">
        <span>{{ workerTimeWithCoef(workerCost) }} €</span>
      </div>
      <div class="flex items-center truncate">
        <span :title="workerCost.info">{{ workerCost.info }}</span>
      </div>
      <div
        class="flex items-center justify-end gap-x-1"
        v-if="['R0', 'R1', 'R2'].includes(userRole)"
      >
        <button
          class="btn-primary"
          v-if="!workerCost.confirmed"
          @click="confirmCost"
        >
          Kinnita
        </button>
        <span v-else class="typcn typcn-tick"> Kinnitatud </span>
        <button
          class="h-6 w-6 bg-primary hover:bg-primary-dark rounded shadow flex items-center justify-center"
          v-if="!editMode && !workerCost.confirmed"
          @click="editMode = !editMode"
        >
          <span class="typcn-edit typcn icon text-white" />
        </button>
        <button
          class="btn-primary"
          v-if="editMode && !workerCost.confirmed"
          @click="saveTime"
        >
          <span class="typcn-tick typcn icon" />
        </button>
        <button
          v-if="!workerCost.confirmed"
          @click="isDeleteModalActive = true"
          class="h-6 w-6 bg-danger hover:bg-danger-dark rounded shadow flex items-center justify-center"
        >
          <img
            src="/bc21/trash.svg"
            class="h-4 w-4 filter-to-white"
            alt="Delete icon"
          />
        </button>
        <button
          @click="showExtraInformation = !showExtraInformation"
          class="h-6 w-6 bg-primary hover:bg-primary-dark rounded shadow flex items-center justify-center"
        >
          <img
            :src="
              showExtraInformation ? '/bc21/chevron-down.svg' : '/bc21/info.svg'
            "
            class="h-4 w-4 filter-to-white"
            alt="?"
          />
        </button>
      </div>
    </div>
    <div
      v-if="showExtraInformation"
      class="flex flex-col border-t border-offwhite-dark p-2 bg-offwhite"
    >
      <div class="grid grid-cols-4 font-medium">
        <span>Lisaja</span>
        <span>Lisamise aeg</span>
        <span>Kinnitaja</span>
        <span>Kinnitamise aeg</span>
      </div>
      <div class="grid grid-cols-4">
        <span>{{
          workerCost.added_by ? workerCost.added_by.worker_name : "-"
        }}</span>
        <span>{{
          moment(workerCost.added_time).format("DD.MM.YYYY HH:mm")
        }}</span>
        <span>{{
          workerCost.confirmed
            ? workerCost.confirmed_by.worker_name
            : "Kinnitamata"
        }}</span>
        <span>{{
          workerCost.confirmed_time
            ? moment(workerCost.confirmed_time).format("DD.MM.YYYY HH:mm")
            : "-"
        }}</span>
      </div>
    </div>
    <div
      v-if="
        showEdits || (showExtraInformation && workerCost.editions.length > 0)
      "
      class="flex flex-col border-t border-b border-offwhite-dark p-2 bg-offwhite"
    >
      <div class="grid grid-cols-4 font-medium">
        <span>Muutja</span>
        <span>Väli</span>
        <span>Vana väärtus</span>
        <span>Uus väärtus</span>
      </div>
      <div
        v-for="item in workerCost.editions"
        :key="item.id"
        class="grid grid-cols-4"
      >
        <div>{{ item.editor }}</div>
        <div>
          {{ translateFieldName(item.field_name) }}
        </div>
        <div>
          {{ transformFieldValue(item.field_name, item.initial_value) }}
        </div>
        <div>
          {{ transformFieldValue(item.field_name, item.new_value) }}
        </div>
      </div>
    </div>
    <delete-confirm-modal
      v-if="isDeleteModalActive"
      @confirmDelete="deleteTime()"
      @closeModal="isDeleteModalActive = false"
    />
  </div>
</template>
<script>
import DeleteConfirmModal from "@/components/reusable/DeleteConfirmModal.vue";

import moment from "moment";
import { ee } from "vuejs-datepicker/dist/locale";
import TimeSelector from "@/components/reusable/TimeSelector.vue";
import {
  costWithVatWorkerTime,
  priceWithVatWorkerTime,
  round2,
  workerTimeWithCoef,
} from "@/assets/utils/commonMath";
import { mapGetters } from "vuex";
import DecimalInput from "@/components/reusable/DecimalInput.vue";
import {
  transformFieldValue,
  translateFieldName,
} from "@/assets/utils/commonTransforms";
export default {
  name: "WorkerCost",
  components: { DecimalInput, DeleteConfirmModal, TimeSelector },
  props: {
    workerCost: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      startDate: null,
      startTime: null,
      endTime: null,
      editMode: false,
      moment: moment,
      ee: ee,
      showEdits: false,
      showExtraInformation: false,
      isDeleteModalActive: false,
      hourlyCost: null,
      hourlyPay: null,
      costCoefficient: null,
      vatType: null,
    };
  },
  mounted() {
    this.startTime = moment(this.workerCost.real_times.start_time);
    this.endTime = moment(this.workerCost.real_times.end_time);
    this.startDate = moment(this.workerCost.real_times.start_time);
    this.hourlyCost = this.workerCost.cost;
    this.hourlyPay = this.workerCost.price;
    this.costCoefficient = this.workerCost.cost_coefficient;
    this.vatType = this.workerCost.vatType;
  },
  methods: {
    workerTimeWithCoef,
    transformFieldValue,
    translateFieldName,
    costWithVatWorkerTime,
    priceWithVatWorkerTime,
    round2,
    handleStartTimeChange(e) {
      this.startTime.hour(e.hour).minute(e.minute);
    },
    handleEndTimeChange(e) {
      this.endTime.hour(e.hour).minute(e.minute);
    },
    handleDateChange(e) {
      this.startDate = moment(e);
    },
    parseStartDate(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    parseTime(date) {
      return moment(date).format("HH:mm");
    },
    formatTime(date) {
      return moment(date).format("HH:mm");
    },
    confirmCost() {
      this.$emit("confirm", this.workerCost.worker_id, this.workerCost.id);
    },
    navigateToWorker() {
      this.$router.push(
        `/workers/${this.workerCost.worker_id}?task_id=${this.workerCost.task.id}`
      );
      this.$store.dispatch("modals/taskModal/close", this.workerCost.task.id);
    },
    saveTime() {
      if (this.startTime > this.endTime || this.hourlyPay < 0) {
        return false;
      }
      if (
        this.parseTime(this.startTime) ===
          this.parseTime(this.workerCost.real_times.start_time) &&
        this.parseTime(this.endTime) ===
          this.parseTime(this.workerCost.real_times.end_time) &&
        this.workerCost.price === this.hourlyPay &&
        this.workerCost.cost === this.hourlyCost &&
        this.workerCost.cost_coefficient === this.costCoefficient &&
        this.workerCost.vatType === this.vatType
      ) {
        this.editMode = false;
      } else {
        this.$emit("update", {
          id: this.workerCost.time_tracking_id,
          workerId: this.workerCost.worker_id,
          startTime: this.startTime,
          endTime: this.endTime,
          hourlyPay: this.hourlyPay,
          hourlyCost: this.hourlyCost,
          costCoefficient: this.costCoefficient,
          vatType: this.vatType,
        });
        this.editMode = false;
      }
    },

    deleteTime() {
      this.$emit(
        "delete",
        this.workerCost.worker_id,
        this.workerCost.time_tracking_id
      );
    },
  },
  computed: {
    ...mapGetters({
      isAdmin: "companyData/isAdmin",
      isManager: "companyData/isManager",
      userRole: "companyData/userPermission",
    }),
  },
};
</script>
