<template>
  <div class="flex flex-col gap-y-2">
    <div class="flex gap-x-2">
      <div
        class="flex w-4 self-center cursor-pointer"
        @click="openTaskLocation(taskData.location)"
      >
        <span class="w-4 h-4 bg-primary rounded-full"></span>
      </div>
      <div class="flex flex-col w-full">
        <planning-location-selector
          v-if="isAdmin || isManager"
          v-model="taskData.location"
          class="w-full mobile:mb-2"
        >
          {{
            transformedStartLocation
              ? `Lähtekoht - ${transformedStartLocation}`
              : "Lähtekoht"
          }}
        </planning-location-selector>
        <div
          v-else
          class="flex flex-col gap-x-2 cursor-pointer"
          @click="openTaskLocation(taskData.location)"
        >
          <h4>Lähtekoht</h4>
          <span>
            {{ parseLocation(taskData.location, savedLocations) }}
          </span>
          <span v-if="taskData.location && taskData.location.info">
            {{ taskData.location.info }}
          </span>
        </div>
      </div>
    </div>

    <div
      v-for="extraLocation in taskData.extra_locations"
      :key="extraLocation.id"
      class="flex gap-x-2 gap-y-1"
    >
      <div class="flex w-4 relative self-center">
        <span class="w-4 h-4 bg-primary rounded-full"></span>
      </div>
      <div class="flex flex-col w-full">
        <planning-location-selector
          v-if="isAdmin || isManager"
          v-model="extraLocation.location"
          :extra-location="true"
          class="w-full mobile:mb-2"
          @delete="
            $store.dispatch(
              'modals/taskModal/deleteExtraLocation',
              extraLocation
            )
          "
        >
          Vahesihtkoht
        </planning-location-selector>
        <div
          v-else
          class="flex flex-col gap-x-2 cursor-pointer"
          @click="openTaskLocation(extraLocation.location)"
        >
          <h4>Vahesihtkoht</h4>
          <span>
            {{ parseLocation(extraLocation.location, savedLocations) }}
          </span>
          <span v-if="extraLocation.location && extraLocation.location.info">
            {{ extraLocation.location.info }}
          </span>
        </div>
      </div>
    </div>
    <div v-if="extraLocations.length > 0" class="flex flex-col gap-y-1">
      <div
        v-for="(location, index) in extraLocations"
        :key="index"
        class="flex w-full gap-x-2"
      >
        <div class="flex w-4 self-center">
          <span class="w-4 h-4 bg-primary rounded-full"></span>
        </div>
        <div class="flex flex-col w-full">
          <planning-location-selector
            v-if="!location"
            :emit-standardized-object="true"
            :extra-location="true"
            ref="extraLocations"
            class="w-full"
            @input="
              (e) =>
                $store.dispatch(`modals/taskModal/updateExtraLocation`, {
                  index: index,
                  newLocation: e,
                })
            "
            @delete="
              $store.dispatch(`modals/taskModal/deleteNotSavedExtraLocation`, {
                index: index,
              })
            "
          >
            Vahesihtkoht
          </planning-location-selector>
          <div v-else class="flex bg-danger">
            {{ location.full_address }}
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="taskData.location && taskData.end_location && isManager"
      class="flex gap-x-2 cursor-pointer rounded-l-full rounded-r hover:bg-primary hover:bg-opacity-10 duration-100"
      @click="$store.dispatch('modals/taskModal/addExtraLocation')"
    >
      <div class="w-4 self-center">
        <button
          class="bg-primary h-4 w-4 rounded-full leading-none flex justify-center items-center text-white"
        >
          <span class="typcn typcn-plus leading-none"></span>
        </button>
      </div>
      <div class="flex font-medium">
        <span>Lisa vahesihtkoht</span>
      </div>
    </div>
    <div class="flex gap-x-2">
      <div
        class="flex w-4 relative self-center cursor-pointer"
        @click="openTaskLocation(taskData.end_location)"
      >
        <span class="w-4 h-4 bg-primary rounded-full"></span>
      </div>
      <div class="flex flex-col flex-1">
        <planning-location-selector
          v-if="isAdmin"
          v-model="taskData.end_location"
          class="w-full mobile:mb-2"
        >
          {{
            transformedEndLocation
              ? `Sihtkoht - ${transformedEndLocation}`
              : "Sihtkoht"
          }}
        </planning-location-selector>
        <div
          v-else
          class="flex flex-col gap-x-2 cursor-pointer"
          @click="openTaskLocation(taskData.end_location)"
        >
          <h4>Sihtkoht</h4>
          <span>
            {{ parseLocation(taskData.end_location, savedLocations) }}
          </span>
          <span v-if="taskData.end_location && taskData.end_location.info">
            {{ taskData.end_location.info }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PlanningLocationSelector from "@/components/tasks/planning/PlanningLocationSelector";
import { mapState, mapGetters } from "vuex";
import {
  parseLocation,
  transformLocationName,
} from "@/assets/utils/commonTransforms";
export default {
  components: {
    PlanningLocationSelector,
  },
  methods: {
    parseLocation,
    transformLocationName,
    openTaskLocation(location) {
      if (!location) return;
      const link = document.createElement("a");
      let url;
      if (location.latitude && location.longitude) {
        url = `https://maps.google.com/?q=${location.latitude},${location.longitude}`;
      } else {
        url = "https://maps.google.com/";
      }
      link.href = url;
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
    },
  },
  computed: {
    ...mapState({
      taskData: (state) => state.modals.taskModal.taskData,
    }),
    ...mapGetters({
      isManager: "companyData/isManager",
      isAdmin: "companyData/isAdmin",
      extraLocations: "modals/taskModal/extraLocations",
      savedLocations: "companyVariables/savedLocations",
    }),
    transformedStartLocation() {
      return this.transformLocationName(
        this.taskData.location,
        this.savedLocations
      );
    },
    transformedEndLocation() {
      return this.transformLocationName(
        this.taskData.end_location,
        this.savedLocations
      );
    },
  },
};
</script>
<style lang="scss"></style>
