<template>
  <div
    class="grid items-center p-2 timer-rows"
    :class="[
      item.added_by !== item.worker_id
        ? 'bg-pink/50'
        : item.has_editions
        ? 'bg-attention/50'
        : '',
    ]"
  >
    <div
      class="flex"
      :class="[
        item.start_location || item.end_location ? 'cursor-pointer' : '',
      ]"
      @click="openGeoMap"
    >
      {{ item.worker_name }}
    </div>
    <div class="flex">
      {{
        item.stopper_end
          ? moment
              .utc(
                moment
                  .duration(
                    moment(item.stopper_end).diff(moment(item.stopper_start))
                  )
                  .as("milliseconds")
              )
              .format("HH:mm:ss")
          : moment
              .utc(
                moment
                  .duration(moment().diff(moment(item.stopper_start)))
                  .as("milliseconds")
              )
              .format("HH:mm:ss")
      }}
    </div>
    <div class="flex items-center" v-if="startDate">
      <DateSelector v-if="editMode" v-model="startDate" class="text-sm" />
      <span v-else> {{ moment(item.stopper_start).format("DD.MM.YYYY") }}</span>
    </div>
    <div class="flex">
      {{ moment(item.task_start).format("HH:mm") }}
    </div>
    <div class="flex">
      {{ moment(item.task_end).format("HH:mm") }}
    </div>
    <div class="flex items-center" :class="editMode ? '' : 'justify-start'">
      <span v-if="!editMode">
        {{ moment(item.stopper_start).format("HH:mm") }}
      </span>
      <time-selector
        v-else
        class="flex flex-row w-full justify-start"
        :value="startTime"
        :full-minutes="true"
        @input="handleStartTimeChange"
        :is-white="false"
        :is-moment="true"
        :class="startTime > endTime ? 'wrong-time' : ''"
      />
    </div>
    <div class="flex items-center" :class="editMode ? '' : 'justify-start'">
      <span v-if="!editMode">
        {{
          item.stopper_end ? moment(item.stopper_end).format("HH:mm") : "Käigus"
        }}
      </span>
      <time-selector
        v-if="editMode && item.stopper_end !== null"
        :full-minutes="true"
        class="flex flex-row w-full justify-start"
        :value="endTime"
        @input="handleEndTimeChange"
        :is-white="false"
        :is-moment="true"
        :class="startTime > endTime ? 'wrong-time' : ''"
      />
    </div>

    <div class="flex">
      {{ item.info }}
    </div>
    <div v-if="!item.stopper_end && isManager">
      <button class="btn-primary" @click="endStopperAsManager">Lõpeta</button>
    </div>
    <div v-if="item.stopper_end && isManager" class="flex items-center gap-x-2">
      <button
        v-if="false && !item.confirmed"
        class="btn-primary"
        @click.stop.prevent="confirmTimer"
      >
        Kinnita
      </button>
      <div class="flex flex-row items-center justify-end gap-x-2">
        <button
          class="bg-primary hover:bg-primary-dark rounded shadow flex items-center justify-center px-2"
          v-if="!editMode && !item.confirmed"
          @click.stop.prevent="editMode = !editMode"
        >
          <span class="h-6 w-6 typcn-edit typcn icon text-white" />
          <span class="label text-white"> Muuda </span>
        </button>
        <button
          class="btn-primary"
          v-if="editMode && !item.confirmed"
          @click.stop.prevent="saveTime"
        >
          <span class="typcn-tick typcn icon" />
        </button>
        <button
          v-if="!item.confirmed"
          @click.stop.prevent="isDeleteModalActive = true"
          class="h-6 w-6 bg-danger hover:bg-danger-dark rounded shadow flex items-center justify-center"
        >
          <img
            src="/bc21/trash.svg"
            class="h-4 w-4 filter-to-white"
            alt="Delete icon"
          />
        </button>
      </div>
    </div>
    <delete-confirm-modal
      v-if="isDeleteModalActive"
      @confirmDelete="deleteTime"
      @closeModal="isDeleteModalActive = false"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
import DeleteConfirmModal from "@/components/reusable/DeleteConfirmModal.vue";
import TimeSelector from "@/components/reusable/TimeSelector.vue";
import DateSelector from "../../globals/DateSelector.vue";

export default {
  name: "TaskTimerItem",
  components: { TimeSelector, DateSelector, DeleteConfirmModal },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      editMode: false,
      moment: moment,
      isDeleteModalActive: false,
      startTime: null,
      endTime: null,
      startDate: null,
    };
  },
  methods: {
    endStopperAsManager() {
      this.$emit("endTimerAsManager", this.item);
    },
    openGeoMap() {
      this.$emit("openGeoMap", this.item);
    },
    editItem() {},
    confirmTimer() {
      this.$emit("confirm", this.item);
    },
    deleteTime() {
      this.$emit("deleteTime", this.item);
    },
    saveTime() {
      if (this.startTime > this.endTime) {
        return false;
      }
      if (
        this.parseTime(this.startTime) ===
          this.parseTime(this.item.stopper_start) &&
        this.parseTime(this.endTime) ===
          this.parseTime(this.item.stopper_end) &&
        this.parseDate(this.startDate) ===
          this.parseDate(this.item.stopper_start)
      ) {
        this.editMode = false;
      } else {
        this.$emit("update", {
          id: this.item.id,
          startTime: this.formatTime(this.startTime),
          endTime: this.formatTime(this.endTime),
          workerId: this.item.worker_id,
        });
        this.editMode = false;
      }
    },
    formatTime(time) {
      return this.startDate
        .hour(time.hour())
        .minute(time.minute())
        .format("YYYY-MM-DD[T]HH:mm:ssZ");
    },
    parseDate(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    parseTime(date) {
      return moment(date).format("HH:mm");
    },
    handleStartTimeChange(e) {
      this.startTime.hour(e.hour).minute(e.minute);
    },
    handleEndTimeChange(e) {
      this.endTime.hour(e.hour).minute(e.minute);
    },
  },
  mounted() {
    this.startTime = moment(this.item.stopper_start);
    this.endTime = moment(this.item.stopper_end);
    this.startDate = moment(this.item.stopper_start);
  },
  computed: {
    ...mapGetters({
      isManager: "companyData/isManager",
    }),
  },
};
</script>
<style lang="scss" scoped>
.timer-rows {
  grid-template-columns: 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr 0.5fr 0.2fr;
}
</style>
