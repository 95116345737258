import { isEqual } from "lodash";
import StoreRequestHandler from "@/assets/mixins/StoreRequestHandler";
import set from "lodash/set";
import router from "@/router/index.js";

export const project = {
  namespaced: true,
  state: {
    project: null,
    projectClone: null,
  },
  mutations: {
    setProject(state, data) {
      state.project = JSON.parse(JSON.stringify(data));
      state.projectClone = JSON.parse(JSON.stringify(data));
    },
    clearState(state) {
      state.project = null;
      state.projectClone = null;
    },
    resetProject(state) {
      state.project = JSON.parse(JSON.stringify(state.projectClone));
    },
  },
  actions: {
    resetProject({ commit }) {
      commit("resetProject");
    },
    retrieveProject({ commit, dispatch }, data) {
      return new Promise((resolve, reject) => {
        let url = data.isClient
          ? `notes/draft/${data.id}/client/`
          : `company/drafts/${data.id}/`;
        StoreRequestHandler.apiRequest(url, "get", true)
          .then((res) => {
            commit("setProject", res.data);
            resolve();
          })
          .catch((err) => {
            if (err.response.status === 403) {
              dispatch(
                "messageHandler/throwMessage",
                {
                  text: "Tellimuse nägemine keelatud.",
                  type: "error",
                  ttl: 10,
                },
                { root: true }
              );
              router.push("/calendar").then(() => {});
            } else {
              reject(err);
            }
          });
      });
    },
    clearProject({ commit }) {
      commit("clearState");
    },
    saveProject({ state }) {
      let data = { ...state.projectClone };

      Object.keys(data).forEach((k) => {
        if (isEqual(data[k], state.project[k])) delete data[k];
        else if (k === "location") {
          if (data[k] === null && state.project[k]) {
            StoreRequestHandler.apiRequest(
              `company/draft/${state.project.uuid}/location/delete/`,
              "delete",
              true
            );
          }
        } else if (k === "client") {
          let updatedValues = {};
          Object.keys(data[k]).forEach((j) => {
            if (!isEqual(data[k][j], state.project.client[j])) {
              set(updatedValues, j, data[k][j]);
            }
          });
          if (Object.keys(updatedValues).length > 0) {
            StoreRequestHandler.apiRequest(
              `company/${state.projectClone.company.uuid}/clients/${data[k]["id"]}/edit/`,
              "patch",
              true,
              updatedValues
            );
          }

          delete data[k];
        } else if (k === "payer") {
          let updatedValues = {};
          Object.keys(data[k]).forEach((j) => {
            if (!isEqual(data[k][j], state.project.payer[j])) {
              set(updatedValues, j, data[k][j]);
            }
          });
          if (Object.keys(updatedValues).length > 0) {
            StoreRequestHandler.apiRequest(
              `company/${state.projectClone.company.uuid}/clients/${data[k]["id"]}/edit/`,
              "patch",
              true,
              updatedValues
            );
          }

          delete data[k];
        } else if (k === "invoice_creator") {
          const invoiceCreator = data[k];
          delete data[k];
          data.invoice_creator = invoiceCreator.id;
        }
      });
      return StoreRequestHandler.apiRequest(
        `company/draft/${state.projectClone.uuid}/edit/`,
        "patch",
        true,
        data
      );
    },
    updateProjectClient({ state }, inputData) {
      let data;
      if (inputData.selectClient) {
        data = { client: inputData.clientId };
      } else {
        data = { payer: inputData.clientId };
      }
      return StoreRequestHandler.apiRequest(
        `company/draft/${state.projectClone.uuid}/edit/`,
        "patch",
        true,
        data
      );
    },
    deleteProject({ state }) {
      return StoreRequestHandler.apiRequest(
        `company/draft/${state.project.uuid}/delete/`,
        "delete",
        true
      );
    },
  },
  getters: {
    projectEdited: (state) => {
      let compObject = JSON.parse(JSON.stringify(state.project));
      let compObjectClone = JSON.parse(JSON.stringify(state.projectClone));
      if (compObject === null) return false;
      delete compObject.location;
      delete compObjectClone.location;
      let invoiceCreator = compObject.invoice_creator?.id ?? null;
      let invoiceCreatorClone = compObjectClone.invoice_creator?.id ?? null;
      delete compObject.invoice_creator;
      delete compObjectClone.invoice_creator;
      return (
        !isEqual(compObject, compObjectClone) ||
        invoiceCreator !== invoiceCreatorClone
      );
    },
    data: (state) => state.project,
    projectId: (state) => state.project.uuid,
  },
};
