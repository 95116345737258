<template>
  <div class="message-container" v-if="messages.length > 0">
    <transition-group name="slowfade" class="w-full">
      <div
        v-for="message in messages"
        :key="message.id"
        class="message-item"
        :class="[message.type, message.payload ? 'cursor-pointer' : '']"
        @click.stop.prevent="routeToTarget(message)"
        :title="errorText(message)"
      >
        <span class="px-4">{{ message.text }}</span>
        <span
          class="typcn typcn-times hover:text-offwhite-dark cursor-pointer pr-2"
          @click="clearMessage(message.id)"
          id="message-close-button"
        />
      </div>
    </transition-group>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  methods: {
    routeToTarget(message) {
      if (message.payload?.party_id) {
        if (
          message.payload?.company_id &&
          message.payload.company_id !== this.companyId
        ) {
          this.$store
            .dispatch("companyData/activateCompany", message.payload.company_id)
            .then(() => {
              this.$router.push(`/chats?chat_id=${message.payload.party_id}`);
            });
        } else {
          this.$router.push(`/chats?chat_id=${message.payload.party_id}`);
        }
      }
    },
    errorText(message) {
      return message.error?.response?.data?.error_message ?? "";
    },
    ...mapActions("messageHandler", ["clearMessage"]),
  },
  computed: {
    ...mapGetters({
      companyId: "companyData/activeCompanyUuid",
      messages: "messageHandler/messages",
    }),
  },
};
</script>
<style lang="scss">
.message-container {
  @apply fixed flex flex-col pr-6 justify-end items-end;
  position: -webkit-sticky;
  right: 0;
  bottom: 10px;
  width: 33%;
  z-index: 9999;
  .message-item {
    @apply py-2 rounded flex text-white w-auto shadow-xl mb-2 justify-between gap-x-2;
    &.error {
      @apply bg-danger;
    }
    &.primary {
      @apply bg-primary;
    }
    &.warning {
      @apply bg-attention;
    }
    &:last-child {
      @apply mb-0;
    }
  }
}
@media (max-width: 1024px) {
  .message-container {
    width: 100%;
    @apply px-2;
    .message-item {
      @apply w-full;
    }
  }
}
</style>
