import StoreRequestHandler from "@/assets/mixins/StoreRequestHandler";
import jwtdecode from "jwt-decode";
import set from "lodash/set";

export const chat = {
  namespaced: true,
  state: {
    parties: null,
    chatNotifications: [],
    showNotification: false,
    latestNotification: null,
    activeChatParties: [],
    activeChats: [],
  },
  mutations: {
    setInitialParties(state, data) {
      state.parties = data;
    },
    setChatNotifications(state, data) {
      state.chatNotifications = data;
    },
    appendChatNotification(state, data) {
      let activeViewParty = [
        ...state.activeChatParties,
        ...state.activeChats,
      ].find((x) => x.id === Number(data.object_id));
      if (
        !activeViewParty ||
        data.task ||
        data.company ||
        data.project ||
        data.worker ||
        data.client
      ) {
        let chatRoomUrl;
        if (data.company && data.task) {
          chatRoomUrl = `chat/${data.company}/task/${data.task}/`;
        } else if (data.company && data.worker) {
          chatRoomUrl = `chat/${data.company}/worker/${data.worker}/`;
        } else if (data.company && data.project) {
          chatRoomUrl = `chat/${data.company}/project/${data.project}/`;
        } else if (data.company && data.client) {
          chatRoomUrl = `chat/${data.company}/client/${data.client}/`;
        } else {
          chatRoomUrl = `chat/rooms/${data.object_id}/`;
        }

        StoreRequestHandler.apiRequest(chatRoomUrl, "get", true).then(
          (res2) => {
            let updatePartyIndex = state.parties.findIndex(
              (x) => Number(x.id) === Number(data.object_id)
            );
            state.parties[updatePartyIndex] = res2.data;
            state.chatNotifications.unshift(data);
          }
        );
      } else {
        StoreRequestHandler.apiRequest(
          `chat/${data.object_id}/read/`,
          "post",
          true
        );
      }
    },
    activeChatParty(state, data) {
      if (!state.activeChatParties.find((x) => x.id === data.id)) {
        state.activeChatParties.push(data);
      }
    },
    closeChatParty(state, data) {
      state.activeChatParties.splice(
        state.activeChatParties.indexOf(
          state.activeChatParties.find((x) => x === data.id)
        ),
        1
      );
    },
    markPartyNotificationsAsRead(state, data) {
      state.chatNotifications = state.chatNotifications.filter(
        (x) => Number(x.object_id) !== Number(data)
      );
    },
    markChatPartyAsRead(state, data) {
      StoreRequestHandler.apiRequest(`chat/${data}/read/`, "post", true).then(
        (res) => {
          if (res.status === 200) {
            state.chatNotifications = state.chatNotifications.filter(
              (x) => Number(x.object_id) !== Number(data)
            );
          }
        }
      );
    },
    togglePartyActive(state, data) {
      const party = state.activeChats.find((x) => x.id === data.party_id);
      if (party) {
        state.activeChats.splice(state.activeChats.indexOf(party), 1);
      } else {
        state.activeChats.push({
          id: data.party_id,
        });
      }
    },
  },
  actions: {
    retrieveParties({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        StoreRequestHandler.apiRequest(`chat/parties/`, "get", true)
          .then((res) => {
            commit("setInitialParties", res.data);
            dispatch("notifications/initAppNotificationWebsocket", null, {
              root: true,
            })
              .then(() => resolve())
              .catch((err) => console.error(err));
          })
          .catch((err) => reject(err));
      });
    },
    retrieveChatNotifications({ commit }) {
      const token = jwtdecode(localStorage.getItem("access")).user_id;
      StoreRequestHandler.apiRequest(
        `chat/notifications/${token}/`,
        "get",
        true
      ).then((res) => {
        commit("setChatNotifications", res.data);
      });
    },
    activateChatParty({ commit }, data) {
      commit("activeChatParty", data);
    },
    closeChatParty({ commit }, data) {
      commit("closeChatParty", data);
    },
    markPartyNotificationsAsRead({ commit }, data) {
      commit("markPartyNotificationsAsRead", data);
    },
    markChatPartyAsRead({ commit }, data) {
      commit("markChatPartyAsRead", data);
    },
    appendChatNotification({ commit, dispatch, state }, data) {
      let append = true;
      if (data.object_type === "ChatParty" && data.object_id) {
        if (
          [...state.activeChats, ...state.activeChatParties].find(
            (x) => x.id === Number(data.object_id)
          )
        ) {
          append = false;
          StoreRequestHandler.apiRequest(
            `chat/${data.object_id}/read/`,
            "post",
            true
          );
        }
      }

      // If chat is active OR chat view open, don't send?
      if (append) {
        commit("appendChatNotification", data);
        console.log(data);
        dispatch(
          "messageHandler/throwMessage",
          {
            type: "primary",
            text: `${data.profile}: ${data.description}`,
            ttl: 5,
            payload: {
              party_id: Number(data.object_id),
              company_id: data.company_id,
            },
          },
          {
            root: true,
          }
        );
      }
    },
    togglePartyActive({ commit }, data) {
      commit("togglePartyActive", data);
    },
    findNotificationTarget({ state }, tasks) {
      tasks = tasks.filter((x) => !!x);
      if (state.chatNotifications.length === 0) return tasks;
      const taskIds = tasks.map((task) => task.id);
      for (let notification of state.chatNotifications) {
        const chatParty =
          state.parties.find((x) => x.id === Number(notification.object_id)) ??
          null;
        if (chatParty && chatParty.task && taskIds.includes(chatParty.task)) {
          set(
            tasks.find((x) => x.id === chatParty.task),
            "chatNotification",
            true
          );
        }
      }
      return tasks;
    },
  },
  getters: {
    parties: (state) => state.parties,
    notifications: (state) => state.chatNotifications,
    latestNotification: (state) => state.latestNotification,
  },
};
