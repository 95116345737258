<template>
  <div class="data-container-content data-container-table">
    <div class="gwd-table">
      <div class="worker-table gwd-table-header">
        <div class="flex">
          <h4>Nimi</h4>
        </div>
        <div class="flex">
          <h4>Plan. algus</h4>
        </div>
        <div class="flex">
          <h4>Plan. lõpp</h4>
        </div>
        <div class="flex">
          <h4>Teg. tööaeg</h4>
        </div>
        <div class="flex">
          <h4>Lähtekoht</h4>
        </div>
        <div class="flex">
          <h4>Sihtkoht</h4>
        </div>
        <div class="flex">
          <h4>Kirjeldus</h4>
        </div>
        <div class="flex"></div>
      </div>
      <div
        class="flex flex-col w-full"
        v-for="assignment in taskData.sub_tasks"
        :key="assignment.worker.id"
      >
        <div class="worker-table items-center w-full p-2">
          <div
            class="flex font-medium cursor-pointer"
            @click="navigateToWorker(assignment.worker.id)"
          >
            {{ assignment.worker.worker_name }}
          </div>
          <div class="flex items-center justify-between" v-if="false">
            {{
              `${formatTimeUnit(
                assignment.start_time
                  ? assignment.start_time.hour
                  : moment(taskData.start_time).hour()
              )}:${formatTimeUnit(
                assignment.start_time
                  ? assignment.start_time.minute
                  : moment(taskData.start_time).minute()
              )}-${formatTimeUnit(
                assignment.end_time
                  ? assignment.end_time.hour
                  : moment(taskData.end_time).hour()
              )}:${formatTimeUnit(
                assignment.end_time
                  ? assignment.end_time.minute
                  : moment(taskData.end_time).minute()
              )}`
            }}
          </div>
          <div class="flex items-center justify-between gap-x-2">
            <time-selector
              v-model="assignment.start_time"
              v-if="assignment.start_time"
              pos="t"
              width="8"
            />
            <span
              class="w-full h-full hover:bg-offwhite hover:p-1 rounded duration-200 cursor-pointer"
              v-else
              @click="
                () =>
                  (assignment.start_time = {
                    hour: moment(taskData.start_time).hour(),
                    minute: moment(taskData.start_time).minute(),
                  })
              "
            >
              {{ `${moment(taskData.start_time).format("HH:mm")}` }}
            </span>
            <button
              @click="assignment.start_time = null"
              class="btn-danger w-5"
              v-if="assignment.start_time"
            >
              <span class="typcn typcn-times leading-none" />
            </button>
          </div>
          <div class="flex items-center justify-between gap-x-2">
            <time-selector
              v-model="assignment.end_time"
              v-if="assignment.end_time"
              pos="t"
              width="10"
            />
            <span
              v-else
              class="w-full h-full hover:bg-offwhite hover:p-1 rounded duration-200 cursor-pointer"
              @click="
                () =>
                  (assignment.end_time = {
                    hour: moment(taskData.end_time).hour(),
                    minute: moment(taskData.end_time).minute(),
                  })
              "
            >
              {{ `${moment(taskData.end_time).format("HH:mm")}` }}
            </span>
            <button
              @click="assignment.end_time = null"
              class="btn-danger w-5"
              v-if="assignment.end_time"
            >
              <span class="typcn typcn-times leading-none" />
            </button>
          </div>
          <div class="flex items-center gap-x-1">
            <span
              class="h-3 w-3 bg-green rounded-full"
              title="Töötajal on aktiivne taimer"
              v-if="workerHasActiveTimer(assignment.worker.profile)"
            ></span>
            <span>{{ getWorkerTimeTotal(assignment.worker.profile) }}</span>
          </div>
          <div class="flex box-border items-center justify-between truncate">
            <span
              v-if="assignment.location"
              @click="
                $store.dispatch(`modals/taskModal/openLocationModal`, {
                  assignmentId: assignment.id,
                  field: `location`,
                  currLocation: assignment.location,
                })
              "
              class="flex truncate py-1 hover:px-2 hover:bg-offwhite duration-200 rounded h-full cursor-pointer"
            >
              {{ assignment.location.long_address }}
            </span>
            <span
              v-else
              class="flex truncate py-1 hover:px-2 hover:bg-offwhite duration-200 rounded h-full cursor-pointer"
              @click="
                $store.dispatch(`modals/taskModal/openLocationModal`, {
                  assignmentId: assignment.id,
                  field: `location`,
                  currLocation: null,
                })
              "
            >
              {{ taskData.location ? taskData.location.long_address : "-" }}
            </span>
            <button
              class="btn-danger w-5"
              @click="assignment.location = null"
              v-if="assignment.location"
            >
              <span class="typcn typcn-times" />
            </button>
          </div>
          <div class="flex box-border items-center justify-between truncate">
            <span
              v-if="assignment.end_location"
              @click="
                $store.dispatch(`modals/taskModal/openLocationModal`, {
                  assignmentId: assignment.id,
                  field: `end_location`,
                  currLocation: assignment.end_location,
                })
              "
              class="flex truncate py-1 hover:px-2 hover:bg-offwhite duration-200 rounded h-full cursor-pointer"
            >
              {{ assignment.end_location.long_address }}
            </span>
            <span
              v-else
              class="flex truncate py-1 hover:px-2 hover:bg-offwhite duration-200 rounded h-full cursor-pointer"
              @click="
                $store.dispatch(`modals/taskModal/openLocationModal`, {
                  assignmentId: assignment.id,
                  field: `end_location`,
                  currLocation: null,
                })
              "
            >
              {{
                taskData.end_location ? taskData.end_location.long_address : "-"
              }}
            </span>
            <button
              class="btn-danger w-5"
              @click="assignment.end_location = null"
              v-if="assignment.end_location"
            >
              <span class="typcn typcn-times" />
            </button>
          </div>
          <div class="flex">
            <gwd-textarea
              v-model="assignment.description"
              placeholder="Kirjeldus"
              class="w-full"
            />
          </div>
          <div class="flex justify-center">
            <button class="btn-danger" @click="assignmentToDelete = assignment">
              <img
                src="/bc21/trash.svg"
                class="h-4 w-4 filter-to-white"
                alt="Delete worker"
              />
            </button>
          </div>
        </div>
        <div
          class="grid grid-cols-16 p-2 gap-x-2"
          v-if="
            assignment.worker.equipment &&
            assignment.worker.equipment.length > 0
          "
        >
          <div class="flex flex-col">
            <div
              class="grid"
              v-for="equipment in assignment.worker.equipment"
              :key="equipment.id"
            >
              <span class="h-2 w-4 bg-offwhite-dark rounded"></span>
              <span class="px-2">{{ equipment.name }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <confirm-modal
      v-if="assignmentToDelete"
      text="Oled kindel, et soovid töötajat eemaldada?"
      @confirm="
        () =>
          $store.dispatch(
            'modals/taskModal/removeAssignment',
            assignmentToDelete
          )
      "
      @closeModal="assignmentToDelete = null"
    />
  </div>
</template>
<script>
import TimeSelector from "@/components/reusable/TimeSelector.vue";
import moment from "moment";
import { mapState, mapGetters } from "vuex";
import {
  formatTimeUnit,
  parseTimerData,
} from "@/assets/utils/commonTransforms";
import EventBus from "@/assets/mixins/EventBus";
import set from "lodash/set";
import ConfirmModal from "@/components/reusable/ConfirmModal.vue";
export default {
  components: {
    ConfirmModal,
    TimeSelector,
  },
  data() {
    return {
      moment: moment,
      assignmentToDelete: null,
    };
  },
  mounted() {
    EventBus.$on("subTaskLocationUpdated", this.updateSubTaskLocation);
  },
  beforeDestroy() {
    EventBus.$off("subTaskLocationUpdated");
  },
  methods: {
    formatTimeUnit,
    navigateToWorker(id) {
      this.$router.push(`/workers/${id}?task_id=${this.taskData.id}`);
      this.$store.dispatch("modals/taskModal/close", this.taskData.id);
    },
    workerHasActiveTimer(workerUUID) {
      if (!this.timerData) return false;
      return this.timerData
        .filter((x) => x.worker === workerUUID)
        .some((x) => !x.stopper_end);
    },
    getWorkerTimeTotal(workerUUID) {
      if (!this.timerData) return null;
      return parseTimerData(this.timerData, workerUUID);
    },
    updateSubTaskLocation(e) {
      let subTask = this.taskData.sub_tasks.find(
        (x) => x.id === e.assignmentId
      );
      if (subTask) {
        set(subTask, e.field, e.selectedLocation);
      }
    },
  },
  computed: {
    ...mapState({
      taskData: (state) => state.modals.taskModal.taskData,
    }),
    ...mapGetters({
      timerData: "modals/taskModal/timerData",
      taskWorkers: "modals/taskModal/workers",
    }),
  },
};
</script>
<style lang="scss">
.worker-table {
  @apply grid gap-x-2;
  grid-template-columns: 0.7fr 0.3fr 0.3fr 0.3fr 1fr 1fr 1fr 0.2fr;
}
</style>
