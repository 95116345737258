<template>
  <div class="flex flex-col gap-y-2">
    <div
      class="focus:shadow-outline shadow flex relative duration-75 bg-offwhite rounded-xl"
      v-on-clickaway:mousedown="closeDropdown"
    >
      <SearchInput v-model="searchQuery" v-if="!selectedResource" />
      <div
        class="w-11/12 flex px-2 py-1"
        @click="menuActive = true"
        v-if="selectedResource"
      >
        <span
          class="items-center flex leading-none flex-grow bg-offwhite rounded-xl"
        >
          <h4 class="flex-grow">{{ selectedResource.name }}</h4>
          <button class="btn-danger" @click="clearSelection">
            <span class="icon">
              <img
                src="/bc21/trash.svg"
                class="h-4 filter-to-white"
                alt="Delete icon"
              />
            </span>
          </button>
        </span>
      </div>
    </div>
    <div class="flex flex-col w-full">
      <ul v-if="resourcesLoaded && resources.results">
        <transition-group name="list">
          <li
            class="flex flex-col"
            v-for="resource in resources.results.filter(
              (x) => !toggledResources.includes(x.id)
            )"
            :key="resource.id"
            @click="selectResource(resource)"
            :class="
              resource.remaining_amount <= 0 ? 'disabled' : ' cursor-pointer'
            "
          >
            <div class="flex py-2 px-2 justify-between">
              <h4
                :class="
                  resource.remaining_amount === 0
                    ? 'line-through'
                    : 'cursor-pointer'
                "
              >
                {{ resource.name }}
              </h4>
              <h4>{{ resource.remaining_amount }}</h4>
            </div>
          </li>
          <li
            v-if="resources.results.length === 0"
            :key="'noresult'"
            class="font-medium flex items-center"
          >
            <span
              class="typcn typcn-times bg-attention rounded-full p-1 flex items-center justify-center leading-none mr-2"
            ></span>
            Vastet ei leitud
          </li>
        </transition-group>
        <page-changer
          class="self-center"
          v-if="resources.count > 1"
          :page="page"
          :count="resources.count"
          @pageChange="pageChange"
        ></page-changer>
      </ul>
    </div>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import PageChanger from "@/components/reusable/PageChanger";
import SearchInput from "../../reusable/SearchInput.vue";
import { mapGetters } from "vuex";
import { generateParamString } from "../../../assets/utils/commonTransforms";
export default {
  name: "ExistingResourceSelector",
  components: { PageChanger, SearchInput },
  props: {
    toggledResources: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      menuActive: false,
      searchQuery: "",
      resources: [],
      resourcesLoaded: false,
      selectedResource: null,
      page: 1,
    };
  },
  mounted() {
    this.loadResources();
  },
  methods: {
    pageChange(e) {
      this.page = e;
      this.loadResources();
    },
    closeDropdown() {
      this.menuActive = false;
      this.searchQuery = "";
    },
    loadResources() {
      let params = {
        page: this.page,
      };
      if (this.searchQuery.length > 0) params.filter = this.searchQuery;

      this.apiRequest(
        `template/${this.companyId}/resources/${generateParamString(params)}`,
        "get",
        true
      ).then((res) => {
        this.resources = res.data;
        this.resourcesLoaded = true;
      });
    },
    debounceSearchQuery: debounce(function () {
      this.loadResources();
    }, 500),
    clearSelection() {
      this.selectedResource = null;
      this.searchQuery = "";
    },
    selectResource(resource) {
      if (resource.remaining_amount > 0)
        this.$emit("resourceSelected", resource);
      // this.selectedResource = resource;
    },
  },
  computed: {
    ...mapGetters({
      companyId: "companyData/activeCompanyUuid",
    }),
  },
  watch: {
    searchQuery() {
      this.debounceSearchQuery();
    },
  },
};
</script>
