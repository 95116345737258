import moment from "moment";
import store from "..";
import StoreRequestHandler from "../../assets/mixins/StoreRequestHandler";
export const timetracking = {
  state: {
    timer: null,
    hasActiveTimer: false,
    loading: false,
  },
  namespaced: true,
  mutations: {
    startTimer(state, timer) {
      state.timer = timer;
      state.hasActiveTimer = true;
    },
    stopTimer(state) {
      state.timer = null;
      state.hasActiveTimer = false;
    },
    blockStart(state) {
      state.loading = true;
    },
    unblockStart(state) {
      state.loading = false;
    },
  },
  actions: {
    startTimer({ dispatch, commit }, taskId) {
      commit("blockStart");
      const request = (data) =>
        StoreRequestHandler.apiRequest(
          `${process.env.VUE_APP_API_URL}tasks/${taskId}/stopper/start/`,
          "post",
          true,
          data ? data : null
        );

      return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (pos) => {
              let data = {};
              data.start_location = {
                latitude: pos.coords.latitude,
                longitude: pos.coords.longitude,
                accuracy: pos.coords.accuracy,
                time_stamp: moment(pos.timestamp).format(
                  "YYYY-MM-DD[T]HH:mm:ssZ"
                ),
              };
              request(data)
                .then((res) => {
                  if (res.status === 201) {
                    dispatch("retrieveTimers")
                      .then(() => resolve())
                      .catch(() => reject());
                  }
                })
                .catch((err) => {
                  if (err.response.status === 400) {
                    if (err.response.data.code === 10) {
                      this.dispatch(
                        "messageHandler/throwMessage",
                        {
                          type: "error",
                          error: err,
                          text: "Ei saa lõpetatud projektil aja mõõtmist alustada, tellimus märgitud lõpetatuks!",
                        },
                        {
                          root: true,
                        }
                      );
                    } else if (err.response.data.code === 8) {
                      this.dispatch(
                        "messageHandler/throwMessage",
                        {
                          type: "error",
                          error: err,
                          text: "Ei saa lõpetatud töö aja mõõtmist alustada!",
                        },
                        {
                          root: true,
                        }
                      );
                    }
                  } else {
                    this.dispatch("messageHandler/throwMessage", {
                      type: "error",
                      text: "Midagi läks valesti",
                      error: err,
                    });
                  }
                  commit("unblockStart");
                  resolve();
                });
            },
            () => {
              request()
                .then((res) => {
                  if (res.status === 201)
                    dispatch("retrieveTimers")
                      .then(() => resolve())
                      .catch(() => reject());
                })
                .catch((err) => {
                  if (err.response.status === 400) {
                    if (err.response.data.code === 10) {
                      this.dispatch(
                        "messageHandler/throwMessage",
                        {
                          type: "error",
                          error: err,
                          text: "Ei saa lõpetatud projektil aja mõõtmist alustada, tellimus märgitud lõpetatuks!",
                        },
                        {
                          root: true,
                        }
                      );
                    } else if (err.response.data.code === 8) {
                      this.dispatch(
                        "messageHandler/throwMessage",
                        {
                          type: "error",
                          error: err,
                          text: "Ei saa lõpetatud töö aja mõõtmist alustada!",
                        },
                        {
                          root: true,
                        }
                      );
                    }
                  } else {
                    this.dispatch("messageHandler/throwMessage", {
                      type: "error",
                      text: "Midagi läks valesti",
                      error: err,
                    });
                  }
                  commit("unblockStart");
                  resolve();
                });
            }
          );
        } else {
          reject();
        }
      });
    },
    stopTimer({ dispatch, commit }, taskData) {
      const request = (data) =>
        StoreRequestHandler.apiRequest(
          `${process.env.VUE_APP_API_URL}tasks/${store.state.timetracking.timer.task_id}/stopper/${store.state.timetracking.timer.id}/stop/`,
          "put",
          true,
          data
        );

      commit("blockStart");
      return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (pos) => {
              let data = {};
              data.worker_estimated_pay = taskData
                ? taskData.estimatedWorkerPay
                : null;
              data.info = taskData ? taskData.taskWorkerInfo : null;
              data.end_location = {
                latitude: pos.coords.latitude,
                longitude: pos.coords.longitude,
                accuracy: pos.coords.accuracy,
                time_stamp: moment(pos.timestamp).format(
                  "YYYY-MM-DD[T]HH:mm:ssZ"
                ),
              };
              request(data).then((res) => {
                if (res.status === 200)
                  dispatch("retrieveTimers").then(() => resolve());
              });
            },
            () => {
              let data = {
                worker_estimated_pay: taskData
                  ? taskData.estimatedWorkerPay
                  : null,
                info: taskData ? taskData.taskWorkerInfo : null,
              };
              request(data).then((res) => {
                if (res.status === 200)
                  dispatch("retrieveTimers").then(() => resolve());
              });
            }
          );
        } else {
          reject();
        }
      });
    },
    retrieveTimers({ commit }) {
      return new Promise((resolve) => {
        StoreRequestHandler.apiRequest(
          `${process.env.VUE_APP_API_URL}tasks/${store.state.companyData.activeCompany.uuid}/active/timing/`,
          "get",
          true
        ).then((res) => {
          if (res.status === 200) {
            commit("startTimer", res.data);
            resolve();
          } else if (res.status === 204) {
            commit("stopTimer");
            resolve();
          }
          commit("unblockStart");
        });
      });
    },
  },
  getters: {
    activeTimer: (state) => state.timer,
  },
};
