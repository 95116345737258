import jwtdecode from "jwt-decode";
import cookies from "js-cookie";
import StoreRequestHandler from "../../assets/mixins/StoreRequestHandler";
import router from "@/router";

export const companyData = {
  state: {
    retrieved: false,
    companies: [],
    activeCompany: null,
    isClient: false,
    trialPopupActive: false,
    can_access: true,
  },
  namespaced: true,
  mutations: {
    setCompanyData(state, data) {
      state.companies = data.companies;
      let defCompany;
      const activeCompany = localStorage.getItem("activeCompany");
      if (!activeCompany) {
        if (data.defaultCompany) {
          defCompany = data.defaultCompany;
        } else if (localStorage.getItem("activeCompany")) {
          defCompany = localStorage.getItem("activeCompany");
        }
      }

      if (data.companies.length > 0) {
        if (defCompany) {
          state.activeCompany =
            state.companies.find((item) => item.uuid === defCompany) ??
            state.companies[0];
          cookies.set("gwd_default_company", defCompany);
          localStorage.setItem("activeCompany", defCompany);
        } else {
          if (!activeCompany) {
            state.activeCompany = state.companies[0];
          } else {
            state.activeCompany =
              state.companies.find((item) => item.uuid === activeCompany) ??
              state.companies[0];
          }
          cookies.set("gwd_default_company", state.activeCompany.uuid);
          localStorage.setItem("activeCompany", state.activeCompany.uuid);
        }
        state.can_access = state.activeCompany.can_access;
        state.isClient = false;
      } else {
        state.isClient = true;
      }
      state.retrieved = true;
    },
    clear(state) {
      state.companies = [];
      state.activeCompany = null;
      state.isClient = false;
      state.retrieved = false;
    },
    setUnactivatedAccount(state) {
      state.companies = [];
      state.activeCompany = null;
      state.isClient = true;
      state.retrieved = true;
    },
    closeTrialPopup(state) {
      state.trialPopupActive = false;
    },
    openTrialPopup(state) {
      state.trialPopupActive = true;
    },
    activateCompany(state, companyId) {
      state.activeCompany = state.companies.find(
        (item) => item.uuid === companyId
      );
      cookies.set("gwd_default_company", state.activeCompany.uuid);
      localStorage.setItem("activeCompany", state.activeCompany.uuid);
      router
        .push("/")
        .then((r) => {
          console.log(r);
        })
        .catch(() => {});
    },
  },
  actions: {
    clearCompanyState({ commit }) {
      commit("clear");
    },
    activateCompany({ commit, dispatch, state }, id) {
      commit("activateCompany", id);

      // Calendar?
      dispatch("timetracking/retrieveTimers", {}, { root: true });
      dispatch("companyVariables/retrieveWorkerAttributes", {}, { root: true });
      dispatch("companyVariables/retrieveWorkers", {}, { root: true });
      dispatch("companyVariables/retrieveTaskTags", {}, { root: true });
      dispatch("companyVariables/retrieveSavedLocations", {}, { root: true });

      if (
        ["R0", "R1", "R2", "R3"].includes(
          state.activeCompany.worker_permission
        ) ||
        ["R0", "R1", "R2"].includes(state.activeCompany.accounting_permission)
      ) {
        // Incoming invoice
        dispatch("companyVariables/retrieveCurrencies", {}, { root: true });
        dispatch("companyVariables/retrieveObjects", {}, { root: true });
        dispatch("companyVariables/retrieveCostAccounts", {}, { root: true });
        dispatch("companyVariables/retrieveTaxTypes", {}, { root: true });
        dispatch("providers/retrieveProviders", {}, { root: true });
        dispatch("templates/retrieveInvoiceTemplates", {}, { root: true });

        // Outgoing invoices
        dispatch("companyVariables/retrieveServiceObjects", {}, { root: true });
      }

      dispatch("pages/invoiceList/clearSearchQuery", {}, { root: true });
    },
    setUnactivatedAccount({ commit }) {
      commit("setUnactivatedAccount");
    },
    closeTrialPopup({ commit }) {
      commit("closeTrialPopup");
    },
    openTrialPopup({ commit }) {
      commit("openTrialPopup");
    },
    retrieveCompanyData({ rootState, commit }) {
      let defaultCompany;
      if (rootState.userData && rootState.userData.user)
        defaultCompany = rootState.userData.user.default_company;
      return new Promise((resolve, reject) => {
        const token = jwtdecode(localStorage.getItem("access")).user_id;

        StoreRequestHandler.apiRequest(
          `profiles/${token}/companies/`,
          "get",
          true
        )
          .then(
            async (res) => {
              commit("clear");
              commit("setCompanyData", {
                companies: res.data,
                defaultCompany: defaultCompany,
              });
              resolve(res);
            },
            (err) => {
              reject(err);
            }
          )
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
  getters: {
    companies: (state) => state.companies,
    isClient: (state) => state.companies.length <= 0,
    activeCompany: (state) => state.activeCompany,
    activeCompanyUuid: (state) => state.activeCompany.uuid ?? null,
    userPermission: (state) =>
      state.activeCompany ? state.activeCompany.worker_permission : null,
    userAccountingPermission: (state) =>
      state.activeCompany.accounting_permission,
    workerId: (state) => state.activeCompany.worker_id,
    workerInstance: (state) => state.activeCompany.worker_instance,
    exportFilename: (state) => state.activeCompany.export_file_name,
    defaultCoefficient: (state) => state.activeCompany.default_cost_coefficient,
    defaultServiceCode: (state) => state.activeCompany.default_service_code,
    receiveDateLimit: (state) =>
      state.activeCompany.earliest_invoice_received_date ?? null,
    isAdmin: (state) =>
      state.activeCompany
        ? ["R0", "R1"].includes(state.activeCompany.worker_permission)
        : false,
    isManager: (state) =>
      state.activeCompany
        ? ["R0", "R1", "R2", "R3"].includes(
            state.activeCompany.worker_permission
          )
        : false,
    elevatedWorker: (state) =>
      state.activeCompany
        ? state.activeCompany.worker_permission === "R4"
        : false,
    visibility: (state) =>
      state.activeCompany
        ? state.activeCompany.calendar_data
        : {
            self_made: false,
            client: true,
            title: true,
            start_address: true,
            end_address: false,
            times: true,
            project: false,
          },
  },
};
