var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full flex flex-col items-center chat-window h-full"},[(!_vm.messagesLoaded || !_vm.datesInited)?_c('div',{staticClass:"flex justify-center items-center h-full"},[_c('clip-loader',{attrs:{"size":"125px"}})],1):_vm._e(),(_vm.datesInited)?_c('div',{staticClass:"flex flex-col overflow-y-scroll w-full mb-auto mobile:py-3 px-3",class:_vm.messages.length === 0 ? 'my-auto' : '',attrs:{"id":"chat-window"},on:{"scroll":_vm.onScroll}},[_vm._l((_vm.messages),function(message,index){return _c('project-comment',{key:message.uuid,attrs:{"show-header":index > 0
          ? _vm.messages[index - 1].author.id !== message.author.id ||
            Date.parse(message.created_at) -
              Date.parse(_vm.messages[index - 1].created_at) >
              300000
          : true,"show-date-header":_vm.dateHeaderPoints.includes(message.uuid),"time-diff":index > 0
          ? Date.parse(message.created_at) -
            Date.parse(_vm.messages[index - 1].created_at)
          : 0,"comment":message,"company":_vm.companyId,"id":("comment-" + (message.uuid))}})}),(_vm.messages.length === 0)?_c('div',{staticClass:"p-5 font-medium shadow bg-offwhite rounded-gwdfull w-4/12 mobile:w-10/12 m-auto flex justify-center"},[_vm._v(" Selles vestluses pole sõnumeid saadetud! ")]):_vm._e()],2):_vm._e(),_c('div',{staticClass:"flex justify-center items-center relative w-full bg-offwhite py-3 rounded-br"},[_c('div',{staticClass:"flex w-full relative px-5 mobile:px-2 flex-col"},[_c('div',{staticClass:"flex relative"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.content),expression:"content"}],staticClass:"w-full h-full relative focus:shadow-outline bg-white shadow p-1 rounded",class:_vm.$v.$error && _vm.$v.content.$invalid
              ? 'shadow-dangeroutline focus:shadow-dangeroutline'
              : '',attrs:{"data-cy":"commentField","rows":"2"},domProps:{"value":(_vm.content)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.saveComment.apply(null, arguments)},"input":function($event){if($event.target.composing){ return; }_vm.content=$event.target.value}}}),_vm._v(" "),_c('span',{staticClass:"admin-comments-symbol-count absolute",class:_vm.symbolsRemaining < 0 ? 'text-danger' : ''},[_vm._v(_vm._s(_vm.symbolsRemaining))])])]),(_vm.fileUpload)?_c('div',{staticClass:"flex flex-row justify-between",class:_vm.fileList.length > 0
          ? 'justify-between items-end mt-2'
          : 'justify-end items-center'},[(_vm.uploadInProgress)?_c('div',{staticClass:"flex flex-grow items-center"},[_c('ClipLoader'),_c('span',{staticClass:"ml-4"},[_vm._v("Faile laetakse üles...")])],1):_vm._e(),(!_vm.uploadInProgress)?_c('div',{staticClass:"flex justify-end mobile:justify-between px-1 mt-2 ml-auto mobile:ml-0 mobile:w-full"},[_c('button',{class:_vm.displayFileUpload ? 'btn-danger' : 'btn-primary',on:{"click":function($event){_vm.displayFileUpload = !_vm.displayFileUpload}}},[_c('span',{staticClass:"typcn icon",class:_vm.displayFileUpload ? 'typcn-times' : 'typcn-document-add'}),_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.displayFileUpload ? "Tühista" : "Lisa faile"))])]),_c('button',{staticClass:"btn-primary ml-3 mobile:ml-0",on:{"click":_vm.saveComment}},[_c('span',{staticClass:"typcn typcn-chevron-right icon"}),_c('span',{staticClass:"label"},[_vm._v("Saada")])])]):_vm._e()]):_vm._e()]),(_vm.displayFileUpload)?_c('div',{staticClass:"flex mt-2 items-center justify-center"},[_c('file-selector',{ref:"fileSelector",staticClass:"bg-white rounded shadow",on:{"filesUpdated":function($event){_vm.fileList = $event}}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }