<template>
  <div class="modal">
    <div class="modal-body modal-small">
      <div class="modal-content">
        <div class="w-full mx-auto flex flex-col items-center p-2">
          <h3 class="mb-2">Kinnitus</h3>
          <span>{{ text }}</span>
        </div>
      </div>
      <div class="modal-bottom-bar justify-around">
        <button @click="confirmDelete()" class="btn-danger">
          <span class="icon typcn typcn-tick"></span>
          <span class="label">Jah</span>
        </button>
        <button @click="closeModal()" class="btn-primary">
          <span class="icon typcn typcn-times"></span>
          <span class="label">Ei</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: "Olete kindel, et soovite kustutada?",
    },
    item: {
      type: Number,
      default: null,
    },
  },
  methods: {
    confirmDelete() {
      this.$emit("confirmDelete");
      this.closeModal();
    },
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>
