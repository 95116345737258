<template>
  <div class="flex w-3/4 flex-row">
    <div class="flex flex-col w-full mr-2">
      <div class="flex flex-col mb-2" v-if="!selectedProvider">
        <gwd-formlabel title="Vali hankija"></gwd-formlabel>
        <select-search
          :dataset="suppliers"
          :label="(item) => item.name"
          class="flex-grow"
          v-model="selectedProvider"
          v-if="!selectedProvider"
          :tabindex="1"
          :focus="true"
          :filter-func="filterProviders"
          ref="providerSelect"
          @input="handleProviderSelection"
          @queryUpdate="listSupplierDebounce"
        />
      </div>
      <div class="flex flex-col mb-2" v-if="selectedProvider">
        <gwd-formlabel title="Valitud hankija" />
        <div class="flex flex-row justify-between">
          <span>{{ selectedProvider.name }}</span>
          <button
            v-if="!planningCost"
            @click="
              () => {
                selectedProvider = null;
              }
            "
            class="btn-primary ml-2"
            tabindex="1"
          >
            <span class="label">Muuda</span>
          </button>
        </div>
      </div>
      <div class="flex flex-col mb-2">
        <gwd-formlabel
          :title="'Teenuse nimi'"
          :required="true"
          :valid="$v.supplier_cost.name.required"
        />
        <select-search
          v-if="
            !isPlanning &&
            selectedProvider &&
            supplierRelatedItems.previous_costs &&
            supplierRelatedItems.previous_costs.length > 0 &&
            !costSelected
          "
          :dataset="supplierRelatedItems.previous_costs"
          :label="(item) => item.name"
          class="flex-grow mb-2"
          v-model="relatedProviderCost"
          :tabindex="1"
          :show-on-focus="true"
          :filter-func="filterCosts"
          ref="providerSelect"
          @input="handleCostSelected"
          :placeholder="'Otsi eelnevate kulude seast'"
        />
        <input v-model="supplier_cost.name" placeholder="Alusta kirjutamist" />
      </div>

      <div class="flex flex-col">
        <div class="flex gap-x-2 mobile:gap-y-4 flex-col gap-y-1 flex-1 mb-4">
          <div class="gap-x-3 items-center triple-field">
            <gwd-formlabel title="Kulu" />
            <gwd-formlabel title="Kogus" />
            <gwd-formlabel title="Ühik" />
          </div>
          <div class="gap-x-3 items-center triple-field">
            <decimal-input v-model.number="supplier_cost.cost" />
            <decimal-input v-model="supplier_cost.amount" />
            <select class="add-offer-input mb-2" v-model="supplier_cost.unit">
              <option v-for="unit in units" :value="unit" :key="unit">
                {{ unit }}
              </option>
            </select>
          </div>
        </div>
        <div class="flex gap-x-2 mobile:gap-y-4 flex-col gap-y-1 flex-1 mb-4">
          <div class="gap-x-3 items-center triple-field">
            <gwd-formlabel title="Müügihind" />
            <gwd-formlabel title="Käibemaks" />
            <gwd-formlabel title="Koguhind" />
          </div>
          <div class="gap-x-3 items-center triple-field">
            <decimal-input v-model.number="supplier_cost.price" />
            <select
              class="max-w-full w-full"
              v-model="supplier_cost.vatType"
              @change="updateKey++"
            >
              <option
                v-for="taxType in taxTypes.filter((x) => x.incoming_tax_code)"
                :key="taxType.id"
                :value="taxType"
              >
                {{ `${taxType.description}` }}
              </option>
            </select>
            <div :key="updateKey">
              <span>{{ priceWithVat(supplier_cost) }} € </span>
            </div>
          </div>
        </div>
        <div
          class="flex flex-col"
          v-if="
            !isPlanning &&
            isAdmin &&
            selectedProvider &&
            supplierRelatedItemsLoaded &&
            supplierRelatedItems.related_invoices.length > 0
          "
        >
          <gwd-formlabel v-if="selectedProvider" :title="'Arve (valikuline)'" />
          <div class="gwd-table" v-if="!selectedInvoice">
            <div class="gwd-table-header supplier-cost-invoice-table nopad">
              <span />
              <span>Arve number</span>
              <span>Järjekorranumber</span>
              <span>Summa</span>
              <span>Kuupäev</span>
              <span>Kinnitatud</span>
            </div>
            <div
              v-for="invoice in supplierRelatedItems.related_invoices"
              :key="invoice.id"
              class="gwd-table-row-wrapper"
              :class="selectedInvoice === invoice ? 'bg-offwhite' : ''"
            >
              <div
                class="gwd-table-row supplier-cost-invoice-table nopad cursor-pointer"
                @click="selectInvoice(invoice)"
              >
                <span
                  class="typcn"
                  :class="
                    selectedInvoice && selectedInvoice.id === invoice.id
                      ? 'typcn-plus'
                      : 'typcn-times'
                  "
                />
                <span> {{ invoice.invoice_nr }}</span>
                <span> {{ invoice.queue_number }}</span>
                <span> {{ invoice.total_sum }}€</span>
                <span>{{
                  moment(invoice.invoice_date).format("DD.MM.YYYY")
                }}</span>
                <div
                  class="w-6 h-6 flex items-center justify-center rounded-full"
                  :class="invoice.manually_checked ? 'bg-primary' : 'bg-danger'"
                >
                  <span
                    class="typcn text-white"
                    :class="
                      invoice.manually_checked ? 'typcn-tick' : 'typcn-times'
                    "
                  />
                </div>
              </div>
              <div class="flex flex-col w-full">
                <div
                  v-for="cost in invoice.costs"
                  :key="cost.id"
                  class="flex flex-row items-center w-full justify-between mb-2 cursor-pointer"
                  @click="selectInvoiceCost(cost)"
                >
                  <span> {{ cost.program_name }}</span>
                  <span> {{ cost.sum }}</span>
                  <span> {{ cost.tax_code }}</span>
                  <span> {{ cost.accounting_description }}</span>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="flex flex-col">
            <span>Valitud arve:</span>
            <div class="flex flex-row mb-2 p-1 supplier-cost-invoice-table">
              <span> {{ selectedInvoice.invoice_nr }}</span>
              <span> {{ selectedInvoice.queue_number }}</span>
              <span> {{ selectedInvoice.total_sum }}€</span>
              <span>{{
                moment(selectedInvoice.invoice_date).format("DD.MM.YYYY")
              }}</span>
              <div
                class="w-6 h-6 flex items-center justify-center rounded-full"
                :class="
                  selectedInvoice.manually_checked ? 'bg-primary' : 'bg-danger'
                "
              >
                <span
                  class="typcn text-white"
                  :class="
                    selectedInvoice.manually_checked
                      ? 'typcn-tick'
                      : 'typcn-times'
                  "
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import SelectSearch from "@/components/reusable/SelectSearch.vue";
import { filterProviders } from "@/assets/utils/commonFilters";
import DecimalInput from "../../reusable/DecimalInput.vue";
import { required } from "vuelidate/lib/validators";
import { priceWithVat } from "@/assets/utils/commonMath";
import debounce from "lodash/debounce";

export default {
  name: "AddSupplierCost",
  components: { SelectSearch, DecimalInput },
  props: {
    project: {
      type: String,
      default: null,
    },
    supplier: {
      type: Object,
      default: () => {
        return null;
      },
    },
    task: {
      type: Number,
      default: null,
    },
    isPlanning: {
      type: Boolean,
      default: false,
    },
    taxTypes: {
      type: Array,
      default: () => {
        return [];
      },
    },
    planningCost: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      selectedProvider: null,
      relatedProviderCost: "",
      costSelected: false,
      supplier_cost: {
        invoice: null,
        invoice_cost_item: null,
        name: "",
        amount: 0,
        unit: "",
        cost: 0.0,
        price: 0.0,
        vat: null,
        project: null,
        task: null,
      },
      supplierRelatedItemsLoaded: false,
      supplierRelatedItems: [],
      selectedInvoice: null,
      selectedInvoiceCost: null,
      updateKey: 1,
      suppliers: [],
      moment: moment,
    };
  },
  methods: {
    priceWithVat,
    filterProviders,
    listSupplierDebounce: debounce(function (query) {
      if (query.length < 3) return;
      this.listProvidersWithFilter(query);
    }, 500),
    listProvidersWithFilter(query) {
      this.suppliers = [];
      this.apiRequest(
        `suppliers/${this.companyId}/?name=${query}`,
        "get",
        true
      ).then((res) => {
        this.suppliers = res.data.results;
      });
    },
    filterCosts(cost, query) {
      return cost.name.toLowerCase().includes(query.toLowerCase());
    },
    handleProviderSelection(e) {
      this.selectedProvider = e;
      // TODO
      // Rewrite supplier tax code to vatType id not accounting code
      if (e.default_tax_code)
        this.supplier_cost.vatType = this.taxTypes.find(
          (x) => x.accounting_code === e.default_tax_code
        );
      if (e) {
        this.loadSupplierRelatedItems();
      } else {
        this.supplierRelatedItemsLoaded = false;
      }
    },
    handleCostSelected(e) {
      this.supplier_cost.name = e.name;
      this.supplier_cost.cost = e.cost;
      this.supplier_cost.price = e.price;
      this.supplier_cost.amount = e.amount;
      this.supplier_cost.unit = e.unit;
      this.supplier_cost.vatType = e.vatType;
      this.costSelected = true;
    },
    saveSupplierCost() {
      if (
        !this.$v.$invalid &&
        this.selectedProvider &&
        Object.keys(this.selectedProvider).length > 0
      ) {
        this.supplier_cost.supplier = this.selectedProvider.id;
        this.supplier_cost.project = this.project;
        this.supplier_cost.task = this.task;
        if (!this.supplier_cost.name || this.supplier_cost.name.length === 0) {
          this.supplier_cost.name = this.relatedProviderCost;
        }
        if (this.selectedInvoice) {
          this.supplier_cost.invoice = this.selectedInvoice.id;
          if (this.selectedInvoiceCost) {
            this.supplier_cost.invoice_cost_item = this.selectedInvoiceCost.id;
          }
        }
        if (this.supplier_cost.vatType)
          this.supplier_cost.vat = this.supplier_cost.vatType.id;
        let url;
        if (this.isPlanning) {
          url = `template/${this.companyId}/plannings/supplier/create/`;
        } else {
          url = `costs/${this.companyId}/supplier/costs/add/`;
        }
        this.apiRequest(url, "post", true, this.supplier_cost).then((res) => {
          if (res.status === 201) {
            this.$emit("supplierCostAdded");
          }
        });
      }
    },
    loadSupplierRelatedItems() {
      if (
        this.selectedProvider &&
        Object.keys(this.selectedProvider).length > 0
      ) {
        this.apiRequest(
          `suppliers/${this.companyId}/${this.selectedProvider.id}/related/`,
          "get",
          true
        ).then((res) => {
          this.supplierRelatedItems = res.data;
          this.supplierRelatedItemsLoaded = true;
        });
      }
    },
    selectInvoice(e) {
      this.selectedInvoice = e;
    },
    selectInvoiceCost(e) {
      this.selectedInvoiceCost = e;
    },
  },
  mounted() {
    if (this.supplier) {
      this.selectedProvider = this.supplier;
      this.loadSupplierRelatedItems();
    }
    if (this.planningCost) {
      this.selectedProvider = this.planningCost.supplier;
      this.handleCostSelected(this.planningCost);
    }
  },
  watch: {
    supplier_cost: {
      deep: true,
      handler() {
        this.$emit("formUpdated", !this.$v.$invalid);
      },
    },
  },
  computed: {
    ...mapGetters({
      companyId: "companyData/activeCompanyUuid",
      units: "companyVariables/units",
      isAdmin: "companyData/isAdmin",
    }),
  },
  validations: {
    supplier_cost: {
      name: { required },
    },
  },
};
</script>
<style lang="scss" scoped>
.triple-field {
  @apply grid;
  grid-template-columns: 0.35fr 0.35fr 0.3fr;
}
.supplier-cost-invoice-table {
  @apply grid;
  grid-template-columns: 0.1fr 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr;
}
</style>
