<template>
  <div class="modal" :data-modal-id="key">
    <div class="modal-body" v-on-clickaway="() => closeModal()">
      <div class="modal-header">
        <div class="modal-title">
          <h3>Vali tellimus</h3>
        </div>
        <div class="flex justify-end w-2/3 px-3">
          <div
            class="items-center flex cursor-pointer hover:bg-offwhite hover:bg-opacity-50 duration-50 relative"
            @click="menuActive = !menuActive"
            :class="menuActive ? 'rounded-t-xl' : 'rounded-xl'"
          >
            <span
              v-if="statusFilter"
              v-html="parseProjectStatus(statusFilter.status)"
            />
            <span v-if="!statusFilter" class="pl-4 pr-8 py-2"
              >Filtreeri staatuse järgi</span
            >
            <span class="typcn typcn-arrow-sorted-down mx-3"></span>
            <div class="status-menu" v-if="menuActive">
              <span
                v-for="status in statusFilters"
                :key="status.status"
                class="status-menu-item cursor-pointer"
                v-html="parseProjectStatus(status.status)"
                @click.stop="selectStatus(status)"
              />
            </div>
          </div>
          <div class="ml-2">
            <button
              class="btn-danger"
              v-if="statusFilter"
              @click="selectStatus(null)"
            >
              <span class="icon">
                <img
                  src="/bc21/trash.svg"
                  class="h-5 filter-to-white"
                  alt="Delete icon"
                />
              </span>
              <span class="label">Tühjenda</span>
            </button>
          </div>
          <search-input
            @input="
              (e) => {
                searchQuery = e;
                debounceSearch();
              }
            "
            background-color="white"
          />
        </div>
      </div>
      <div class="modal-content flex-col">
        <div v-if="projectsLoaded" class="data-container">
          <div class="data-container-content data-container-table">
            <div class="gwd-table nested borderless">
              <div class="gwd-table-header task-project-selector">
                <span>Staatus</span>
                <span>Tellimus</span>
                <span>Klient</span>
                <span>Asukoht</span>
                <span />
              </div>
              <task-project
                v-for="(item, index) in projects.results"
                :key="item.uuid"
                :project="item"
                :is-first="index === 0"
                :selected-project="
                  selectedProject ? selectedProject.uuid : null
                "
                @projectSelected="toggleSelectedProject"
              />
            </div>

            <span v-if="projects.results.length === 0" class="p-2"
              >Selle staatusega tellimusi pole</span
            >
          </div>

          <page-changer
            class="self-center mt-4 mobile:justify-center"
            v-if="projects.count > 1"
            :page="page"
            :count="projects.count"
            @pageChange="pageChange"
          />
        </div>
      </div>
      <div
        v-if="selectedProject && selectedProject.client_warning"
        class="px-3 py-1 border border-danger"
      >
        <span class="text-danger">
          Hoiatus: {{ selectedProject.client_warning }}
        </span>
      </div>
      <div class="modal-bottom-bar">
        <button
          @click="selectedProject ? saveProject() : ''"
          class="mr-3"
          :class="selectedProject ? 'btn-primary' : 'btn-disabled'"
        >
          <span class="icon typcn typcn-tick" />
          <span class="label">{{ confirmButtonText }}</span>
        </button>
        <button class="btn-danger" @click="closeModal">
          <span class="icon typcn typcn-times" />
          <span class="label">Sulge</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { RequestHandler, StatusParser } from "@/assets/mixins/GewodoMixins";
import { generateModalKey } from "@/assets/utils/commonMath";
import debounce from "lodash/debounce";
import PageChanger from "@/components/reusable/PageChanger";
import TaskProject from "./TaskProject";
import SearchInput from "../reusable/SearchInput.vue";

const statusFilters = [
  {
    text: "Ootel",
    status: 1,
  },
  {
    text: "Aktiivne",
    status: 2,
  },
  {
    text: "Käigus",
    status: 3,
  },
  {
    text: "Probleem",
    status: 4,
  },
  {
    text: "Lõpetatud",
    status: 5,
  },
  {
    text: "Kinnitatud",
    status: 6,
  },
];
export default {
  name: "ProjectSelector",
  components: { TaskProject, PageChanger, SearchInput },
  mixins: [RequestHandler, StatusParser],
  props: {
    task: { type: Number, default: null },
    convert: { type: Boolean, default: true },
    confirmButtonText: { type: String, default: "Salvesta" },
  },
  data() {
    return {
      projectsLoaded: false,
      projects: [],
      page: 1,
      searchQuery: "",
      focusSearch: false,
      statusFilter: null,
      statusFilters: [],
      menuActive: false,
      selectedProject: null,
      key: null,
    };
  },
  methods: {
    generateModalKey,
    toggleSelectedProject(project) {
      if (!this.selectedProject) {
        this.selectedProject = project;
      } else if (this.selectedProject.uuid === project.uuid) {
        this.selectedProject = null;
      } else {
        this.selectedProject = project;
      }
    },
    pageChange(e) {
      this.page = e;
      this.retrieveProjects();
    },
    selectStatus(status) {
      this.statusFilter = status;
      this.menuActive = false;
      this.selectedProject = null;
      this.retrieveProjects();
    },
    retrieveProjects() {
      this.projectsLoaded = false;
      this.apiRequest(
        `notes/${this.$store.state.companyData.activeCompany.uuid}/drafts/?page_size=12&page=` +
          this.page +
          (this.searchQuery ? "&s=" + this.searchQuery : "") +
          (this.statusFilter ? "&state=" + this.statusFilter.status : ""),
        "get",
        true
      ).then((res) => {
        this.projects = res.data;
        this.projectsLoaded = true;
      });
    },
    closeModal() {
      this.$emit("closeModal");
    },
    debounceSearch: debounce(function () {
      this.retrieveProjects();
    }, 500),
    saveProject() {
      if (this.convert) {
        this.apiRequest(
          `tasks/${this.task}/convert/${this.selectedProject.uuid}/`,
          "post",
          true
        ).then((res) => {
          if (res.status === 200) {
            this.$emit("closeModal");
            this.$store.dispatch("modals/taskModal/saveTask", res.data.task_id);
          }
        });
      } else {
        this.$emit("projectAdded", this.selectedProject);
        this.$emit("closeModal");
      }
    },
  },
  mounted() {
    this.statusFilters = statusFilters;
    this.key = this.generateModalKey();
    this.retrieveProjects();
  },
};
</script>
<style lang="scss">
.task-project-selector {
  grid-template-columns: 0.1fr 0.2fr 0.2fr 0.4fr 0.1fr;
}
</style>
