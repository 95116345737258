import { render, staticRenderFns } from "./TaskWorkerTimes.vue?vue&type=template&id=631ea501&scoped=true&"
import script from "./TaskWorkerTimes.vue?vue&type=script&lang=js&"
export * from "./TaskWorkerTimes.vue?vue&type=script&lang=js&"
import style0 from "./TaskWorkerTimes.vue?vue&type=style&index=0&id=631ea501&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "631ea501",
  null
  
)

export default component.exports