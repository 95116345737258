import jwtdecode from "jwt-decode";
import StoreRequestHandler from "../../assets/mixins/StoreRequestHandler";
import { EventBus } from "@/assets/mixins/GewodoMixins";
import router from "@/router";

const initState = {
  unread: [],
  notificationsLoaded: false,
  websocketTried: false,
};

export const notifications = {
  namespaced: true,
  state: initState,
  mutations: {
    setUnreadNotifications(state, data) {
      state.unread = data;
      state.notificationsLoaded = true;
    },
    appendUnreadNotification(state, data) {
      state.unread.push(data);
      state.notificationsLoaded = true;
    },
    setReadNotifications(state, data) {
      state.read = data;
    },
    clearNotificationState(state) {
      state.unread = [];
      state.notificationsLoaded = false;
    },
    appendNotification(state, data) {
      state.unread.push(data);
    },
    setWebsocketTriedTrue(state) {
      state.websocketTried = true;
    },
  },
  actions: {
    retrieveNotifications({ commit }) {
      StoreRequestHandler.apiRequest(
        `notifications/${
          jwtdecode(localStorage.getItem("access")).user_id
        }/unread/`,
        "get",
        true
      ).then((res) => {
        commit("setUnreadNotifications", res.data);
      });
    },
    readNotification({ dispatch }, data) {
      StoreRequestHandler.apiRequest(
        `notifications/${data}/read/`,
        "put",
        true,
        data
      ).then((res) => {
        if (res.status === 200) {
          dispatch("retrieveNotifications");
        }
      });
    },
    readAllUnread({ dispatch }) {
      StoreRequestHandler.apiRequest(
        `notifications/${
          jwtdecode(localStorage.getItem("access")).user_id
        }/all/read/`,
        "put",
        true
      ).then(() => {
        dispatch("retrieveNotifications");
      });
    },
    clearNotificationState({ commit }) {
      commit("clearNotificationState");
    },
    initAppNotificationWebsocket({ rootState, commit, dispatch }) {
      return new Promise((resolve) => {
        StoreRequestHandler.apiRequest(`chat/token/`, "post", true)
          .then((res) => {
            const socket = new WebSocket(
              `${process.env.VUE_APP_WS_URL}profile/${res.data.token}/`
            );
            socket.addEventListener("message", (e) => {
              const data = JSON.parse(e.data);
              switch (data.type) {
                case "chat_message": {
                  dispatch("chat/appendChatNotification", data.message, {
                    root: true,
                  });
                  break;
                }
                case "update": {
                  if (router.currentRoute.name === data.message.target) {
                    EventBus.$emit("websocketUpdate", data.message);
                  }
                  break;
                }
                case "store": {
                  if (
                    data.message.company_id !==
                    rootState.companyData.activeCompany.uuid
                  )
                    return;
                  if (
                    !["R0", "R1", "R2", "R3"].includes(
                      rootState.companyData.activeCompany.worker_permission
                    ) &&
                    !["R0", "R1", "R2"].includes(
                      rootState.companyData.activeCompany.accounting_permissions
                    )
                  ) {
                    if (data.message.target === "saved_locations") {
                      dispatch(
                        "companyVariables/retrieveSavedLocations",
                        {},
                        { root: true }
                      );
                      return;
                    }
                  }
                  switch (data.message.target) {
                    case "tax_types": {
                      dispatch(
                        "companyVariables/retrieveTaxTypes",
                        {},
                        { root: true }
                      );

                      break;
                    }
                    case "program_codes": {
                      dispatch(
                        "companyVariables/retrieveCostAccounts",
                        {},
                        { root: true }
                      );
                      break;
                    }
                    case "service_codes": {
                      dispatch(
                        "companyVariables/retrieveServiceObjects",
                        {},
                        { root: true }
                      );
                      break;
                    }
                    case "saved_locations": {
                      dispatch(
                        "companyVariables/retrieveSavedLocations",
                        {},
                        { root: true }
                      );
                      break;
                    }
                    case "worker_attributes": {
                      dispatch(
                        "companyVariables/retrieveWorkerAttributes",
                        {},
                        { root: true }
                      );
                      break;
                    }
                  }
                  break;
                }
                default: {
                  commit("appendNotification", data.message);
                  break;
                }
              }
            });
            commit("setWebsocketTriedTrue");
            resolve();
          })
          .catch(() => {
            commit("setWebsocketTriedTrue");
            resolve();
          });
      });
    },
  },
  getters: {
    unreadNotifications: (state) => state.unread,
  },
};
