<template>
  <div class="flex w-full items-center" @click="toggleStatus">
    <div class="w-4/12 mobile:w-1/2 flex justify-start items-center truncate">
      <div class="mr-3">
        <span class="typcn typcn-user table-icon" />
      </div>
      <span class="truncate w-1/2">{{ data.worker_name }}</span>
    </div>
    <div class="w-7/12 flex flex-wrap gap-x-2 gap-y-1">
      <span
        class="flex bg-primary text-white rounded text-sm font-medium p-1 gap-x-1 items-center"
        v-for="equipment in data.equipment"
        :key="`equipment${equipment.id}`"
      >
        <img
          src="/icons/tehnika.svg"
          class="w-5 h-5 filter-to-white"
          alt="Equipment icon"
        />
        {{ equipment.name }}
      </span>
      <span
        v-for="attribute in data.attributes"
        :key="`att${attribute.id}`"
        class="flex bg-primary text-white rounded text-sm font-medium p-1"
      >
        {{ attribute.name }}
      </span>
    </div>
    <div
      class="w-1/12 items-center flex mobile:order-4"
      :class="[
        isBusy
          ? cautionHovered
            ? buttonFaded
              ? 'justify-start'
              : 'justify-between'
            : 'justify-between'
          : 'justify-end',
      ]"
    >
      <div
        class="flex items-center justify-start duration-150 w-auto"
        v-if="isBusy"
      >
        <div
          v-if="!cautionHovered && busyTextFaded"
          class="text-white rounded-xl leading-none w-8 h-8 flex justify-center items-center font-extrabold cursor-default mr-3"
          :class="cautionHovered ? 'bg-danger-dark' : 'bg-danger'"
          @mouseenter="cautionHovered = true"
          @mouseleave="cautionHovered = false"
        >
          !
        </div>
        <transition
          name="fade"
          :duration="{ enter: 300, leave: 100 }"
          @before-enter="busyTextFaded = false"
          @after-leave="busyTextFaded = true"
        >
          <span
            v-if="cautionHovered && buttonFaded"
            class="text-sm py-1"
            @mouseenter="cautionHovered = true"
            @mouseleave="cautionHovered = false"
            >Töötaja on valitud ajal teisele tööle määratud</span
          >
        </transition>
      </div>
      <transition
        name="fade"
        :duration="{ enter: 300, leave: 100 }"
        @before-enter="buttonFaded = false"
        @after-leave="buttonFaded = true"
      >
        <button
          v-if="!cautionHovered && busyTextFaded"
          :class="isSelected ? 'btn-danger' : 'btn-primary'"
          class="h-8"
          @mouseover="isHovered = true"
          @mouseleave="isHovered = false"
        >
          <div
            class="transform duration-150 rounded-gwdfull icon items-center justify-center flex"
            :class="isSelected ? 'rotate-45' : ''"
          >
            <span
              class="typcn typcn-plus text-2xl leading-none transform text-white"
            ></span>
          </div>
        </button>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isSelected: Boolean,
    isBusy: Boolean,
  },
  data() {
    return {
      isHovered: false,
      cautionHovered: false,
      buttonFaded: false,
      busyTextFaded: true,
    };
  },
  methods: {
    toggleStatus() {
      this.$emit("toggleSelect", this.data);
    },
  },
};
</script>

<style lang="scss">
.worker-card-tooltip {
  position: absolute;
  //bottom: 2%;
  width: 100%;
  right: 0;
  height: 100%;
  @apply bg-white z-10 px-6 py-4 rounded-xl flex items-center;
}
</style>
