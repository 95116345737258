var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.taskExtraCostsLoaded)?_c('div',{staticClass:"gwd-table nested h-full"},[_c('div',{staticClass:"gwd-table-header"},[_vm._v("Lisatööd")]),_c('div',{staticClass:"gwd-table-header",class:_vm.isAdmin || _vm.isManager ? 'extra-cost-table' : 'extra-cost-table-worker'},[_c('span',{staticClass:"mobile:hidden"},[_vm._v("Töötaja")]),_c('span',[_vm._v("Kirjeldus")]),_c('span',[_vm._v("Kogus")]),_c('span',[_vm._v(_vm._s(_vm.isManager ? "Kulu" : "Ühiku hind"))]),(_vm.isAdmin || _vm.isManager)?_c('span',[_vm._v("Koefitsent")]):_vm._e(),(_vm.isAdmin || _vm.isManager)?_c('span',[_vm._v("Kulu kokku")]):_vm._e(),(_vm.isAdmin || _vm.isManager)?_c('span',[_vm._v("Müügihind")]):_vm._e(),(_vm.isAdmin || _vm.isManager)?_c('span',[_vm._v("KM")]):_vm._e(),_c('span',[_vm._v("Lisainfo")]),_c('span',[_vm._v("Kokku")]),_c('span',[_vm._v("Kinnitatud")])]),_c('div',{staticClass:"gwd-table-row-wrapper mobile:overflow-x-hidden"},[_vm._l((_vm.extraWorkPlannings),function(extraCostPlanning){return _c('extra-cost-planning',{key:extraCostPlanning.id,attrs:{"extra-cost-planning":extraCostPlanning},on:{"deletePlanningCost":function (planning) { return (_vm.planningToDelete = planning); },"updatePlanningCost":_vm.updatePlanningCost}})}),(_vm.extraWorkPlannings.length > 0)?_c('div',{staticClass:"gwd-table-header nopad"}):_vm._e(),_vm._l((_vm.taskExtraCosts),function(extraCost){return _c('extra-cost',{key:extraCost.id,class:extraCost.added_by.id !==
        (extraCost.worker ? extraCost.worker.id : _vm.workerId)
          ? 'bg-danger-dark/25'
          : extraCost.editions && extraCost.editions.length > 0
          ? 'bg-attention/25'
          : '',attrs:{"extra-cost":extraCost,"tax-types":_vm.taxTypes,"is-admin":_vm.isAdmin,"is-manager":_vm.isManager},on:{"confirmExtraCost":_vm.confirmWorkerExtraCost,"editCost":_vm.editCost,"deleteCost":_vm.deleteCost}})}),(_vm.taskExtraCosts.length === 0 && _vm.extraWorkPlannings.length === 0)?_c('span',{staticClass:"p-2"},[_vm._v(" Määratud lisatöid pole. ")]):_vm._e()],2),_c('div',{staticClass:"gwd-table-bottom-bar"},[(['R0', 'R1', 'R2'].includes(_vm.userPermissions))?_c('button',{staticClass:"btn-primary mr-3",on:{"click":function($event){return _vm.$store.commit('modals/taskModal/openAddExtraCostModal', true)}}},[_vm._v(" Planeeri lisatöö ")]):_vm._e(),(
        _vm.isAdmin ||
        _vm.isManager ||
        (['R4', 'R5'].includes(_vm.userPermissions) &&
          _vm.moment(_vm.taskTime.end).diff(_vm.moment(), 'hours') >= -12 &&
          !_vm.taskConfirmed)
      )?_c('button',{staticClass:"btn-primary",on:{"click":function($event){return _vm.$store.commit('modals/taskModal/openAddExtraCostModal', false)}}},[_c('span',{staticClass:"label"},[_vm._v("Lisa kulu")])]):_vm._e()]),(_vm.planningToDelete)?_c('delete-confirm-modal',{on:{"confirmDelete":_vm.deletePlannedCost,"closeModal":function($event){_vm.planningToDelete = null}}}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }