<template>
  <div
    class="gwd-dateselector"
    :class="[
      showDatePicker ? 'datepicker-open' : '',
      error.show ? 'ring-2 ring-danger' : '',
      `bg-${backgroundColor}`,
      shadow ? 'shadow' : '',
      padded ? 'pl-1' : '',
    ]"
    v-if="year !== null && month !== null"
    v-on-clickaway="() => setDatepickerState(false)"
    ref="wrapper"
  >
    <div class="flex w-full items-center justify-between">
      <input
        type="text"
        ref="customInput"
        class="date-selector-custom-input flex-grow"
        :class="[`bg-${backgroundColor}`, bigText ? 'text-xl' : '']"
        placeholder="DD.MM.YYYY"
        @focus="setDatepickerState(true)"
        v-model="customDateInput"
        :tabindex="tabindex"
      />
      <button
        class="h-full w-6 bg-primary flex items-center justify-center rounded shadow hover:bg-primary-dark"
        @click="setDatepickerState(!showDatePicker, $event)"
      >
        <img
          src="/bc21/calendar.svg"
          class="h-3 w-3 filter-to-white"
          alt="Datepicker icon"
        />
      </button>
    </div>
    <div
      class="gwd-datepicker"
      v-if="showDatePicker"
      ref="datepicker"
      :style="positionComp"
    >
      <div class="flex justify-between bg-offwhite rounded-t">
        <div
          class="w-2/12 flex justify-center py-2 hover:bg-offwhite-dark rounded-tl cursor-pointer"
          @click="decrementMonth"
        >
          &lt;
        </div>
        <div class="w-8/12 flex justify-around font-medium">
          <span
            class="flex-grow justify-center flex hover:bg-offwhite-dark h-full items-center cursor-pointer"
            @click="
              () => {
                if (showYearPicker) showYearPicker = false;
                showMonthPicker = true;
              }
            "
          >
            {{ getMonthName(month) }}
          </span>
          <span
            class="flex-grow justify-center flex h-full items-center hover:bg-offwhite-dark cursor-pointer"
            @click="
              () => {
                if (showMonthPicker) showMonthPicker = false;
                showYearPicker = true;
              }
            "
          >
            {{ year }}
          </span>
        </div>
        <div
          class="w-2/12 flex justify-center py-2 hover:bg-offwhite-dark rounded-tr cursor-pointer"
          @click="incrementMonth"
        >
          >
        </div>
      </div>
      <div class="grid grid-cols-7 h-60 relative">
        <div
          v-for="(day, index) in days"
          :key="index"
          class="flex justify-center items-center flex-grow hover:bg-offwhite"
          :class="[
            moment(day.fullDate).isSame(moment(value), 'day')
              ? 'border-b-2 border-primary bg-primary bg-opacity-50'
              : '',
            moment(day.fullDate).isSame(moment(), 'day')
              ? 'bg-primary bg-opacity-5'
              : null,
            day.disabled
              ? 'text-offwhite-dark'
              : day.isOffset
              ? 'cursor-pointer text-bordergrey'
              : 'cursor-pointer',
            days.length === 42 && day.index === 42 ? 'rounded-br' : '',
            days.length > 35 && day.index === 36 ? 'rounded-bl' : '',
            days.length === 35 && day.index === 35 ? 'rounded-br' : '',
            days.length < 36 && days.length > 28 && day.index === 29
              ? 'rounded-bl'
              : '',
          ]"
          @click="day.disabled ? () => {} : selectDate(moment(day.fullDate))"
        >
          {{ day.date }}
        </div>
        <div class="monthpicker datepicker-overlay" v-if="showMonthPicker">
          <div @click="setMonth(0)">{{ getMonthName(0) }}</div>
          <div @click="setMonth(1)">{{ getMonthName(1) }}</div>
          <div @click="setMonth(2)">{{ getMonthName(2) }}</div>
          <div @click="setMonth(3)">{{ getMonthName(3) }}</div>
          <div @click="setMonth(4)">{{ getMonthName(4) }}</div>
          <div @click="setMonth(5)">{{ getMonthName(5) }}</div>
          <div @click="setMonth(6)">{{ getMonthName(6) }}</div>
          <div @click="setMonth(7)">{{ getMonthName(7) }}</div>
          <div @click="setMonth(8)">{{ getMonthName(8) }}</div>
          <div @click="setMonth(9)">{{ getMonthName(9) }}</div>
          <div @click="setMonth(10)">{{ getMonthName(10) }}</div>
          <div @click="setMonth(11)">{{ getMonthName(11) }}</div>
        </div>
        <div class="yearpicker datepicker-overlay" v-if="showYearPicker">
          <div v-for="item in yearPickerArr" @click="setYear(item)" :key="item">
            {{ item }}
          </div>
        </div>
      </div>
      <div
        class="flex p-2 bg-danger rounded-b text-white text-sm text-medium"
        v-if="error.show"
      >
        {{ error.message }}
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import CalendarGenerator from "@/assets/mixins/CalendarGenerator";
export default {
  props: {
    value: { type: Object, default: null },
    disabledBefore: { type: Object, default: null },
    disabledAfter: { type: Object, default: null },
    disabledDates: { type: Array, default: () => [] },
    tabindex: { type: Number, default: null },
    backgroundColor: { type: String, default: "white" },
    shadow: { type: Boolean, default: false },
    padded: { type: Boolean, default: false },
    pos: { type: String, default: "br" },
    offset: { type: Number, default: 10 },
    bigText: { type: Boolean, default: false },
  },
  data() {
    return {
      year: null,
      month: null,
      days: null,
      showDatePicker: false,
      showMonthPicker: false,
      showYearPicker: false,
      yearPickerCenter: null,
      customDateInput: "",
      moment: moment,
      error: {
        show: false,
        message: "",
      },
    };
  },
  mixins: [CalendarGenerator],
  mounted() {
    this.resetDate();
  },
  beforeDestroy() {},
  methods: {
    selectDate(date, closePicker = true) {
      this.customDateInput = moment(date).format("DD.MM.YYYY");
      this.$emit("input", date);
      if (closePicker) this.showDatePicker = false;
    },
    generateCalendar() {
      this.days = this.generateMonth(this.year, this.month);
      if (this.disabledBefore) {
        // console.log("has disabledBefore");
        for (let day of this.days)
          if (day.fullDate.isBefore(this.disabledBefore)) day.disabled = true;
      }
      if (this.disabledAfter) {
        // console.log("has disabledAfter");
        for (let day of this.days)
          if (day.fullDate.isAfter(this.disabledAfter)) day.disabled = true;
      }
    },
    decrementMonth() {
      if (this.month === 0) {
        this.month = 11;
        this.year -= 1;
      } else this.month -= 1;
      this.generateCalendar(this.year, this.month);
    },
    incrementMonth() {
      if (this.month === 11) {
        this.month = 0;
        this.year += 1;
      } else this.month += 1;
      this.generateCalendar(this.year, this.month);
    },
    setMonth(month) {
      this.month = month;
      this.generateCalendar(this.year, this.month);
      this.showMonthPicker = false;
    },
    setYear(year) {
      this.year = year;
      this.generateCalendar(this.year, this.month);
      this.showYearPicker = false;
    },
    setDatepickerState(val) {
      this.showDatePicker = val;
      if (!val) this.resetDate();
      else this.generateCalendar();
    },
    resetDate(to) {
      if (to) {
        this.year = to.year();
        this.month = to.month();
        this.customDateInput = to.format("DD.MM.YYYY");
      } else if (this.value) {
        this.year = moment(this.value).year();
        this.month = moment(this.value).month();
        this.customDateInput = moment(this.value).format("DD.MM.YYYY");
      } else {
        this.year = moment().year();
        this.month = moment().month();
        this.customDateInput = moment(this.value).format("DD.MM.YYYY");
      }
    },
    parseDateFromInput() {
      const [day, month, year] = this.customDateInput
        .split(".")
        .map((x) => parseInt(x));
      const dateObj = moment()
        .month(month - 1)
        .year(year)
        .date(day);
      if (!dateObj.isValid()) throw new Error("Ei ole valiidne kuupäev");
      if (this.disabledBefore && dateObj.isBefore(this.disabledBefore))
        throw new Error("Kuupäev on liiga varajane");
      if (this.disabledAfter && dateObj.isAfter(this.disabledAfter))
        throw new Error("Kuupäev on liiga hiline");
      this.$emit("input", dateObj);
      this.resetDate(dateObj);
      this.generateCalendar();
    },
    tabHandler(e) {
      if (e.key === "Tab") {
        this.setDatepickerState(false);
      }
    },
  },
  computed: {
    dateComputed() {
      return this.value ?? moment();
    },
    yearPickerArr() {
      const arr = [];
      const yearToUse = this.year ?? moment().year();
      for (let year = yearToUse - 3; year < yearToUse + 4; year++) {
        arr.push(year);
      }

      return arr;
    },
    positionComp() {
      let elPos = this.$refs.wrapper.getBoundingClientRect();
      if (this.pos === "r")
        return {
          left: elPos.left + elPos.width + this.offset + "px",
          top: elPos.top + "px",
        };
      if (this.pos === "t")
        return {
          bottom:
            window.innerHeight -
            elPos.bottom +
            elPos.height +
            this.offset +
            "px",
          right: window.innerWidth - elPos.right + "px",
        };
      return {
        top: elPos.bottom + this.offset + "px",
        right: window.innerWidth - elPos.right + "px",
      };
    },
  },
  watch: {
    value(val) {
      if (!moment(this.customDateInput).isSame(moment(val)))
        this.customDateInput = moment(val).format("DD.MM.YYYY");
    },
    disabledBefore() {
      this.generateCalendar();
    },
    disabledAfter() {
      this.generateCalendar();
    },
    showDatePicker(val) {
      if (val) window.addEventListener("keydown", this.tabHandler);
      if (!val) window.removeEventListener("keydown", this.tabHandler);
    },
    customDateInput(val, oldVal) {
      if (val !== oldVal) this.error.show = false;
      let output = val;
      output = output.replace(/[A-Za-z]/, "");
      output = output.replace(" ", "");
      if ([2, 5].includes(output.length) && oldVal.length < val.length)
        output += ".";
      if (output.length > 10) output = output.slice(0, 10);
      this.customDateInput = output;
      if (output.length === 10)
        try {
          this.parseDateFromInput();
        } catch (error) {
          this.error.message = error;
          this.error.show = true;
        }
    },
  },
};
</script>
<style lang="scss">
.gwd-dateselector {
  @apply relative flex rounded duration-300 cursor-pointer;
  &.datepicker-open {
    @apply ring-2 ring-primary ring-opacity-25 outline-none pl-1;
  }
  &:focus {
    @apply ring-2 ring-primary ring-opacity-25 outline-none pl-1;
  }
  .date-selector-custom-input {
    @apply p-1 pl-0 shadow-none flex;
    &:focus {
      @apply ring-0;
    }
  }
  .datepicker-overlay {
    @apply absolute bg-white h-full w-full rounded-b;
  }
  .monthpicker {
    @apply grid grid-cols-3;
    div {
      @apply flex items-center justify-center text-black duration-150;
      &:hover {
        @apply bg-offwhite cursor-pointer font-medium;
      }
    }
  }
  .yearpicker {
    @apply grid grid-cols-1;
    div {
      @apply flex items-center justify-center text-black duration-150;
      &:hover {
        @apply bg-offwhite cursor-pointer font-medium;
      }
    }
  }
  .gwd-datepicker {
    @apply fixed w-72 bg-white rounded shadow-xl flex flex-col transform isolate;
    z-index: 9999;
  }
}
</style>
